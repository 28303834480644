import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Cliente } from '../../models/cliente.model';
import { BackendService } from '../../core/backend.service';
import { SnackService } from '../../shared-services/snack.service';
import { Email } from '../../models/email.model';

@Component({
  selector: 'app-cambio-email',
  templateUrl: './cambio-email.component.html',
  styleUrls: ['./cambio-email.component.css']
})
export class CambioEmailComponent implements OnInit {

  @Input() cliente: Cliente;
  @Input() cambioEmail: Email;
  @Output() onUpdateEmail = new EventEmitter<boolean>();
  public staticCambioEmail = { email: '' };
  operationalCambioEmail = { email: '' };
  private staticCliente = { username: '' };

  constructor(
    private backendService: BackendService,
    private snackService: SnackService
  ) { }

  hasCambioEmail() {
    if (this.staticCambioEmail.email !== '') {
      return true;
    }
    return false;
  }

  async save() {

    this.snackService.spinner(true);
    try {
      await this.backendService.update('/clientes/cambio_email?userId=' + this.cliente.id
                                    + '&email=' + encodeURIComponent(this.operationalCambioEmail.email), null);
      this.snackService.success('Email actualizado');
      this.updateView();
      this.snackService.spinner(false);
    } catch (error) {
      this.snackService.spinner(false);
      this.snackService.error(error.message);
    }
  }

  updateView() {
    this.staticCambioEmail.email = this.operationalCambioEmail.email;
  }

  ngOnInit() {
    if (this.cambioEmail) {
      this.staticCambioEmail.email = this.cambioEmail.direccion;
      this.operationalCambioEmail.email = this.cambioEmail.direccion;
    }
  }

}
