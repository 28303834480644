import { OperationalParametric } from './operationalParametric.model';
import { Provincia } from './provincia.model';
import { Departamento } from './departamento.model';
import { PaisCliente } from './paisCliente.model';

export class Direccion {

  id: number;
  pais: OperationalParametric;
  tipoDomicilio: OperationalParametric;
  provincia: Provincia;
  departamentoProvincia: Departamento;
  direccion: string;
  numero: string;
  piso: string;
  departamento: string;
  codigoPostal: string;
  PaisCliente = new PaisCliente();
  ciudad: string;
  markers = [];

constructor() {
  this.pais = new OperationalParametric();
  this.tipoDomicilio = new OperationalParametric();
  this.provincia = new Provincia(1);
  this.departamentoProvincia = new Departamento(1);
}

}
