import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MenuActiveService {
  constructor() { }

  private subject = new Subject<string>();
  onGetMenuStatus = this.subject.asObservable();

  onGetSubject(url) {
    this.subject.next(url);
  }

}
