import {Injectable, Inject} from '@angular/core';
import { TRANSLATIONS } from './translations';

@Injectable()
export class TranslateService {
  private _currentLang: string;

  constructor(@Inject(TRANSLATIONS) private _translations: any) {
    this._currentLang = 'es';
  }

  translate(message: string): string | string[] {
    if (message == null) {
      return '';
    }

    if (message.indexOf('\n') !== -1) {
      const messages = message.split(/\r?\n/);
      return this.translateMultipleLines(messages);
    } else {
      return this.translateLine(message);
    }
  }

  private translateLine(message: string): string {
    const regexp = /\[.{1,}\]/;
    const arrayIndex = message.match(regexp);
    let attributeIndex = 0;

    if (arrayIndex && arrayIndex[0] ) {
      message = message.replace(arrayIndex[0], '');
      attributeIndex = +arrayIndex[0].replace('[', '').replace(']', '');
      attributeIndex++;
    }

    if (this._translations[this._currentLang] && this._translations[this._currentLang][message]) {
      const resultMessage = this._translations[this._currentLang][message] as string;
      return attributeIndex === 0 ? resultMessage : resultMessage.replace('@number@', attributeIndex.toString());
    }

    return message;
  }

  private translateMultipleLines(messages: string[]): string[] {
    return messages.map(message => this.translateLine(message));
  }
}
