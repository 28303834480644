export class Telefono {

  id: number;
  numero: string;
  tipoTelefonoId: number;
  icono: string;
  description: string;

  constructor(numero: string, tipoTelefonoId: number) {
    this.numero = numero;
    this.tipoTelefonoId = tipoTelefonoId;
    this.icono = 'phone';
  }

}
