import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, ViewChild } from '@angular/core';
import { Imagen } from '../../models/imagen.model';
import { OperationalParametric } from '../../models/operationalParametric.model';
import { Observable } from '../../../../node_modules/rxjs';
import { EstadoCliente } from '../../models/estadoCliente.model';
import { BackendService } from '../../core/backend.service';
import { SnackService } from '../../shared-services/snack.service';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { DialogService } from '../../shared-components/modals/dialog.service';
import { Producto } from '../../models/producto.model';

@Component({
  selector: 'app-producto-imagenes-upload',
  templateUrl: './producto-imagenes-upload.component.html',
  styleUrls: ['./producto-imagenes-upload.component.css']
})
export class ProductoImagenesUploadComponent implements OnInit, OnChanges {
  @Input() imagenes: Imagen[];
  @Input() estado: OperationalParametric;
  @Input() coloresPosibles: OperationalParametric[];
  @Input() tiposImagenes: Observable<OperationalParametric[]>;
  @Input() submitted;
  @Input() isFirst;
  @Input() img;
  @Input() index;

  @ViewChild('form', {static: false}) public imagenForm;

  private re = /(?:\.([^.]+))?$/;
  private EstadoCliente = new EstadoCliente();
  seleccionarArchivo = 'Seleccionar imágen';
  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService) { }

  ngOnInit() {
    if (this.imagenes.length === 0) {
      this.agregarImagen();
    }
    this.imagenes.forEach(i => {
      if (i.path && i.path.indexOf('http') < 0) {
        this.getImages(i);
      } else {
        i.url = i.path + i.name;
      }
    });

  }

  ngOnChanges() {
    if (this.submitted) {
      this.imagenForm.submitted = true;
    }
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      return c1.id === c2.id;
    } else {return false; }
  }

  getImages(imagen: Imagen) {
    this.backendService.getImagenById(Producto.path + 'imagen/' + imagen.id).subscribe(data => {
      const urlCreator = window.URL;
      const safeUrl: any = this.sanitizer.bypassSecurityTrustUrl(
          urlCreator.createObjectURL(data));
      imagen.url = safeUrl;
    });
  }

  agregarImagen() {
    this.imagenes.push(new Imagen(undefined));
  }
  eliminarImagen(index) {
    if (this.imagenes[index].id) {
      this.dialogService.confirm('Eliminar archivo',
      '¿Está seguro que desea eliminar la imagen ' + this.imagenes[index].path
       + '?')
      .then( res => {
        if (res) {
          this.deleteFromDb(this.imagenes[index].id);
          this.imagenes.splice(index, 1);
          if (this.imagenes.length === 0) {
            this.imagenes.push(new Imagen(undefined));
          }
        }
      });
    } else {
      this.imagenes.splice(index, 1);
    }
  }
  deleteFromDb(id: number) {
    this.backendService.delete(Producto.path + 'eliminar-imagen/' + id);
  }
  addFile(event, index: number) {
    if (this.soloImagenesOPdf(event.target.files[0].name)) {
      this.imagenes[index].file = event.target.files[0];
      this.imagenes[index].path = event.target.files[0].name;
      this.imagenes[index].name = event.target.files[0].name;
      this.imagenes[index].id = undefined;
    }
  }

  soloImagenesOPdf(fileName: string) {
    let ext = this.re.exec(fileName)[1];
    ext = ext.toLowerCase();
    if (ext !== 'png' && ext !== 'jpg' && ext !== 'svg' && ext !== 'jpeg' && ext !== 'gif' && ext !== 'pdf') {
      this.snackService.error('Solo estan permitidos archivos de tipo ".png", ".jpg", ".svg", ".jpeg", ".gif" o ".pdf"');
      return false;
    }
    return true;
  }


  public openWindow(img: Imagen) {
    this.dialogService.imageViewer(img.name, '', img.url);
    // window.open(img.url, '_blank');
  }

}
