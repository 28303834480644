export class Cotizacion {

    static path = '/configuraciones/cotizacion/';
    id: number;
    valor: number;
    created: Date;
    deleted: Date;
    desde: Date;
    hasta: Date;
    desdeString: string;
    hastaString: string;

}
