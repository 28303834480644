import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TdSearchBoxComponent } from '@covalent/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.css']
})
export class SearchInputComponent {

  @Input() searchTittle: string;
  @Input() searchPlaceHolder: string;
  @Output() search = new EventEmitter<string>();

  @ViewChild(TdSearchBoxComponent, {static: false}) searchBox: TdSearchBoxComponent;

  onSearch(value) {
      this.search.emit(value);
  }
}
