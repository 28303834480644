import { Component, OnInit } from '@angular/core';
import { Filters } from '../../../models/filters.model';
import { HasAction, Actions } from '../../../shared-components/generic-table/generic-table.component';
import { Subscription, Observer, Observable } from '../../../../../node_modules/rxjs';
import { SubcategoriaProducto } from '../../../models/subcategoria-producto.model';
import { PaginatedList } from '../../../models/paginatedList.model';
import { BackendService } from '../../../core/backend.service';
import { SnackService } from '../../../shared-services/snack.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { MediaService } from '../../../shared-services/media.service';
import { SecurityService } from '../../../security/security.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { switchMap, share } from '../../../../../node_modules/rxjs/operators';

@Component({
  selector: 'app-subcategoria-producto-table',
  templateUrl: './subcategoria-producto-table.component.html',
  styleUrls: ['./subcategoria-producto-table.component.css']
})
export class SubcategoriaProductoTableComponent implements OnInit {

  public filters: Filters = {
    description: undefined,
    page: 0,
    size: 10,
  };

  columns = [
    { name: 'description',  label: 'Descripcion' },
    { name: 'categorias',  label: 'Categorías' }
  ];

  public showFilters = true;
  isMobile: boolean;

  public totalItems: number;

  public hasActions: HasAction;

  filtersActive = false;
  mostrarResumen = false;
  isLoading = false;

  filtersLoaded = false;
  subscriptions: Subscription[] = [];

  addText = 'Agregar Subcategoría';
  addUrl = '/subcategorias/agregar';
  searchTitle = 'Subcategoría';
  searchPlaceHolder = 'Ingrese el nombre de la subcategoría que desea buscar';
  messageEmpty = 'No se encuentran subcategoría.';
  messageFilters = 'No se encuentran subcategorías que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_SUBCATEGORIA_PRODUCTO';
  subcategoriasLista: {id: number, description: string, categorias: string}[] = [];
  atributoBusqueda: string = 'description';

  itemsList = this.backendService.newSubjectWithoutDistinct<Filters>()
  .pipe(switchMap(filters => {
    const subcategoriasPromise = this.backendService.get<PaginatedList<SubcategoriaProducto>>
            (SubcategoriaProducto.path, filters).toPromise();
    return (async () => {
      this.isLoading = true;
      try {
        const subcategorias = await subcategoriasPromise;
        this.subcategoriasLista = [];
        subcategorias.data.forEach(s => {
          let cats = '';
          let categoriasSize = 0;
          s.categorias.forEach(c => {
            categoriasSize += 1;
            cats = cats + c.description;
            if (categoriasSize !== s.categorias.length) {
              cats = cats + ', ';
            }
          });
          const el = {id: s.id, description: s.description, categorias: cats};
          this.subcategoriasLista.push(el);
        });
        return subcategorias;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
        this.filtersActive = !(this.filters.search === '' || this.filters.search === undefined);
      }
    })();
  })).pipe(share()) as Observer<Filters> & Observable<SubcategoriaProducto[]>;

  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    private mediaService: MediaService,
    public securityService: SecurityService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.showFilters = true;
    this.filtersLoaded = true;

    this.hasActions = new HasAction();

    this.hasActions.hasAction = true;
    this.hasActions.actions = [{ 'element': Actions.edit, 'permission': 'EDITAR_CATEGORIA_PRODUCTO'},
                              { 'element': Actions.delete, 'permission': 'ELIMINAR_CATEGORIA_PRODUCTO'}];

    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.showFilters = !this.isMobile;
    }));

    this.itemsList.subscribe(t => {
      this.isLoading = false;
      this.totalItems = t['totalElements'];
    });

    this.refreshItemsList();
  }


  refreshItemsList() {
    this.itemsList.next(this.getFilters());
  }

  getDefaultFilters() {
    this.filters = {
      description : undefined,
      page: 0,
      size: 10,
    };
  }

  getFilters() {
    return this.filters;
  }

  onEdit(subcategoria: SubcategoriaProducto) {
    this.router.navigate(['/subcategorias/editar', subcategoria.id]);
  }


  async onDelete(subcategoria: SubcategoriaProducto) {

    this.dialogService.confirm('Eliminar Subcategoria',
    '¿Está seguro que desea eliminar las subcategorias ' + subcategoria.description + '?')
    .then( res => {
      if (res) {
        this.deleteCategoria(res, subcategoria);
      }
    });

  }

  async deleteCategoria(res, subcategoria: SubcategoriaProducto) {

    if (res) {

      this.snackService.spinner(true);

      try {
        await this.backendService.delete(SubcategoriaProducto.path + subcategoria.id);
        this.snackService.success('Subcategorías de Productos eliminadas correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.refreshItemsList();
      }

    }

  }

}
