import {Component, Input, OnInit, ViewChild, OnChanges} from '@angular/core';
import {Cliente} from "../../models/cliente.model";
import {OperationalParametric} from "../../models/operationalParametric.model";
import {ApiService} from "../../core/api.service";
import {NgModel} from "@angular/forms";
import {$e} from "codelyzer/angular/styles/chars";
import {UniqueIdentifierService} from "../../shared-services/unique-identifier.service";

export class ClienteTransporte {
  static path = '/backend/clientes/transporte/all';
  name: string;
  id: number;
  constructor() {
  }
}

@Component({
  selector: 'app-cliente-datos-comportamiento',
  templateUrl: './cliente-datos-comportamiento.component.html',
  styleUrls: ['./cliente-datos-comportamiento.component.scss']
})
export class ClienteDatosComportamientoComponent implements OnInit, OnChanges {

  @Input() cliente: Cliente;
  @Input() required;
  @Input() submitted;
  transportes: ClienteTransporte[];

  @ViewChild('form', {static: false}) public clienteForm;

  constructor(private apiService: ApiService, private uniqueService: UniqueIdentifierService) { }

  ngOnInit() {
    this.getTransportes();

  }

  ngOnChanges() {
    if (this.submitted) {
      this.clienteForm.submitted = true;
    }
  }

  async getTransportes() {
    await this.apiService.get<any>(ClienteTransporte.path, null).toPromise().then(res => {
      this.transportes = res;
      this.transportes = this.transportes.filter(value => value.id != this.cliente.id);
    });
  }

  setLimiteCredito($event) {
    alert($event);
    this.cliente.limiteCredito = $event.value();
  }

  validateDecimalNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*\.?\d*$/;

    if (!reg.test(input)) {
      e.preventDefault();
    }
  }
}
