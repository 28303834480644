import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { Cliente } from '../../models/cliente.model';
import { IMyDpOptions, IMyDateModel, MyDatePicker } from 'mydatepicker';
import { FormControl, NgForm } from '@angular/forms';
import {UniqueIdentifierService} from "../../shared-services/unique-identifier.service";

@Component({
  selector: 'app-cliente-datos-personales',
  templateUrl: './cliente-datos-personales.component.html',
  styleUrls: ['./cliente-datos-personales.component.scss']
})
export class ClienteDatosPersonalesComponent implements OnInit, OnChanges {
  @Input() cliente: Cliente;
  @Input() buttonClicked = true;
  @Input() submitted;

  private isPersonaFisica = false;

  @ViewChild('form', {static: false}) public clienteForm;
  @ViewChild('mydp', {static: false}) mydp: MyDatePicker;

  modelForDatePicker: any;

  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    sunHighlight: false,
    satHighlight: false,
    showInputField: false,
    showClearDateBtn: false
  };

  constructor(private uniqueService: UniqueIdentifierService) {
  }

  ngOnInit() {
    if (this.cliente.fechaNacimiento) {
      const date = new Date(Number(this.cliente.fechaNacimiento.substr(0, 4)),
                            Number(this.cliente.fechaNacimiento.substr(6, 2)),
                            Number(this.cliente.fechaNacimiento.substr(10, 2)));
      this.setDateModelForPicker(
        date.getUTCFullYear(),
        date.getUTCMonth() + 1,
        date.getUTCDate());
    }
  }

  ngOnChanges() {
    if (this.submitted) {
      this.clienteForm.submitted = true;
    }
  }


  // dateChanged callback function called when the user select the date. This is mandatory callback
  // in this option. There are also optional inputFieldChanged and calendarViewChanged callbacks.
  onDateChanged(event: IMyDateModel) {
    // event properties are: event.date, event.jsdate, event.formatted and event.epoc
    const fechaNacimiento = event.jsdate;
    if (fechaNacimiento) {
      this.cliente.fechaNacimiento = fechaNacimiento.toISOString();
    }
  }

  onBirthDateTextBoxBlur(date) {
    if (date.length === 10) {

      const dateParts = date.split('/');
      const year = +dateParts[2];
      const month =  +dateParts[1];
      const day = +dateParts[0];

      this.setDateModelForPicker(year, month, day);
    }
  }

  private setDateModelForPicker(year: number, month: number, day: number) {
    this.modelForDatePicker = {
      date:
       {
         year,
         month,
         day
        }
      };
  }

  onTogglePicker(event) {
    event.stopPropagation();
    this.mydp.openBtnClicked();
  }

  mapNumberToString(id: number) {
    const val = id.toString();
    return val;
  }

}
