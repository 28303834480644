export class PaisCliente {

    static Argentina;

    constructor() {
      PaisCliente.Argentina = 55;
    }

    get staticArgentina() {
      return PaisCliente.Argentina;
    }

}
