import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BackendService } from '../../core/backend.service';
import { Comprobante } from '../../models/comprobante.model';

@Injectable()
export class ComprobanteResolver implements Resolve<Comprobante> {
  constructor(
    protected router: Router,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id || 'agregar';

      if (id === 'agregar') {
        const comprobante = new Comprobante();
        return comprobante;
      }

      return await this.backendService.get<Comprobante>(Comprobante.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/comprobante']);
    }
  }
}
