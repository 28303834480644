import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {HttpClientModule} from '@angular/common/http';

import { LoginComponent } from './login/login.component';
import { SecurityService } from './security.service';
import { VendorModule } from '../core/vendor.module';

import { CoreModule } from '../core/core.module';
import { SharedServicesModule } from '../shared-services/shared-services.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { LoguedGuardService } from './logued-guard.service';
import { UserComponent } from './user/user.component';
import { UserTableComponent } from './user/user-table/user-table.component';
import { UserAddEditComponent } from './user/user-add-edit/user-add-edit.component';
import { UsuarioDatosContactoComponent } from './user/usuario-datos-contacto/usuario-datos-contacto.component';
import { UsuarioPhoneListComponent } from './user/usuario-phone-list/usuario-phone-list.component';
import { UsuarioEmailListComponent } from './user/usuario-email-list/usuario-email-list.component';
import { UserFotoUploadComponent } from './user/user-foto-upload/user-foto-upload.component';
import { UserResolver } from './user/user.resolver';
import { UserChangePasswordComponent } from './user/user-change-password/user-change-password.component';
import { UserChangePasswordResolver } from './user/user-change-password/user-change-password.resolver';
import { RoleComponent } from './role/role.component';
import { RoleTableComponent } from './role/role-table/role-table.component';
import { RoleAddEditComponent } from './role/role-add-edit/role-add-edit.component';
import { RoleResolver } from './role/role.resolver';
import { PermissionComponent } from './permission/permission.component';
import { SucursalComponent } from './sucursal/sucursal.component';
import { SucursalAddEditComponent } from './sucursal/sucursal-add-edit/sucursal-add-edit.component';
import { SucursalTableComponent } from './sucursal/sucursal-table/sucursal-table.component';
import { SucursalResolver } from './sucursal/sucursal.resolver';
import { SmartPasswordPipe} from '../pipes/password/smart-password.pipe';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    CoreModule,
    VendorModule,
    SharedServicesModule,
    SharedComponentsModule,

  ],
  declarations: [
    LoginComponent,
    UserComponent,
    UserTableComponent,
    UserAddEditComponent,
    UsuarioDatosContactoComponent,
    UsuarioPhoneListComponent,
    UsuarioEmailListComponent,
    UserFotoUploadComponent,
    UserChangePasswordComponent,
    RoleComponent,
    RoleTableComponent,
    RoleAddEditComponent,
    PermissionComponent,
    SucursalComponent,
    SucursalAddEditComponent,
    SucursalTableComponent,
    SmartPasswordPipe
  ],
  exports: [
    LoginComponent,
    UserComponent,
    UserTableComponent,
    UserAddEditComponent,
    UsuarioDatosContactoComponent,
    UsuarioPhoneListComponent,
    UsuarioEmailListComponent,
    UserChangePasswordComponent,
    RoleTableComponent,
    RoleAddEditComponent,
    PermissionComponent,
    SucursalComponent,
    SucursalAddEditComponent,
    SucursalTableComponent,
  ],
  providers: [
    SecurityService,
    LoguedGuardService,
    UserResolver,
    UserChangePasswordResolver,
    RoleResolver,
    SucursalResolver
  ]
})
export class SecurityModule { }
