export class EscalaRetencionGanancia {

    static path = '/clientes/escalas-retencion-ganancias';

    id: number;
    inciso: string;
    detalle: string;
    codigo: string;

}
