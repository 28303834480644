import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { SnackService } from './snack.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  previousURL: string = undefined;

  constructor(
    private router: Router,
    private snackService: SnackService,
  ) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.previousURL = this.router.url;
      }
    });
  }

  async back(defaultURL?: string) {
    this.snackService.spinner(true);
    await this.router.navigateByUrl(this.previousURL !== '/' ? this.previousURL : (defaultURL || '/'));
    this.snackService.spinner(false);
  }
}
