import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SnackService } from '../../../shared-services/snack.service';

@Component({
  selector: 'app-modal-link-confirmacion',
  templateUrl: './modal-link-confirmacion.component.html',
  styleUrls: ['./modal-link-confirmacion.component.css']
})
export class ModalLinkConfirmacionComponent {
  public link: string;
  public linkString: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalLinkConfirmacionComponent>,
    public snackService: SnackService) {}

  copiarLink() {
    const selBox = document.createElement('textarea');
    selBox.value = this.link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    const copiado = document.execCommand('copy');
    document.body.removeChild(selBox);
    if (copiado) {
        this.snackService.success('Link copiado al portapapeles');
    } else {
        this.snackService.error('Hubo un error al copiar');
    }
  }
}
