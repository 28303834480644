import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SecurityService } from './security/security.service';

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(
    private securityService: SecurityService,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.securityService.redirectToDefault();
    return false;
  }
}
