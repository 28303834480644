import { Component, OnInit, OnChanges, Input, ViewChildren, Output, EventEmitter, SimpleChanges, QueryList } from '@angular/core';
import * as moment from 'moment';
import { OperationalParametric } from '../../models/operationalParametric.model';
import { IFilterOption, ExpansorWithChipComponent, ISelectedValue } from '../../shared-components/expansor-with-chip/expansor-with-chip.component';
import { MediaService } from '../../shared-services/media.service';
import { ExpansorWithChipMultipleComponent } from '../../shared-components/expansor-with-chip-multiple/expansor-with-chip-multiple.component';
import { Tag } from '../../models/tag.model';

export interface ISelectedChip {
  chipKey: string;
  chipLabel: string;
  categoryName: string;
  tagId: any;
  selectedChipFilterComponent: any; // Componente del viewChild
}

export interface DocChangeResponse {
  selectedChips: ISelectedChip[];
  selectedDocs: OperationalParametric[];
}

export interface GenericFilter {
  id: number;
  label: string;
  selected: boolean;
  filter: string;
}

@Component({
  selector: 'app-cliente-filters',
  templateUrl: './cliente-filters.component.html',
  styleUrls: ['./cliente-filters.component.css']
})
export class ClienteFiltersComponent implements OnInit, OnChanges {

  isMobile: boolean;

  ultimaConexion: string;
  ultimoPedido: string;

  @Input() showEstado = true;
  @Input() selectedChips: ISelectedChip[] = [];

  ultimaConexionFilterValues: IFilterOption[] = [];
  ultimoPedidoFilterValues: IFilterOption[] = [];
  origenFilterValues: IFilterOption[] = [];

  @ViewChildren('ultimaConexionFilter') private ultimaConexionFilterComponent: ExpansorWithChipComponent;
  @ViewChildren('ultimoPedidoFilter') private ultimoPedidoFilterComponent: ExpansorWithChipComponent;
  @Output() onFilterChange = new EventEmitter<ISelectedChip[]>();
  @Output() onHide = new EventEmitter<void>();

  async getUltimaConexionValues() {
    this.ultimaConexionFilterValues.push({value: this.getFechaReciente(), displayText: 'Reciente', class: ''});
    this.ultimaConexionFilterValues.push({value: this.getFechaHacePoco(), displayText: 'Hace poco', class: ''});
    this.ultimaConexionFilterValues.push({value: this.getFechaHaceMucho(), displayText: 'Hace mucho', class: ''});
  }
  async getUltimoPedidoValues() {
    this.ultimoPedidoFilterValues.push({value: this.getFechaReciente(), displayText: 'Reciente', class: ''});
    this.ultimoPedidoFilterValues.push({value: this.getFechaHacePoco(), displayText: 'Hace poco', class: ''});
    this.ultimoPedidoFilterValues.push({value: this.getFechaHaceMucho(), displayText: 'Hace mucho', class: ''});
  }

  constructor(
    private mediaService: MediaService,
  ) {}

  ngOnInit() {
    this.mediaService.isMobile.subscribe(isMobile => this.isMobile = isMobile);
    this.getUltimaConexionValues();
    this.getUltimoPedidoValues();
  }

  ngOnChanges(changes: SimpleChanges) {}

  onRemoveChip(selectedChip: ISelectedChip) {
    selectedChip.selectedChipFilterComponent.onRemoveChip();
    this.selectedChips = this.selectedChips.filter(x => x.chipKey !== selectedChip.chipKey);
    this.filterChange();
  }

  onUltimaConexionChange(selectedFilter: ISelectedValue) {
    this.addChip('ultimaConexionFilter', selectedFilter.viewValue, selectedFilter, this.ultimaConexionFilterComponent);
    this.ultimaConexion = selectedFilter.value;
    this.filterChange();
  }

  onUltimoPedidoChange(selectedFilter: ISelectedValue) {
    this.addChip('ultimoPedidoFilter', selectedFilter.viewValue, selectedFilter, this.ultimoPedidoFilterComponent);
    this.ultimoPedido = selectedFilter.value;
    this.filterChange();
  }

  addMultipleChip(chips: any[], categoryName: string, chipName: string, component: ExpansorWithChipMultipleComponent<Tag>) {
    this.selectedChips = this.selectedChips.filter(x => x.categoryName !== categoryName);

    for (const chip of chips) {
      const chipKey = chipName + chip.value;

      if (!this.selectedChips.find(x => x.chipKey === chipKey)) {
        const selectedFilter = { value: chip.value, categoryName: categoryName, viewValue: chip.displayText };
        this.addChip(chipKey, chip.displayText, selectedFilter, component);
      }
    }
  }

  filterChange() {
    this.onFilterChange.emit(this.selectedChips);
  }

  addChip(chipKey: string, chipLabel: string, selectedFilter: ISelectedValue, selectedChipFilterComponent: any): ISelectedChip {
    if (selectedFilter.value === undefined) {
      return;
    }

    if (this.selectedChips.find(x => x.chipKey === chipKey)) {
      return;
    }

    const selectedChipInstance: ISelectedChip =  {
      chipKey: chipKey,
      chipLabel: chipLabel,
      categoryName: selectedFilter.categoryName,
      tagId: selectedFilter.tagID !== undefined ? selectedFilter.tagID : selectedFilter.value,
      selectedChipFilterComponent: (<QueryList<ExpansorWithChipComponent>>selectedChipFilterComponent).first
    };

    this.selectedChips.push(selectedChipInstance);
  }

  onTogglePickerFrom(event: any) {
    event.stopPropagation();
  }

  onTogglePickerTo(event: any) {
    event.stopPropagation();
  }

  getFechaReciente() {
    return 3;
  }

  getFechaHacePoco() {
    return 15;
  }
  getFechaHaceMucho() {
    return 60;
  }

  getFecha(val: number) {
    const d = new Date();
    d.setDate(new Date().getDate() - val);
    return moment(d).format('YYYY-MM-DD');
  }
}
