import { OperationalParametric } from './operationalParametric.model';
import { Telefono } from './telefono.model';
import { Email } from './email.model';
import * as moment from 'moment';
import { MontoCompra } from './montoCompra.model';
import { Direccion } from './direccion.model';
import { Documento } from './documento.model';
import { Logo } from './logo.model';
import { TipoPersona } from './tipo-persona.model';
import { TipoServicio } from './tipo-servicio.model';
import {Contacto} from "./contacto.model";
import {CuentaBancaria} from "./cuenta-bancaria.model";
import { RetencionGanancia } from './retencion-ganancia.model';
import {PathSociales} from "./path-sociales.interface";
import {EscalaRetencionGanancia} from "./escala-retencion-ganancia.model";

export class Cliente implements PathSociales{

  static path = '/clientes/';

  id: number;
  username: string;
  password: string;
  retryPassword: string;
  name: string;
  lastname: string;
  estado: OperationalParametric;
  nacionalidad: OperationalParametric;
  emisorDocumento: OperationalParametric;
  genero: OperationalParametric;
  tipoDocumento: OperationalParametric;
  tipoUsuario: OperationalParametric;
  situacionIva: OperationalParametric;
  montoCompraActual: MontoCompra;
  personaPoliticamenteExpuesta: boolean;
  documentacionPendiente: boolean;
  nroDocumento: string;
  fechaNacimiento: string;
  cuitCuil: string;
  ocupacion: string;
  direcciones: Direccion[];
  telefonos: Telefono[];
  emails: Email[];
  documentacionRespaldatoria: Documento[];
  documentos: Documento[] = [];
  logos: Logo[] = [];
  observacion: string;
  pais: string;
  provincia: string;
  direccion: string;
  numero: string;
  piso: string;
  departamento: string;
  codPostal: string;
  origen: string;
  ultimaCompra =  moment('01/01/2018', 'DD/MM/YYYY');
  tipoServicio: TipoServicio;
  tipoPersona: TipoPersona;
  limiteCredito: number;
  validaPedido: boolean;
  descuento: number;
  transporte: Cliente;
  contactos: Contacto[] = [];
  cuentasBancarias: CuentaBancaria[] = [];
  nroIngresosBrutos: string;
  retencionIngresosBrutos: number;
  percepcionIngresosBrutos: number;
  retencionGanancia: RetencionGanancia;
  escalaRetencionGanancia: EscalaRetencionGanancia;
  inscriptoGanancias: boolean;

  websitePath: string;
  twitterPath: string;
  facebookPath: string;
  instagramPath: string;

  public isPersonaFisica() {
    return this.situacionIva.id === 5 || this.situacionIva.id === 6;
  }

}
