import { Component, OnInit, Input, OnDestroy, ViewChild, OnChanges, Inject } from '@angular/core';
import { Email } from '../../../models/email.model';

@Component({
  selector: 'app-usuario-email-list',
  templateUrl: './usuario-email-list.component.html',
  styleUrls: ['./usuario-email-list.component.scss']
})
export class UsuarioEmailListComponent implements OnInit, OnChanges {

  @Input() email: Email;
  @Input() emails: Email[];
  @Input() submitted;

  @ViewChild('form', {static: false}) public clienteForm;

  constructor() { }

  ngOnInit() {
    if (this.emails.length === 1) {
      this.emails[0].principal = true;
    }
  }

  ngOnChanges() {
    if (this.submitted) {
      if (this.clienteForm) {
        this.clienteForm.submitted = true;
      }
    }
  }

  onlyOneEmailPrincipal() {
    for (const email of this.emails) {
      if (email.principal === true) {
        email.principal = false;
      }
    }
  }
}
