import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material';

export interface IOption {
  value: number;
  label: string;
  displayText: string;
  selected?: boolean;
}

@Component({
  selector: 'app-expansor-with-chip-multiple',
  templateUrl: './expansor-with-chip-multiple.component.html',
  styleUrls: ['./expansor-with-chip-multiple.component.scss']
})
export class ExpansorWithChipMultipleComponent<T extends IOption> implements OnInit {

  constructor() {}
  initialized = false;
  optionsSelected: T[] = [];
  filteredOptions: Observable<T[]>;
  search = new FormControl();
  @Input() expanded = false;
  @Input() hideDivider = false;
  @Input() placeHolderText: string;
  @Input() displayedOptions: T[] = [];
  @Input() withSearch = false;
  @Output() filtersChange: EventEmitter<T[]> = new EventEmitter<T[]>();

  @Input() set selectedFilters(tagIds: number[]) {
    let optionsToLoad: T[] = [];

    if (tagIds !== undefined) {
      optionsToLoad = this.displayedOptions.filter(x => tagIds.find(y => y === x.value) !== undefined);
    }

    if (optionsToLoad.length !== 0) {
      if (this.expanded !== false) {
        this.expanded = true;
      }
      optionsToLoad.forEach(o => o.selected = true);
      this.optionsSelected = optionsToLoad;

      // Es necesario un timeout de 0 para que se cierre el ciclo y el componente exista como view children en el padre
      setTimeout(() => this.filtersChange.emit(this.optionsSelected), 0);
    }
  }

  public _filter(value: any): any[] {
    const filterValue = value.description ? value.description.toLowerCase() : value.toLowerCase();
    return this.displayedOptions.filter(opt => opt.displayText.toLowerCase().indexOf(filterValue) === 0);
  }
  ngOnInit() {
    this.filteredOptions = this.search.valueChanges.pipe(
      startWith(null),
      map((opt: string | null) => opt ? this._filter(opt) : this.displayedOptions));
  }

  onRemoveChip() {
  }

  onOptionClicked(checkbox: MatCheckboxChange, option: T) {
    if (checkbox.checked) {
      this.optionsSelected.push(option);
    } else {
      this.optionsSelected = this.optionsSelected.filter(x => x !== option);
    }
  }

  apply() {
    this.filtersChange.emit(this.optionsSelected);
  }
}
