import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';

import {catchError, distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { isEmpty, isEqual, forEach, isArray, isFunction, isString } from 'lodash';

import { StorageProvider } from './browser-providers';
import { SnackService } from '../shared-services/snack.service';
import { NavigationService } from '../shared-services/navigation.service';
import {  throwError } from 'rxjs';
import { User } from '../models/user.model';
import { Producto } from '../models/producto.model';
import { EnvService } from '../shared-services/env.service';

export type QueryParams = null | string | URLSearchParams | {
  [key: string]: any | any[]
};

export type BackendModelFactory<M extends BackendModel> = (
  new(backendService: BackendService, obj?: Object) => M
);

export abstract class BackendModel {
  protected '@id' = '';
  get iri() { return this['@id']; }
  protected '@type' = '';
  get class() { return this['@type']; }
  get id() {
    return +this['@id'].split('/').pop();
  }
  constructor(
    protected backendService: BackendService, model?: any
  ) { }

  protected isNull(model: any): boolean {
    return model === undefined || model === null;
  }

  delete() {
    this.backendService.delete(this.iri);
  }

  protected preSave() {
    forEach(this, p => {
      if (p instanceof BackendModel) {
        p.preSave();
      } else if (isArray(p) && p.length && p[0] instanceof BackendModel) {
        p.forEach(m => isFunction(m.preSave) && m.preSave());
      }
    });
  }
  async save() {
    this.preSave();
    try {
      if (this.id) {
        return await this.backendService.update(this.iri, this);
      } else {
        this['_id'] = this['@id'];
        delete this['@id'];
        return await this.backendService.create(this['_id'], this);
      }
    } catch (e) {
      this['@id'] = this['@id'] || this['_id'];
      throw e;
    } finally {
      this.postSave();
    }
  }
  protected postSave() {
    forEach(this, p => {
      if (p instanceof BackendModel) {
        p.postSave();
      } else if (isArray(p) && p.length && p[0] instanceof BackendModel) {
        p.forEach(m => isFunction(m.postSave) && m.postSave());
      }
    });
  }
}

@Injectable()
export class BackendService {

  private tokenId = 'jwtToken';
  protected backendUrl: string;
  private frontendUrl: string;
  private baseUrl: string;

  constructor(
    protected env: EnvService,
    @Inject(StorageProvider) protected storage: Storage,
    protected navigationService: NavigationService,
    protected snackService: SnackService,
    protected http: HttpClient,
  ) {
    this.backendUrl = env.apiBack;
    this.baseUrl = env.api;
  }

  private buildUrl(path: string) {
    return this.backendUrl + encodeURI(path);
  }

  private buildRequestOptions(params?) {
    const options = params ? params : { headers: undefined };
    options.headers = new HttpHeaders();
    options.headers = options.headers.append('Content-Type', 'application/ld+json');
    const token = this.token;
    if (token) {
      options.headers = options.headers.append('Authorization', 'Bearer ' + token);
    }
    return options;
  }

  private buildRequestMultiPartOptions(params?) {
    const options = params ? params : { headers: undefined };
    options.headers = new HttpHeaders();
    const token = this.token;
    if (token) {
      options.headers = options.headers.append('Authorization', 'Bearer ' + token);
    }
    return options;
  }

  /*private getData(response: HttpResponse<any>) {
    debugger;
    if (response.ok) { return response; }
    throw response['hydra:description'] || response['message'];
  }*/

  private handleError(error: HttpErrorResponse) {
    /*if (response.status === 401) {
      this.authService.logout();
    }*/
    if (error.status === 403) {
      setTimeout(() => this.navigationService.back(), 2000);
      this.snackService.error('Usted no tiene permisos para visualizar o modificar estos datos');
    }
    return throwError(error);
  }

 /* new<M>(M, obj = { }) {
    return Object.setPrototypeOf(obj, M) as M;
  }

  cast<M extends BackendModel>(list: M[], modelFactory: BackendModelFactory<M>, ...defaults: any[]) {
    return (list && list.length >= defaults.length ? list : defaults)
    .map(item => isString(item) ? item : this.new(modelFactory, item)) as M[];
  }
*/
  newSubject<S>() {
    return new Subject<S>().pipe(
      debounceTime(666),
      distinctUntilChanged(isEqual));
  }

  newSubjectWithoutDistinct<S>() {
    return new Subject<S>().pipe(
      debounceTime(666));
  }

  get<M>(path: string, params?: QueryParams): Observable<M>;
  get<M>(path: string, params: QueryParams): Observable<any> {
    return this.http.get(
      this.buildUrl(path),
      this.buildRequestOptions(
        isEmpty(params) ? undefined : { params }
      )
    ).pipe(catchError(this.handleError));
  }

  getRawPath<M>(path: string): Observable<M>;
  getRawPath<M>(path: string, lista?: boolean, params?: QueryParams): Observable<any> {
    return this.http.get(
      this.baseUrl + encodeURI(path),
      this.buildRequestOptions(
        isEmpty(params) ? undefined : { params }
      )
    ).pipe(catchError(this.handleError));
  }

  create<M extends Object>(path: string, model: M) {
    return this.http.post(
      this.buildUrl(path),
      model,
      this.buildRequestOptions()
    ).pipe(catchError(this.handleError))
    .toPromise();
  }

  createWithResponse<M extends Object>(path: string, model: M) {
    return this.http.post(
      this.buildUrl(path),
      model,
      this.buildRequestOptions()
    ).pipe(catchError(this.handleError))
    .toPromise();
  }

  createFile<M extends Object>(path: string, model: M) {
    return this.http.post(
      this.buildUrl(path),
      model,
      this.buildRequestMultiPartOptions()
    ).pipe(catchError(this.handleError))
    .toPromise();
  }

  update<M extends Object>(path: string, model: M) {
    return this.http.put(
      this.buildUrl(path),
      model,
      this.buildRequestOptions()
    ).pipe(catchError(this.handleError))
    .toPromise();
  }

  delete<M extends Object>(path: string) {
    return this.http.delete(
      this.buildUrl(path),
      this.buildRequestOptions()
    ).pipe(catchError(this.handleError))
    .toPromise();
  }

  enable<M extends Object>(path: string) {
    return this.http.get(
      this.buildUrl(path),
      this.buildRequestOptions()
    ).pipe(catchError(this.handleError))
    .toPromise();
  }

  disable<M extends Object>(path: string) {
    return this.http.get(
      this.buildUrl(path),
      this.buildRequestOptions()
    ).pipe(catchError(this.handleError))
    .toPromise();
  }

  get token() {
    return localStorage.getItem(this.tokenId);
  }

  getImagenById(url: string): Observable<Blob> {
    return this.http.get(this.buildUrl(url),
        this.buildRequestOptions({responseType: 'blob'})) as any;
  }

}
