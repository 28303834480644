import { Operacion } from './operacion.model';
import { OperacionMedioPago } from './operacion-medio-pago.model';
import { ComprobanteOperacion } from './comprobante-operacion.model';

export class OperacionVista {

    static path = '/operacion/operacion-vista/';

    operacion: Operacion;
    operacionMediosPago: OperacionMedioPago[];
    operacionComprobantes: ComprobanteOperacion[];

    constructor() {
        this.operacion = new Operacion();
        this.operacionMediosPago = [];
        this.operacionComprobantes = [];
    }
}
