import { Component, OnInit, EventEmitter, AfterViewInit, Input, Output, ViewChild } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { SearchInputComponent } from '../search-input/search-input.component';

export const DECIMAL_FORMAT: (v: any) => any = (v: number) => v.toFixed(2);

export interface IColumn {
  name: string;
  label: string;
  numeric: boolean;
  format: any;
}

export interface Page {
  pageIndex: number;
  pageSize: number;
}

export enum Actions {
  view = 1 ,
  edit = 2,
  delete = 3
}

export interface ActionList {
  element: Actions;
  permission: string;
}

export class HasAction {
  public hasAction = false;
  public actions: ActionList[];
}

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.css']
})
export class GenericTableComponent implements OnInit, AfterViewInit {

  @Input() columns: IColumn[];
  @Input() rows: any[];
  @Input() hasActions: HasAction;

  @Input() securityService: SecurityService;

  @Output() initialized = new EventEmitter();
  @Output() view = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  public actions: any[];
  public edita: any[];
  public puedeEditar = false;

  @ViewChild(GenericTableComponent, {static: false})
  private genericListComponent: GenericTableComponent;

  @ViewChild(SearchInputComponent, {static: false})
  private searchComponent: SearchInputComponent;

  constructor() { }

  ngOnInit() {
    this.actions = new Array();
    if (this.hasActions.hasAction) {
      for (const action of this.hasActions.actions) {
        switch (action.element) {
          case Actions.view:
            this.actions.push({ 'permission': action.permission, 'method': 'onView', 'icon': 'visibility', 'tooltip': 'Ver detalle' });
            break;
          case Actions.edit:
            this.puedeEditar = true;
            this.actions.push({ 'permission': action.permission, 'method': 'onEdit', 'icon': 'edit', 'tooltip': 'Editar elemento' });
            break;
          case Actions.delete:
            this.actions.push({ 'permission': action.permission, 'method': 'onDelete', 'icon': 'delete_outline', 'tooltip': 'Eliminar elemento' });
            break;
        }
      }
      if (this.puedeEditar) {
        this.edita = this.actions.filter(t => t.method === 'onEdit');
      }
    }
  }

  editar(row) {
    if (this.securityService.hasPermission(this.edita[0].permission)) {
      return this.onEdit(row);

    }
  }

  ngAfterViewInit() {
    this.initialized.emit();
  }

  onView(e) {
    this.view.emit(e);
  }

  onEdit(e) {
    this.edit.emit(e);
  }

  onDelete(e) {
    this.delete.emit(e);
  }
}
