import { Documento } from './documento.model';

export class Documentacion {

    static path = 'documentacion-ordenada';
    id: number;
    description: string;
    documentos: Documento[];

}
