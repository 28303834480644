import { Component, OnInit, ViewChild } from '@angular/core';
import { Filters } from '../../../models/filters.model';
import { GenericTableComponent, HasAction, Actions } from '../../../shared-components/generic-table/generic-table.component';
import { Subject, Subscription, Observer, Observable } from '../../../../../node_modules/rxjs';
import { switchMap, share } from '../../../../../node_modules/rxjs/operators';
import { PaginatedList } from '../../../models/paginatedList.model';
import { OperationalParametric } from '../../../models/operationalParametric.model';
import { EnvService } from '../../../shared-services/env.service';
import { BackendService } from '../../../core/backend.service';
import { MatPaginatorIntl } from '../../../../../node_modules/@angular/material';
import { SnackService } from '../../../shared-services/snack.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { MediaService } from '../../../shared-services/media.service';
import { SecurityService } from '../../../security/security.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { TipoPersona } from '../../../models/tipo-persona.model';

@Component({
  selector: 'app-tipos-personas-table',
  templateUrl: './tipos-personas-table.component.html',
  styleUrls: ['./tipos-personas-table.component.css']
})
export class TiposPersonasTableComponent implements OnInit {

  private backendUrl: string;

  public filters: Filters = {
    name: undefined,
    page: 0,
    size: 10,
  };

  columns = [
    { name: 'id', label: 'Identificador' },
    { name: 'description', label: 'Tipo Persona' }
  ];

  public showFilters = true;
  isMobile: boolean;

  @ViewChild(GenericTableComponent, {static: false})
  private genericTableComponent: GenericTableComponent;

  private getNewPage = new Subject<Filters>();

  public totalItems: number;

  public hasActions: HasAction;

  roleFiltersActive = false;
  mostrarResumen = false;
  isLoading = false;

  rolesLista = [];

  filtersLoaded = false;
  subscriptions: Subscription[] = [];
  addText = 'Agregar Tipo de Persona';
  addUrl = '/tipo-persona/agregar';
  searchTitle = 'Tipos Persona';
  searchPlaceHolder = 'Ingrese el nombre del tipo de persona que desea buscar';
  messageEmpty = 'No se encuentran tipos de persona.';
  messageFilters = 'No se encuentran tipos de persona que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_TIPO_PERSONA';
  atributoBusqueda: string='description';
  roleList = this.backendService.newSubjectWithoutDistinct<Filters>().pipe(
  switchMap(filters => {
    const usersPromise = this.backendService.get<PaginatedList<TipoPersona>>(TipoPersona.path, filters).toPromise();
    return (async () => {
      this.isLoading = true;
      try {
        const permissions = await usersPromise;
        this.rolesLista = permissions.data;
        return permissions;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
        this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
      }
    })();
  })).pipe(share()) as Observer<Filters> & Observable<TipoPersona[]>;


  constructor(
    protected env: EnvService,
    private backendService: BackendService,
    private paginatorsLabels: MatPaginatorIntl,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    private mediaService: MediaService,
    public securityService: SecurityService,
    private dialogService: DialogService,
  ) {
    this.backendUrl = env.api + '/backend';
  }

  ngOnInit() {
    this.showFilters = true;
    this.filtersLoaded = true;

    this.hasActions = new HasAction();

    this.hasActions.hasAction = true;
    this.hasActions.actions = [{ 'element': Actions.edit, 'permission': 'EDITAR_TIPO_PERSONA'},
                              { 'element': Actions.delete, 'permission': 'ELIMINAR_TIPO_PERSONA'}];

    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.showFilters = !this.isMobile;
    }));

    this.paginatorsLabels.itemsPerPageLabel = 'Filas por página:';

    this.roleList.subscribe(t => {
      this.isLoading = false;
      this.totalItems = t['totalElements'];
    });

    this.refreshRoleList();
  }

  refreshRoleList() {
    this.roleList.next(this.getFilters());
  }

  getDefaultFilters() {
    this.filters = {
      name : undefined,
      page: 0,
      size: 10,
    };
  }

  getFilters() {
    return this.filters;
  }

  onEditRole(role: any) {
    this.router.navigate(['/tipo-persona/editar', role.id]);
  }

  async onDeleteRole(role: TipoPersona) {

    this.dialogService.confirm('Eliminar tipo de persona',
    '¿Está seguro que desea eliminar el tipo de persona ' + role.description + '?')
    .then( res => {
      if (res) {
        this.deleteRole(res, role);
      }
    });

  }

  async deleteRole(res, role: TipoPersona) {

    if (res) {

      this.snackService.spinner(true);

      try {
        await this.backendService.delete(TipoPersona.path + role.id);
        this.snackService.success('Tipo de persona eliminado correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.refreshRoleList();
      }

    }

  }
}
