import { Component, ViewChild, Input, Inject, OnInit } from '@angular/core';
import { SearchInputComponent } from '../search-input/search-input.component';
import { Subject, Subscription } from 'rxjs';
import { MatPaginatorIntl } from '@angular/material';
import { Router } from '@angular/router';
import { UpdateEventService } from '../../shared-services/update-event.service';
import { MediaService } from '../../shared-services/media.service';
import { SecurityService } from '../../security/security.service';
import { Filters } from '../../models/filters.model';
import { Page } from '../../models/page.model';
import { EnvService } from '../../shared-services/env.service';

@Component({
  selector: 'app-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.css']
})
export class GenericListComponent implements OnInit {

  private backendUrl: string;

  public showFilters = true;
  isMobile: boolean;

  @ViewChild(SearchInputComponent, {static: false})
  private searchComponent: SearchInputComponent;

  private getNewPage = new Subject<Filters>();

  public totalItems: number;

  roleFiltersActive = false;
  mostrarResumen = false;
  filtros = false;

  filtersLoaded = false;
  subscriptions: Subscription[] = [];
  @Input() mostrarBotonFiltros;
  @Input() genericList;
  @Input() addUrl;
  @Input() addText;
  @Input() isLoading;
  @Input() filters;
  @Input() searchTitle;
  @Input() botonAgregar;
  @Input() permisoCrear;
  @Input() searchPlaceHolder: string;
  @Input() hasActiveFilters = false;
  @Input() pageSize = 10;
  @Input() messageEmpty = 'No se encuentran elementos.';
  @Input() messageFilters = 'No se encuentran elementos que coincidan con el criterio de búsqueda actual.';
  @Input() atributoBusqueda;
  @Input() label: string;
  searchTittle: any;

  constructor(
    protected env: EnvService,
    private paginatorsLabels: MatPaginatorIntl,
    public router: Router,
    protected updateEventService: UpdateEventService,
    private mediaService: MediaService,
    public securityService: SecurityService,
  ) {
    this.backendUrl = env.api + '/backend';
  }

  ngOnInit() {
    this.showFilters = true;
    this.filtersLoaded = true;

    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.showFilters = !this.isMobile;
    }));

    this.paginatorsLabels.itemsPerPageLabel = 'Filas por página:';
    this.genericList.subscribe(t => {
      this.isLoading = false;
      this.totalItems = t['totalElements'];
    });

    this.refreshRoleList();
  }


  refreshRoleList() {
    this.genericList.next(this.getFilters());
  }

  getDefaultFilters() {
    this.filters = {
      name : undefined,
      page: 0,
      size: 10,
    };
  }

  getFilters() {
    return this.filters;
  }

  // Seteo de filtros
  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  onPageChange(page: Page) {
    this.filters.page = page.pageIndex;
    this.filters.size = page.pageSize;
    this.refreshRoleList();
  }

  onSearch(searchText) {
    this.filters.search = this.atributoBusqueda + ':' + searchText;
    this.refreshRoleList();
  }

  clearSearch() {
    this.searchComponent.searchBox.value = '';
    this.refreshRoleList();
  }

  getData(response: Response) {
    const data = response.json();
    if (response.ok) { return data; }
    throw data['hydra:description'] || data['message'];
  }

  displayFilters(boolean: boolean) {
    this.showFilters = boolean;
  }

  onAdd(url: string) {
    this.router.navigate([url]);
  }

  onFiltersChanged(event) {
    this.refreshRoleList();
  }

  removeChip(event) {
    this.refreshRoleList();
  }

  mostrarFiltros() {
    this.filtros = !this.filtros;
  }

}
