import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BackendService } from '../../core/backend.service';
import { MedioPago } from '../../models/medio-pago.model';

@Injectable()
export class MediosPagoResolver implements Resolve<MedioPago> {
  constructor(
    protected router: Router,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id || 'agregar';

      if (id === 'agregar') {
        const medioPago = new MedioPago();
        return medioPago;
      }

      return await this.backendService.get<MedioPago>(MedioPago.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/medio-pago']);
    }
  }
}
