import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyHomeComponent } from './empty-home/empty-home.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { SecurityModule } from '../security/security.module';
import { SharedServicesModule } from '../shared-services/shared-services.module';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from '../core/core.module';
import { VendorModule } from '../core/vendor.module';
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    CoreModule,
    VendorModule,
    SharedComponentsModule,
    SharedServicesModule,
    SecurityModule,
  ],
  declarations: [EmptyHomeComponent]
})
export class HomeModule { }
