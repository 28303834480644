import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Foto } from '../../../models/foto.model';
import { OperationalParametric } from '../../../models/operationalParametric.model';
import { Observable } from 'rxjs';
import { BackendService } from '../../../core/backend.service';
import { SnackService } from '../../../shared-services/snack.service';
import { ApiService } from '../../../core/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { User } from '../../../models/user.model';
import { DataObject } from '../../../models/dataObject.model';

@Component({
  selector: 'app-user-foto-upload',
  templateUrl: './user-foto-upload.component.html',
  styleUrls: ['./user-foto-upload.component.scss']
})
export class UserFotoUploadComponent implements OnInit {
  @Input() fotos: Foto[];
  @Input() estado: OperationalParametric;
  @Input() editable = false;
  @Output() foto = new EventEmitter<string>();

  private re = /(?:\.([^.]+))?$/;
  seleccionarArchivo = 'Foto del usuario*';
  tiposImagenes: OperationalParametric[];


  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService) { }

  async ngOnInit() {
    this.getTiposImagenes();

    if (this.fotos.length === 0) {
      this.agregarImagen();
    }
    const imgs = [];
    this.fotos.forEach(i => {
      const img = new Foto(null);
      img.id = i.id;
      img.name = i.name;
      img.path = i.path;
      if (i.path && i.path.indexOf('http') < 0) {
       this.getImages(img);
      } else {
        img.url = i.path + i.name;
      }

      imgs.push(img);
    });
    this.fotos.splice(0);
    imgs.forEach(i => this.fotos.push(i));

    console.log(this.fotos);
  }

  async getImages(imagen: Foto) {
    await this.backendService.getImagenById(User.path + 'foto/' + imagen.id).subscribe(data => {
      const urlCreator = window.URL;
      const safeUrl: any = this.sanitizer.bypassSecurityTrustUrl(
          urlCreator.createObjectURL(data));
      imagen.url = safeUrl;
      console.log(imagen);
    });
  }

  agregarImagen() {
    this.fotos.push(new Foto(undefined));
  }
  eliminarImagen(index: number) {
    if (this.fotos[index].id) {
      this.dialogService.confirm('Eliminar archivo',
      '¿Está seguro que desea eliminar la foto ' + this.fotos[index].name
       + '?')
      .then( res => {
        if (res) {
          this.deleteFromDb(this.fotos[index].id);
          this.fotos.splice(index, 1);
          if (this.fotos.length === 0) {
            this.fotos.push(new Foto(undefined));
          }
        }
      });
    } else {
      this.fotos.splice(index, 1);
    }
  }
  deleteFromDb(id: number) {
    this.backendService.delete(User.path + 'eliminar-foto/' + id);
  }
  addFile(event, index: number) {
    if (this.soloImagenes(event.target.files[0].name)) {
      this.fotos[index].file = event.target.files[0];
      this.fotos[index].path = event.target.files[0].name;
      this.fotos[index].name = event.target.files[0].name;
      this.foto.emit();
      this.fotos[index].id = undefined;
    }
  }

  soloImagenes(fileName: string) {
    let ext = this.re.exec(fileName)[1];
    ext = ext.toLowerCase();
    if (ext !== 'png' && ext !== 'jpg' && ext !== 'svg' && ext !== 'jpeg' && ext !== 'gif') {
      this.snackService.error('Solo estan permitidos archivos de tipo ".png", ".jpg", ".svg", ".jpeg", ".gif"');
      return false;
    }
    return true;
  }

  async getTiposImagenes() {
    await this.apiService.get<DataObject<OperationalParametric>>(OperationalParametric.path + 'tipos_imagenes/').toPromise().then(res => {
      this.tiposImagenes = res.data;
    });
  }

  public openWindow(img: Foto) {
    this.dialogService.imageViewer(img.name, '', img.url);
    // window.open(img.url, '_blank');
  }
}
