import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Configuracion } from '../../models/configuracion.model';
import { BackendService } from '../../core/backend.service';
import { SnackService } from '../../shared-services/snack.service';
import { DialogService } from '../modals/dialog.service';
import { CambioDolarCompra } from '../../models/cambio-dolar-compra.model';
import { switchMap, share } from 'rxjs/operators';
import { PaginatedList } from 'src/app/models/paginatedList.model';
import { Filters } from 'src/app/models/filters.model';
import { Cotizacion } from 'src/app/models/cotizacion.model';
import { TipoConfiguracion } from 'src/app/models/tipo-configuracion.model';
import { Observer, Observable, Subscription } from 'rxjs';
import { MediaService } from 'src/app/shared-services/media.service';
import { MatPaginatorIntl } from '@angular/material';
import { Page } from 'src/app/models/page.model';

@Component({
  selector: 'app-cambio-dolar-compra',
  templateUrl: './cambio-dolar-compra.component.html',
  styleUrls: ['./cambio-dolar-compra.component.css']
})
export class CambioDolarCompraComponent implements OnInit {

  private filters: Filters = {
    page: 0,
    size: 50,
    sort: 'created,desc'
  };

  @Input() cambioDolarCompra: CambioDolarCompra;
  staticCambioDolarCompra = { valor: null };
  operationalCambioDolarCompra = { valor: null };

  staticDolaresCompra: Configuracion[];
  isLoading: boolean;
  roleFiltersActive = false;
  cotizacionLista = [];
  public showFilters = true;
  isMobile: boolean;
  filtersLoaded = false;
  subscriptions: Subscription[] = [];
  public totalItems: number;

  cotizacionList = this.backendService.newSubjectWithoutDistinct<Filters>()
  .pipe(switchMap(filters => {
    const usersPromise = this.backendService.get<PaginatedList<Cotizacion>>
    (TipoConfiguracion.path + 'dolar_compra_historico' , filters).toPromise();
    return (async () => {
      this.isLoading = true;
      try {
        const permissions = await usersPromise;
        this.cotizacionLista = permissions.data;
        return permissions;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
        this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
      }
    })();
  })).pipe(share()) as Observer<Filters> & Observable<Cotizacion[]>;

  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    private dialogService: DialogService,
    private mediaService: MediaService,
    private paginatorsLabels: MatPaginatorIntl
  ) { }

  ngOnInit() {
    this.backendService.get('/configuraciones/dolar_compra').toPromise().then(
      res => {
        this.staticCambioDolarCompra.valor = res;
      }
    );

    this.getDolarCompraHistorico();

    if (this.cambioDolarCompra) {
      this.staticCambioDolarCompra.valor = this.cambioDolarCompra.valor;
      this.operationalCambioDolarCompra.valor = this.cambioDolarCompra.valor;
    }

    this.showFilters = true;
    this.filtersLoaded = true;

    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.showFilters = !this.isMobile;
    }));

    this.paginatorsLabels.itemsPerPageLabel = 'Filas por página:';
    this.cotizacionList.subscribe(t => {
      this.isLoading = false;
      this.totalItems = t['totalElements'];
    });

    this.refreshRoleList();
  }

  onChange(event: any) {
    this.refreshRoleList();
  }

  onPageChange(page: Page) {
    this.filters.page = page.pageIndex;
    this.filters.size = page.pageSize;
    this.refreshRoleList();
  }

  onFiltersChanged(event) {
    this.refreshRoleList();
  }

  hasCambioDolarCompra() {
    if (this.staticCambioDolarCompra.valor !== null) {
      return true;
    }
    return false;
  }

  async confirmCambioDolarCompra() {
      this.dialogService.confirm('Modificar cotización dolar',
      '¿Está seguro que desea modificar la cotización actual de compra del dólar?')
      .then( res => {
        if (res) {
          this.save(res);
        }
      });
  }

  async save(res) {
    if (res) {
      this.snackService.spinner(true);
      try {
        await this.backendService.update('/configuraciones/cambio_dolar_compra?dolar='
                                                      + this.operationalCambioDolarCompra.valor, null);
        this.refreshRoleList();
        this.snackService.success('Cotización actualizada');
        this.updateView();
        this.snackService.spinner(false);
      } catch (error) {
        this.snackService.spinner(false);
        this.snackService.error(error.message);
      }
    }
  }

  updateView() {
    this.getDolarCompraHistorico();
    this.staticCambioDolarCompra.valor = this.operationalCambioDolarCompra.valor;
  }

  refreshRoleList() {
    this.cotizacionList.next(this.getFilters());
  }

  getFilters() {
    return this.filters;
  }

  async getDolarCompraHistorico() {
    this.backendService.get('/configuraciones/dolar_compra_historico').toPromise().then(
      res => {
        this.staticDolaresCompra = res as [Configuracion];
      }
    );
  }

}
