import { Component, OnInit, ViewChild } from '@angular/core';
import { Filters } from '../../../models/filters.model';
import { GenericListComponent } from '../../../shared-components/generic-list/generic-list.component';
import { switchMap, share } from '../../../../../node_modules/rxjs/operators';
import { PaginatedList } from '../../../models/paginatedList.model';
import { MedioPago } from '../../../models/medio-pago.model';
import { Observer, Observable } from '../../../../../node_modules/rxjs';
import { BackendService } from '../../../core/backend.service';
import { SnackService } from '../../../shared-services/snack.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { SecurityService } from '../../../security/security.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';

@Component({
  selector: 'app-medio-pago-table',
  templateUrl: './medio-pago-table.component.html',
  styleUrls: ['./medio-pago-table.component.css']
})
export class MedioPagoTableComponent implements OnInit {

  isLoading = false;
  roleFiltersActive = false;
  addUrl = '/medio-pago/agregar';

  public filters: Filters = {
    name: undefined,
    page: 0,
    size: 10,
  };
  mediosPagoLista = [];

  @ViewChild(GenericListComponent, {static: false}) genericList;

  addText = 'Agregar medio de pago';
  searchTitle = 'Medios de pago';
  searchPlaceHolder = 'Ingrese el nombre del medio de pago que desea buscar';
  messageEmpty = 'No se encuentran medios de pago.';
  messageFilters = 'No se encuentran medios de pago que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_MEDIO_PAGO';
  atributoBusqueda = 'detalle';

  mediosPagoList = this.backendService.newSubjectWithoutDistinct<Filters>().pipe(
    switchMap(filters => {
      const promise = this.backendService.get<PaginatedList<MedioPago>>(MedioPago.path, filters).toPromise();
      return (async () => {
        this.isLoading = true;
        try {
          const permissions = await promise;
          this.mediosPagoLista = permissions.data;
          return permissions;
        } catch (error) {
          return [];
        } finally {
          this.isLoading = false;
          this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
        }
      })();
    }))
  .pipe(share()) as Observer<Filters> & Observable<MedioPago[]>;

  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    public securityService: SecurityService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
  }

  onEditMedioPago(medioPago: MedioPago) {
    this.router.navigate(['/medio-pago/editar', medioPago.id]);
  }

  async onDeleteMedioPago(medioPago: MedioPago) {

    this.dialogService.confirm('Eliminar Medio de Pago',
    '¿Está seguro que desea eliminar el medio de pago ' + medioPago.detalle + '?')
    .then( res => {
      if (res) {
        this.deleteMedioPago(res, medioPago);
      }
    });
  }

  async deleteMedioPago(res, medioPago: MedioPago) {

    if (res) {
      this.snackService.spinner(true);
      try {
        await this.backendService.delete(MedioPago.path + medioPago.id);
        this.snackService.success('Medio de pago eliminado correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.genericList.refreshRoleList();
      }
    }
  }

}
