import { Component, OnInit, Input } from '@angular/core';
import { TelefonoCliente } from '../../../models/telefonoCliente.model';
import { ApiService } from '../../../core/api.service';
import { Telefono } from '../../../models/telefono.model';
import { Email } from '../../../models/email.model';
import { ParametricWithIcon } from '../../../models/parametricWithIcon.model';
import { EmailCliente } from '../../../models/emailCliente.model';
import { DataObject } from '../../../models/dataObject.model';

@Component({
  selector: 'app-usuario-datos-contacto',
  templateUrl: './usuario-datos-contacto.component.html',
  styleUrls: ['./usuario-datos-contacto.component.scss']
})
export class UsuarioDatosContactoComponent implements OnInit {

  @Input() telefonos = [];
  @Input() emails = [];
  @Input() submitted;

  public tiposTelefonos: ParametricWithIcon[];
  public tiposEmails: ParametricWithIcon[];
  public maxLengthTelefonos = 3;
  public maxLengthEmails = 3;
  private TelefonoCliente = new TelefonoCliente();
  private EmailCliente = new EmailCliente();

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.getTiposTelefonos();
    this.getTiposEmails();
  }

  async getTiposTelefonos() {
    await this.apiService.get<DataObject<ParametricWithIcon>>(ParametricWithIcon.path + 'tipos_telefonos/')
    .toPromise().then( (res: any) => {
      this.tiposTelefonos = res.data;
    });
    for (const tipoTelefono of this.tiposTelefonos) {
      switch (tipoTelefono.id) {
        case this.TelefonoCliente.staticParticular:
          tipoTelefono.icono = 'phone';
          break;
        case this.TelefonoCliente.staticCelular:
          tipoTelefono.icono = 'phone_android';
          break;
        case this.TelefonoCliente.staticLaboral:
          tipoTelefono.icono = 'phonelink_ring';
          break;
        default:
          tipoTelefono.icono = 'phone';
      }
    }
  }
  async getTiposEmails() {
    await this.apiService.get<DataObject<ParametricWithIcon>>(ParametricWithIcon.path + 'tipos_email/').toPromise().then( (res: any) => {
      this.tiposEmails = res.data;
    });
    for (const tipoEmail of this.tiposEmails) {
      switch (tipoEmail.id) {
        case this.EmailCliente.staticPersonal:
          tipoEmail.icono = 'contact_mail';
          break;
        case this.EmailCliente.staticLaboral:
          tipoEmail.icono = 'email';
          break;
        default:
          tipoEmail.icono = 'email';
      }
    }
  }

  agregarTelefono() {
    this.telefonos.push(new Telefono('', 1));
  }

  agregarEmail() {
    this.emails.push(new Email(1, '', false));
  }

  eliminarTelefono(index: number) {
    this.telefonos.splice(index, 1);
    if (this.telefonos.length === 0) {
      this.agregarTelefono();
    }
  }
  eliminarEmail(index: number) {
    this.emails.splice(index, 1);
    if (this.emails.length === 0) {
      this.agregarEmail();
    }
  }

}
