import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UniqueIdentifierService {

  private uniqueValue = Date.now();
    constructor() { }


  public getUniqueIdentifier(identifier: any) {
    return identifier + this.uniqueValue;
  }
}
