import { Component, OnInit, ViewChild, OnDestroy, Inject, HostListener, Output, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { SidenavComunicationService } from './sidenav-comunitacation.service';
import { MediaService } from '../../shared-services/media.service';
import { ScrollComunicationService } from '../../shared-services/scroll-comunication.service';
import { MatExpansionPanel} from '@angular/material/expansion';
import { DOCUMENT } from '@angular/common';
import { MenuActiveService } from 'src/app/shared-services/menu-active.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  user: any;
  title: string;
  avatarURL: string;
  year: number;
  subscriptions: Subscription[] = [];
  userCanEnterCanvass: boolean;
  userCanEnterOrganization: boolean;
  userCanEnterTerritory: boolean;
  elem;
  fullscreenOn = false;
  openSidebar = false;
  home = false;
  subcategoria = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];

  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: SecurityService,
    private securityService: SecurityService,
    private sidenavComunicationService: SidenavComunicationService,
    private scrollComunicationService: ScrollComunicationService,
    private mediaService: MediaService,
    private menuActive: MenuActiveService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.year = new Date().getFullYear();
    this.router.events.subscribe(event => {
      if (event) {
        document.title = 'Smart Office';
        this.route.snapshot.children.forEach(r => {
          if (r.data.title) {
            this.title = r.data.title;
          }
        });
        document.title += this.title ? ' - ' + this.title : '';
      }
    });
  }

  logout() { this.authService.logout(); }

  closePanels(...panels: MatExpansionPanel[]) {
    panels.forEach(p => p.close());
  }

  async ngOnInit() {
    this.user = this.authService.user;
    this.userCanEnterCanvass = true;
    this.userCanEnterOrganization = true;
    this.userCanEnterTerritory = true;
    this.elem = document.documentElement;

    // Determina si el modo pantalla completa esta activado o no
    if ( window.innerHeight === screen.height) {
      this.fullscreenOn = true;
    }

    this.subscriptions.push(this.sidenavComunicationService.onGetSidenavStatus.subscribe(() => {
      this.updateSideNavStatus();
    }));

    this.subscriptions.push(
      this.mediaService.isMobile.subscribe(isMobile => {
        if (this.sidenav) {
          this.closeSidenav();

          this.sidenav.mode = isMobile ? 'over' : 'side';

          if (!isMobile) {
            setTimeout(() => { this.openSidenav(); }, 500);
          }
        }
      })
    );
    this.subscriptions.push(this.menuActive.onGetMenuStatus.subscribe(subject => {
      setTimeout(() => (this.expanded(subject), 0));
    }));
    this.subscriptions.push();
    this.expanded(this.router.url);
  }

  inicio() {
    return this.home === true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onScroll(event: any) {
    this.scrollComunicationService.scroll(event);
  }

  updateSideNavStatus() {
    this.sidenavComunicationService.sidenavChange(this.sidenav.opened);
  }

  toggleSidenav() {
    this.sidenav.toggle();
    this.openSidebar = !this.openSidebar;
    if (this.sidenav.mode === 'side') {
      this.updateSideNavStatus();
    }
  }

  openSidenav() {
    this.sidenav.open();
    if (this.sidenav.mode === 'side') {
      this.updateSideNavStatus();
    }
  }

  closeSidenav() {
      this.sidenav.close();
      if (this.sidenav.mode === 'side') {
        this.updateSideNavStatus();
      }
  }

  closeOverSidenav() {
    if (this.sidenav.mode === 'over') {
      this.sidenav.close();
    }
  }

  async fullscreen() {
    if (this.fullscreenOn) {
      await this.closeFullscreen();
    } else {
      await this.openFullscreen();
    }
    this.fullscreenOn = !this.fullscreenOn;
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  async expanded(url) {
    this.home = true;
    let submenu = 0;
    switch (url) {
      case '/usuarios':
        submenu = 1;
        break;
      case '/roles':
        submenu = 2;
        break;
      case '/permisos':
        submenu = 3;
        break;
      case '/sucursales':
        submenu = 4;
        break;
      case '/clientes':
        submenu = 5;
        break;
      case '/tipo-persona':
        submenu = 6;
        break;
      case '/tipo-servicio':
        submenu = 7;
        break;
      case '/productos':
        submenu = 8;
        break;
      case '/categorias':
        submenu = 9;
        break;
      case '/subcategorias':
        submenu = 10;
        break;
      case '/talles':
        submenu = 11;
        break;
      case '/colores':
        submenu = 12;
        break;
      case '/cotizacion':
        submenu = 13;
        break;
      case '/comprobante':
        submenu = 14;
        break;
      case '/medio-pago':
        submenu = 15;
        break;
    }
    for (let x = 0; x < this.subcategoria.length; x++) {
      if (submenu === x && submenu !== 0 ) {
        this.subcategoria[submenu] = true;
        this.home = false;
      } else {
        this.subcategoria[x] = false;
      }
    }

    this.router.navigateByUrl(url);
  }

}
