import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-entidad-operacion',
  templateUrl: './entidad-operacion.component.html',
  styleUrls: ['./entidad-operacion.component.css']
})
export class EntidadOperacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
