import { Component, OnInit } from '@angular/core';
import { BackendService } from '../../../core/backend.service';
import { SnackService } from '../../../shared-services/snack.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { MediaService } from '../../../shared-services/media.service';
import { SecurityService } from '../../../security/security.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { Filters } from '../../../models/filters.model';
import { Subscription, Observer, Observable } from '../../../../../node_modules/rxjs';
import { switchMap, share } from '../../../../../node_modules/rxjs/operators';
import { Color } from '../../../models/color.model';
import { PaginatedList } from '../../../models/paginatedList.model';

@Component({
  selector: 'app-color-table',
  templateUrl: './color-table.component.html',
  styleUrls: ['./color-table.component.css']
})
export class ColorTableComponent implements OnInit {

  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    private mediaService: MediaService,
    public securityService: SecurityService,
    private dialogService: DialogService,
  ) { }

  public filters: Filters = {
    description: undefined,
    page: 0,
    size: 10,
    sort: 'description'
  };

  columns = [
    { name: 'code',  label: 'Color' },
    { name: 'description',  label: 'Descripción' }
  ];

  public showFilters = true;
  isMobile: boolean;

  public totalItems: number;

  filtersActive = false;
  mostrarResumen = false;
  isLoading = false;
  pageSize = 25;

  filtersLoaded = false;
  subscriptions: Subscription[] = [];

  addText = 'Agregar Color';
  addUrl = '/colores/agregar';
  searchTitle = 'Color';
  searchPlaceHolder = 'Ingrese el nombre del color que desea buscar';
  messageEmpty = 'No se encuentran colores.';
  messageFilters = 'No se encuentran colores que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_COLOR';
  coloresLista = [];
  atributoBusqueda: string = 'description';

  itemsList = this.backendService.newSubjectWithoutDistinct<Filters>()
  .pipe(switchMap(filters => {
    const coloresPromise = this.backendService.get<PaginatedList<Color>>(Color.path, filters).toPromise();
    return (async () => {
      this.isLoading = true;
      try {
        const colores = await coloresPromise;
        this.coloresLista = colores.data;
        return colores;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
        this.filtersActive = !(this.filters.search === '' || this.filters.search === undefined);
      }
    })();
  })).pipe(share()) as Observer<Filters> & Observable<Color[]>;

  ngOnInit() {

    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.showFilters = !this.isMobile;
    }));

    this.refreshItemsList();
  }

  refreshItemsList() {
    this.itemsList.next(this.getFilters());
  }

  getDefaultFilters() {
    this.filters = {
      description : undefined,
      sort: 'orden',
      page: 0,
      size: 10,
    };
  }

  getFilters() {
    return this.filters;
  }

  onEdit(color: Color) {
    this.router.navigate(['/colores/editar', color.id]);
  }

  async onDelete(color: Color) {

    this.dialogService.confirm('Eliminar color',
    '¿Está seguro que desea eliminar el color ' + color.description + '?')
    .then( res => {
      if (res) {
        this.deleteColor(res, color);
      }
    });

  }

  async deleteColor(res, color: Color) {

    if (res) {

      this.snackService.spinner(true);

      try {
        await this.backendService.delete(Color.path + color.id);
        this.snackService.success('Colores eliminados correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.refreshItemsList();
      }

    }

  }

}
