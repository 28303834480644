import {Injectable, Inject} from '@angular/core';
import { NgForm, FormArray, FormControl, FormGroup } from '@angular/forms';


@Injectable()
export class FormValidatorService {

  public isFormValid(applicationForm: NgForm): boolean {
    let result = true;

    if (!applicationForm.valid) {
      Object.keys(applicationForm.controls).forEach(key => {
        this.iterateControls(applicationForm.controls[key]);
      });

      result = false;
    }

    return result;
  }

  iterateControls(container) {
    if (container instanceof FormControl) {
      container.markAsTouched()
    }

    if (container instanceof FormGroup || container instanceof FormArray) {
      Object.keys(container.controls).forEach(key => {
        this.iterateControls(container.controls[key]);
      });
    }
  }
}
