import { OperationalParametric } from './operationalParametric.model';
import { Provincia } from './provincia.model';
import { Departamento } from './departamento.model';
import { PaisCliente } from './paisCliente.model';
import {EmailCliente} from "./emailCliente.model";
import {Email} from "./email.model";
import {Telefono} from "./telefono.model";

export class CuentaBancaria {

  id: number;
  nombre: string;
  cbu: string;
  alias: string;

  constructor() {
  }



}
