import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SecurityService } from './security.service';
import { SnackService } from '../shared-services/snack.service';

@Injectable({
  providedIn: 'root'
})
export class LoguedGuardService implements CanActivate, CanActivateChild {

  constructor(public securityService: SecurityService,
              public router: Router,
              private snackService: SnackService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const url = route.url.join('');

    if (url === '' || url === 'home') {

      if (this.securityService.logged()) {
        return true;
      }

      this.snackService.error('Sesión expirada');
      this.securityService.logout();
      return false;

    }

    const expectedPermission = route.firstChild !== null ? route.firstChild.data.expectedPermission : route.data.expectedPermission;

    if (!this.securityService.logged()) {
      this.snackService.error('Sesión expirada');
      this.securityService.logout();
      return false;
    }

    if (!this.securityService.hasPermission(expectedPermission)) {
      this.snackService.error('Usted no tiene permisos para ingresar');
      this.securityService.redirectToDefault();
      return false;
    }

    return true;

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }

}
