import { Injectable, Component, Inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';
import { defaults, isNil } from 'lodash';
import { TranslateService } from '../core/translate.service';

@Component({
  selector: 'app-snack-service-msg',
  styleUrls: ['./snack.service.scss'],
  template: `<mat-icon>{{ data.ico }}</mat-icon><div *ngFor="let msg of data.translation">{{ msg }}</div>`,
})
export class SnackServiceMsgComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) { }
}

@Component({
  selector: 'app-snack-service-spinner',
  styleUrls: ['./snack.service.scss'],
  template: `<div class="snack-spinner" *ngIf="config.show"><mat-spinner color="accent"></mat-spinner></div>`,
})
export class SnackServiceSpinnerComponent {
  static config = { show: false };
  config = SnackServiceSpinnerComponent.config;
  constructor(
  ) { }
}

@Component({
  selector: 'app-snack-service-permission-message',
  styleUrls: ['./snack.service.scss'],
  template: `<!--<div class="snack-spinner" *ngIf="config.show"><mat-spinner color="accent"></mat-spinner></div>-->
  <div class="snack-spinner" *ngIf="config.show">
    <div class="permission-message-container">
      <mat-icon class="permission-message-text">error</mat-icon> <span class="permission-message-text">{{config.message}}</span>
    </div>
  </div>`,
})
export class SnackServicePermissionMessageComponent {
  static config = { show: false, message: '' };
  config = SnackServicePermissionMessageComponent.config;

  constructor(
  ) { }

  static showPermissionMessage(showMessage?: boolean, message?: string) {
    if (message) {
      this.config.message = message;
    } else {
      this.config.message = 'Usted no tiene permisos para realizar esta accion';
    }
    this.config.show = showMessage;
  }
}

@Injectable()
export class SnackService {
  defults = { duration: 3000, defaultDuration: 3000 };
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  success(msg: string) { return this.msg(msg, 'cloud_done', 'mat-success'); }
  info(msg: string) { return this.msg(msg, 'info_outline', 'mat-accent'); }
  warn(msg: string) { return this.msg(msg, 'warning', 'mat-primary'); }
  error(msg: string) { return this.msg(msg, 'error', 'mat-warn'); }
  msg(msg: string, ico: string, bgClass: string) {

    let translation: any = this.translate.translate(msg);

    if ( !(translation instanceof Array)) {
      const translationArray = [];
      translationArray[0] = translation;
      translation = translationArray;
    }

    // this.defults.duration = this.defults.defaultDuration * (<string[]>translation).length
    return this.snackBar.openFromComponent(
      SnackServiceMsgComponent,
      defaults({ data: { translation, ico } }, this.defults, {
        extraClasses: [bgClass, 'mat-form-field-ripple']
      }, {panelClass: [bgClass=='mat-success'?'success-snackbar':'danger-snackbar']})
    );
  }
  spinner(show?: boolean) {
    SnackServiceSpinnerComponent.config.show = isNil(show)
    ? !SnackServiceSpinnerComponent.config.show
    : show;
  }

  permissionMessage(message?: string, callback?) {
    SnackServicePermissionMessageComponent.showPermissionMessage(true, message);
    setTimeout( () => SnackServicePermissionMessageComponent.showPermissionMessage(false), 2000,  callback());
  }
}
