import { Injectable } from '../../../../node_modules/@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '../../../../node_modules/@angular/router';
import { Sucursal } from '../../models/sucursal.model';
import { SnackService } from '../../shared-services/snack.service';
import { SecurityService } from '../security.service';
import { BackendService } from '../../core/backend.service';

@Injectable()
export class SucursalResolver implements Resolve<Sucursal> {
  constructor(
    protected router: Router,
    protected snackService: SnackService,
    protected securityService: SecurityService,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id || 'agregar';

      if (id === 'agregar') {
        let sucursal = new Sucursal();
        return sucursal;
      }
      return await this.backendService.get<Sucursal>(Sucursal.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/sucursal']);
    }
  }
}
