export const LANG_ES_NAME = 'es';

// usar @number@ en el string resultado para que el servicio coloque el indice

export const LANG_ES_TRANS = {

    'This territory already has an owner.':
        'Este territorio ya tiene un coordinador asignado. Solo puede haber un coordinador por territorio.',
    'Password already requested' :
        'Tu solicitud de recuperación de contraseña fue registrada. Por favor revisa tu casilla de e-mail',
    'volunteer': 'voluntario',
    'team': 'equipo',
    'related': 'Contacto',
    'leaders': 'Equipo Nacional',
    'responsible: This value should not be null.':
        'Debe seleccionar un responsable.',
    'The type of the "areaCode" attribute must be "int", "NULL" given.':
        'Error en el codigo de area del telefono, verifique los telefonos ingresados',
    'addresses.territory: El territorio no puede ser nulo.':
        'Direccion: debe completar la provincia y cuidad',
    'name: This value should not be blank.':
        'El nombre no puede quedar en blanco',
    'addresses.doorNumber: La altura de la calle no puede ser nula.':
        'Debe especificar la altura de la calle',
    'addresses.street: La calle no puede ser nula.':
        'Debe especificar el nombre de la calle',
    'addresses.latitude: La latitud no puede ser nula.':
        'Debe seleccionar la ubicacion geografica',
    'addresses.longitude: La longitud no puede ser nula.':
        'Debe seleccionar la ubicacion geografica',
    'The owner does not have permission in this territory.':
        'Usted no tiene permisos para regitrar datos en este territorio',
    'Already exists a user with this email':
        'Ya existe un usuario con el mail ingresado',
    'contact.phones.phone.areaCode: El código de área no es válido':
        'El código de área no es válido, por favor revise los telefonos',
    'PROVINCIA':
        'PROVINCIAS',
    'SECCIÓN':
        'SECCIONES',
    'REGION':
        'REGIONES',
    'DEPARTAMENTO':
        'DEPARTAMENTOS',
    'CIUDAD':
        'CIUDADES',
    'BARRIO':
        'BARRIOS',
    'ZONA':
        'ZONAS',
    'phones.phone.areaCode: El código de área no puede ser nulo.':
        'Teléfono: El código de área no puede estar en blanco.',
    'phones.phone.phone: El teléfono no puede ser nulo.':
        'Teléfono: El teléfono no puede estar en blanco',
    'phones.phone.areaCode: El código de área debe tener entre 2 y 4 dígitos':
        'Teléfono: El código de área debe tener entre 2 y 4 dígitos',
    'phones.phone.phone: El número debe tener entre 6 y 8 dígitos':
        'Teléfono: El número debe tener entre 6 y 8 dígitos',
    'phones.phone.areaCode.phone: El código de área + el número debe tener 10 dígitos':
        'Teléfono: El código de área + el número debe tener 10 dígitos',
    'phones.phone.areaCode: El código de área no es válido':
      'Teléfono: El código de área no es válido',
    'data_entry': 'Data Entry',
    'viewer': 'Visualizador',
    'owner': 'Coordinador',
    'organization': 'Responsable de organización',
    'schedules: This collection should contain 1 element or more.':
        'Debe ingresar al menos un horario de atención',
    'ACL permission for change permission is not granted.':
        'Usted no tiene permisos para realizar esta acción',
    'create':
      'Creación',
    'update':
      'Modificación',
    'phones.type: This value should not be blank.':
      'El contacto tiene un tipo de teléfono inválido',
    'The type of the "type" attribute must be "string", "NULL" given.':
      'El contacto tiene un tipo de teléfono inválido',
    'email: This value is already used.':
      'Ese email ya fue registrado previamente',
    'contact: Ya existe otro contacto con ese numero de documento y genero':
      'El número de documento ya fue registrado previamente ',
    'The User has not permission for this territory':
      'Usted no tiene permisos en el territorio seleccionado',
    'A territory with contacts cannot be hidden.':
       'No se puede ocultar un territorio que contenga voluntarios y/o equipos.',
    'This combination of user permission is already in use.':
      'El usuario seleccionado ya posee el permiso que está intentando asignarle.'
};
