import { TipoMoneda } from './tipoMoneda.model';

export class MedioPago {

    static path = '/medio-pago/';

    id: number;
    detalle: string;
    fungible: boolean;
    tipoMoneda: TipoMoneda;

    constructor() {
        this.tipoMoneda = new TipoMoneda();
    }
}
