export class TelefonoCliente {

    static Laboral;
    static Particular;
    static Celular;
    static DescLaboral;
    static DescParticular;
    static DescCelular;
    constructor() {
      TelefonoCliente.Particular = 1;
      TelefonoCliente.Celular = 2;
      TelefonoCliente.Laboral = 3;
      TelefonoCliente.DescLaboral = 'Laboral';
      TelefonoCliente.DescParticular = 'Particular';
      TelefonoCliente.DescCelular = 'Celular';
    }

    get staticParticular() {
      return TelefonoCliente.Particular;
    }
    get staticCelular() {
      return TelefonoCliente.Celular;
    }
    get staticLaboral() {
      return TelefonoCliente.Laboral;
    }
    get staticDescParticular() {
      return TelefonoCliente.DescParticular;
    }
    get staticDescCelular() {
      return TelefonoCliente.DescCelular;
    }
    get staticDescLaboral() {
      return TelefonoCliente.DescLaboral;
    }
}
