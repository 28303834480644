import { Component, OnInit, Input } from '@angular/core';
import { Cliente } from '../../models/cliente.model';

@Component({
  selector: 'app-cliente-datos-personales-card',
  templateUrl: './cliente-datos-personales-card.component.html',
  styleUrls: ['./cliente-datos-personales-card.component.css']
})
export class ClienteDatosPersonalesCardComponent implements OnInit {
  @Input() cliente: Cliente;
  @Input() validacionComponent = false;
  constructor() { }

  ngOnInit() {
  }

}
