import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackService } from '../../../shared-services/snack.service';
import { BackendService } from '../../../core/backend.service';
import { FormValidatorService } from '../../../shared-services/form-validator.service';
import { MediaService } from '../../../shared-services/media.service';
import { NavigationService } from '../../../shared-services/navigation.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { Role } from '../../../models/role.model';
import { Observable } from 'rxjs';
import { User } from '../../../models/user.model';
import { StrengthPasswordService } from '../../../shared-services/strength-password.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.css']
})
export class UserChangePasswordComponent implements OnInit {

  @ViewChild('form', {static: false}) public userForm: NgForm;

  user: any;

  pass: string;
  retryPass: string;

  messageTitle: string;
  urlBack: string;

  isHD = false;
  validationFail = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackService: SnackService,
    private backendService: BackendService,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private navigationService: NavigationService,
    private dialogService: DialogService,
    @Inject(StrengthPasswordService) private passwordStrength,
  ) { }

  _selectedRole: Role[] = [];

  roleList: Observable<Role[]>;

  ngOnInit() {

    this.route.data.subscribe(data => {
      this.user = data.user;
    });

    this.messageTitle = this.user.myPassword ? 'Cambiar mi contraseña' : 'Cambio de contraseña de ' + this.user.user.name ;
    this.urlBack = this.user.myPassword ? '/perfil' : '/usuarios';
    this.mediaService.isHighDefinition.subscribe(isHD => this.isHD = isHD);
  }

  back() {
    this.navigationService.back();
  }

  async save() {
    if (this.validateForm()) {
      this.invokeSave();
    }
  }

  validateForm() {
    let validationsOK = false;

    // Valida el resto de los controles con sus validadores
    if (this.formValidatorService.isFormValid(this.userForm)) {

      if (this.pass !== this.retryPass) {
        this.snackService.error('Las claves no coinciden');

        validationsOK = false;
        this.validationFail = true;

        return validationsOK;
      }

      validationsOK = true;
      this.validationFail = false;
    } else {
      validationsOK = false;
      this.validationFail = true;
    }

    return validationsOK;
  }

  private async invokeSave() {

    if (this.validateForm()) {
      this.ConfirmSave();
    }

    return;
  }

  async ConfirmSave() {
    this.dialogService.confirm(this.messageTitle,
    '¿Está seguro que desea cambiar la contraseña?')
    .then( res => {
      if (res) {
        this.saveRole(res);
      }
    });
  }

  async saveRole(res) {
    if (res) {

      this.snackService.spinner(true);

      const fuerzaPassword = this.passwordStrength.measureStrength(this.pass);
      if (fuerzaPassword < 4) {
        this.snackService.error('La contraseña ingresada debe ser del nivel máximo');
        this.snackService.spinner(false);
      } else {
        if (this.user.myPassword) { // Cambia la contraseña del usuario logueado
        try {
          await this.backendService.update(User.path + 'change_my_password?password=' + this.pass, null);
          this.snackService.success('Clave modificada correctamente');
          this.router.navigate(['/perfil']);
        } catch (ex) {
          this.snackService.error(ex.message);
        } finally {
          this.snackService.spinner(false);
        }
        } else {
          try {
            await this.backendService.update(User.path + 'change_password?id=' + this.user.user.id + '&password=' + this.pass, null);
            this.snackService.success('Clave modificada correctamente');
            this.router.navigate(['/usuarios']);
          } catch (ex) {
            this.snackService.error(ex.message);
          } finally {
            this.snackService.spinner(false);
          }
        }
      }
    }
  }
}
