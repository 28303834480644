import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {catchError, map} from 'rxjs/operators';
import { EnvService } from '../shared-services/env.service';

export interface MailCredentials {
  email: string;
  password: string;
}

export interface MailCredential {
  email: string;
}

export interface SignInCredential {
  username: string;
  password: string;
  app: number;
}

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  private tokenId = 'jwtToken';
  private jwtTokenExpiredData = 'jwtTokenExpiredData';
  private backendUrl: string;

  constructor(
    protected env: EnvService,
    private http: HttpClient,
    private router: Router,
  ) {
    this.backendUrl = env.api;
  }

  public logged(): boolean {

    if (!this.token || !this.isValidToken()) {
      return false;
    }

    return true;
  }

  get token() {
    return localStorage.getItem(this.tokenId);
  }

  get user() {
    if (!this.logged) {
      return;
    }

    const data = JSON.parse(atob(this.token.split('.')[1]));

    const names = [data.name, data.lastname];

    return {
      id: data.id,
      email: data.username,
      name: names.every(n => n) ? names.join(' ') : names.find(n => n) || '--',
      roles: data.user_role,
    };
  }

  login(credentials: MailCredentials) {
    const credenciales = {app: 2, password: credentials.password, username: credentials.email};

    const url = this.backendUrl + '/login/';

    return this.http.post<any>(url, credenciales, { headers: this.loginHeaders() }).pipe(map(res => {

      const token = res.token;
      localStorage.setItem(this.tokenId, token);

      const tokenData = this.tokenData(token);

      const expiresAt = moment().add(tokenData.exp, 'second');

      localStorage.setItem(this.jwtTokenExpiredData, JSON.stringify(expiresAt.valueOf()));

      return token as string;

    })).toPromise();

  }

  logout() {
    localStorage.removeItem(this.tokenId);
    this.router.navigate(['/login']);
  }

  private loginHeaders() {

    return new HttpHeaders(
      { 'Content-Type': 'application/ld+json' }
    );

  }

  authHeaders() {
    return new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
  }

  private tokenData(token) {
    return JSON.parse(atob(this.token.split('.')[1]));
  }

  private isValidToken() {
    return moment().isBefore(this.getExpiration());
  }

  private getExpiration() {
    const expiration = localStorage.getItem(this.jwtTokenExpiredData);
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  public hasPermission(permissionExpected): boolean {

    let retorno = false;

    if (this.token === null) {
      return false;
    }

    this.tokenData(this.token).auth.forEach(auth => {
      if (auth.authority === permissionExpected) {
        retorno = true;
        return;
      }
    });

    return retorno;

  }

  redirectToDefault() {
    this.router.navigate(['login']);
  }

}
