import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { GenericTableComponent, HasAction, Actions, Page } from '../../../shared-components/generic-table/generic-table.component';
import { Subject, Subscription, Observer, Observable } from 'rxjs';
import { Role } from '../../../models/role.model';
import { BackendService } from '../../../core/backend.service';
import { MatPaginatorIntl } from '@angular/material';
import { SnackService } from '../../../shared-services/snack.service';
import { Router } from '@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { MediaService } from '../../../shared-services/media.service';
import { SecurityService } from '../../security.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { share, switchMap } from 'rxjs/operators';
import { PaginatedList } from '../../../models/paginatedList.model';
import { Filters } from '../../../models/filters.model';
import { EnvService } from '../../../shared-services/env.service';

@Component({
  selector: 'app-role-table',
  templateUrl: './role-table.component.html',
  styleUrls: ['./role-table.component.css']
})
export class RoleTableComponent implements OnInit {

  private backendUrl: string;

  public filters: Filters = {
    name: undefined,
    page: 0,
    size: 10,
  };

  columns = [
    { name: 'name',  label: 'Rol' },
    { name: 'displayName', label: 'Nombre' },
    { name: 'description', label: 'Descripcion' }
  ];

  public showFilters = true;
  isMobile: boolean;

  @ViewChild(GenericTableComponent, {static: false})
  private genericTableComponent: GenericTableComponent;

  private getNewPage = new Subject<Filters>();

  public totalItems: number;

  public hasActions: HasAction;

  roleFiltersActive = false;
  mostrarResumen = false;
  isLoading = false;

  rolesLista = [];

  filtersLoaded = false;
  subscriptions: Subscription[] = [];
  addText = 'Agregar Rol';
  addUrl = '/roles/agregar';
  searchTitle = 'Roles';
  searchPlaceHolder = 'Ingrese el nombre del rol que desea buscar';
  messageEmpty = 'No se encuentran roles.';
  messageFilters = 'No se encuentran roles que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_ROL';
  atributosBusqueda = 'name';

  roleList = this.backendService.newSubjectWithoutDistinct<Filters>().pipe(
  switchMap(filters => {
    const usersPromise = this.backendService.get<PaginatedList<Role>>(Role.path, filters).toPromise();
    return (async () => {
      this.isLoading = true;
      try {
        const permissions = await usersPromise;
        this.rolesLista = permissions.data;
        return permissions;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
        this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
      }
    })();
  })).pipe(share()) as Observer<Filters> & Observable<Role[]>;

  constructor(
    protected env: EnvService,
    private backendService: BackendService,
    private paginatorsLabels: MatPaginatorIntl,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    private mediaService: MediaService,
    public securityService: SecurityService,
    private dialogService: DialogService,
  ) {
    this.backendUrl = env.api + '/backend';
  }

  ngOnInit() {
    this.showFilters = true;
    this.filtersLoaded = true;

    this.hasActions = new HasAction();

    this.hasActions.hasAction = true;
    this.hasActions.actions = [{ 'element': Actions.edit, 'permission': 'EDITAR_ROL'},
                              { 'element': Actions.delete, 'permission': 'ELIMINAR_ROL'}];

    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.showFilters = !this.isMobile;
    }));

    this.paginatorsLabels.itemsPerPageLabel = 'Filas por página:';

    this.roleList.subscribe(t => {
      this.isLoading = false;
      this.totalItems = t['totalElements'];
    });

    this.refreshRoleList();
  }

  refreshRoleList() {
    this.roleList.next(this.getFilters());
  }

  getDefaultFilters() {
    this.filters = {
      name : undefined,
      page: 0,
      size: 10,
    };
  }

  getFilters() {
    return this.filters;
  }

  onEditRole(role: any) {
    this.router.navigate(['/roles/editar', role.id]);
  }

  async onDeleteRole(role: Role) {

    this.dialogService.confirm('Eliminar rol',
    '¿Está seguro que desea eliminar el rol ' + role.name + '?')
    .then( res => {
      if (res) {
        this.deleteRole(res, role);
      }
    });

  }

  async deleteRole(res, role: Role) {

    if (res) {

      this.snackService.spinner(true);

      try {
        await this.backendService.delete(Role.path + role.id);
        this.snackService.success('Rol eliminado correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.refreshRoleList();
      }

    }

  }

}
