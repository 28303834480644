import { CategoriaProducto } from './categoria-producto.model';

export class Sugerencia {

  static path = '/productos/sugerencias/';

  id: number;
  description: string;
  origen: string;

  interna() {
    return this.origen === CategoriaProducto.codInterna;
  }
}
