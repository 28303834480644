import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, OnDestroy } from '@angular/core';
import { Direccion } from '../../models/direccion.model';
import { Provincia } from '../../models/provincia.model';
import { PaisCliente } from '../../models/paisCliente.model';
import {} from 'googlemaps';
import { HttpClient } from '../../../../node_modules/@angular/common/http';
import { ApiService } from 'src/app/core/api.service';
import { OperationalParametric } from 'src/app/models/operationalParametric.model';
import {MatSelectChange} from "@angular/material/select";
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {DataObject} from "../../models/dataObject.model";
import {UniqueIdentifierService} from "../../shared-services/unique-identifier.service";

@Component({
  selector: 'app-cliente-datos-direcciones',
  templateUrl: './cliente-datos-direcciones.component.html',
  styleUrls: ['./cliente-datos-direcciones.component.scss']
})
export class ClienteDatosDireccionesComponent implements OnInit, OnChanges, OnDestroy {

  @Input() direccion: Direccion;
  @Input() index: number;
  @Input() provincias;
  @Input() paisesList;
  @Input() submitted;
  @Output() changeDireccion = new EventEmitter<void>();

  PaisCliente = new PaisCliente();
  tiposDomiciliosList: OperationalParametric[] = [];
  listaPais;
  listaProv;
  TipoDomicilioSelected: any;
  private selectedValue: string;

  public paisFilterCtrl: FormControl = new FormControl();
  public filteredPaises: ReplaySubject<OperationalParametric[]> = new ReplaySubject<OperationalParametric[]>(1);
  protected _onDestroyPais = new Subject<void>();

  public provinciaFilterCtrl: FormControl = new FormControl();
  public filteredProvincias: ReplaySubject<Provincia[]> = new ReplaySubject<Provincia[]>(1);
  protected _onDestroyProvincia = new Subject<void>();

  @ViewChild('form', {static: false}) public clienteForm;

  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private uniqueService: UniqueIdentifierService
  ) {
   }

  async ngOnInit() {
    if (this.direccion.pais.id === undefined) {
      this.direccion.pais.id = 55;
      this.direccion.pais.description = 'ARGENTINA';
      this.direccion.provincia = new Provincia(1);
    }
    this.changeDireccion.emit();
    this.getTiposDomicilios();

    this.selectedValue = this.mappertTipoDomicilio(this.direccion.tipoDomicilio.id);

    this.paisFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroyPais))
    .subscribe(() => {
      this.filterPaises();
  });
    this.provinciaFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroyProvincia))
    .subscribe(() => {
      this.filterProvincias();
  });
  }


  ngOnChanges() {
    if (this.submitted) {
      this.clienteForm.submitted = true;
    }
    if (this.paisesList) {
      this.filteredPaises.next(this.paisesList.slice());
    }
    if (this.provincias) {
      this.filteredProvincias.next(this.provincias.slice());
    }
  }

  ngOnDestroy() {
    this._onDestroyPais.next();
    this._onDestroyPais.complete();
    this._onDestroyProvincia.next();
    this._onDestroyProvincia.complete();
  }

  protected filterPaises() {
    if (!this.paisesList) {
      return;
    }
    let search = this.paisFilterCtrl.value;
    if (!search) {
      this.filteredPaises.next(this.paisesList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredPaises.next(
      this.paisesList.filter(pais => pais.description.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterProvincias() {
    if (!this.provincias) {
      return;
    }
    let search = this.provinciaFilterCtrl.value;
    if (!search) {
      this.filteredProvincias.next(this.provincias.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredProvincias.next(
      this.provincias.filter(prov => prov.nombre.toLowerCase().indexOf(search) > -1)
    );
  }

  async  onChangeDireccion() {
    if (this.direccion.provincia.id) {
      if (this.provincias == null){
        await this.getProvincias();
      }
      this.listaProv = this.provincias.filter(t => t.id === this.direccion.provincia.id);
      const provinciaa = Object.assign({}, this.listaProv[0]);
      this.direccion.provincia = provinciaa;
    } else {
    this.direccion.provincia.id = null;
  }
    this.changeDireccion.emit(); }

  async getTiposDomicilios() {
    await this.apiService.get<any>(OperationalParametric.path + 'tipos_domicilios/', null).toPromise().then(res => {
      this.tiposDomiciliosList = res.data;
    });
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      return c1.id === c2.id;
    } else {return false; }
  }

  getSelectedValue() {
    return this.selectedValue;
  }

  setSelectedValue($event: MatSelectChange) {
    this.selectedValue =  this.mappertTipoDomicilio($event.value);
  }

  mappertTipoDomicilio(id){
    if(id == 1){
      return 'Lugar de entrega';
    }
    if(id == 2){
      return 'Domicilio legal';
    }
    if(id == 3){
      return 'Domicilio real';
    }
    return '';
  }

  getIcon(id: number) {
    if(id == 1){
      return 'send';
    }
    if(id == 2){
      return 'business_center';
    }
    if(id == 3){
      return 'homel';
    }
    return '';
  }
  async getProvincias() {
    await this.apiService.get<DataObject<Provincia>>(Provincia.path + 'provincias/', null).toPromise().then(res => {
      this.provincias = res.data;
    });
  }

}
