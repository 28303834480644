export class EmailCliente {

    static Laboral;
    static Personal;
    static DescLaboral;
    static DescPersonal;

    constructor() {
      EmailCliente.Personal = 1;
      EmailCliente.Laboral = 2;
      EmailCliente.DescPersonal = 'Personal';
      EmailCliente.DescLaboral = 'Laboral';
    }

    get staticPersonal() {
      return EmailCliente.Personal;
    }
    get staticLaboral() {
      return EmailCliente.Laboral;
    }
    get staticDescLaboral() {
      return EmailCliente.DescLaboral;
    }
    get staticDescPersonal() {
      return EmailCliente.DescPersonal;
    }

}
