import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-empty-list-pattern',
  templateUrl: './empty-list-pattern.component.html',
  styleUrls: ['./empty-list-pattern.component.css']
})
export class EmptyListPatternComponent implements OnInit {
  @Input() show = false;
  @Input() icon = 'content_paste';
  @Input() message = 'Esta lista se encuentra vacía';
  @Input() messageIfFilters = 'No se encuentran elementos con los filtros aplicados';
  @Input() mainButtonLabel = 'Cargar lista';
  @Input() clearButtonLabel = 'Limpiar filtros';
  @Input() hideMainButton = false;
  @Input() hasActiveFilters = false;

  @Output() mainButtonClick = new EventEmitter<void>();
  @Output() clearFilters = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void { }

  onMainButtonClicked() { this.mainButtonClick.emit(); }

  onClearFilters() { this.clearFilters.emit(); }
}
