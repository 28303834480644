import { Component, OnInit } from '@angular/core';
import { Cotizacion } from '../../../models/cotizacion.model';
import { ActivatedRoute } from '../../../../../node_modules/@angular/router';

@Component({
  selector: 'app-cotizacion-edit',
  templateUrl: './cotizacion-edit.component.html',
  styleUrls: ['./cotizacion-edit.component.css']
})
export class CotizacionEditComponent implements OnInit {

  cotizacion: Cotizacion;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data.subscribe( data => {
      this.cotizacion = data.cotizacion;
    });
  }

}
