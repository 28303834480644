
export class Permission {

  static path = '/permisos/';

  id: number;
  name?: string;
  displayName?: string;
  description?: string;

}
