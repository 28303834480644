import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Cotizacion } from '../../models/cotizacion.model';
import { BackendService } from '../../core/backend.service';
import { SnackService } from '../../shared-services/snack.service';
import { DialogService } from '../modals/dialog.service';
import { Router } from '../../../../node_modules/@angular/router';
import { Configuracion } from '../../models/configuracion.model';

@Component({
  selector: 'app-cambio-dolar-venta',
  templateUrl: './cambio-dolar-venta.component.html',
  styleUrls: ['./cambio-dolar-venta.component.css']
})
export class CambioDolarVentaComponent implements OnInit {

  @Input() cotizacion: Cotizacion;
  @Output() onUpdateDolarVenta = new EventEmitter<boolean>();
  staticCambioDolarVenta = { valor: null };
  operationalCambioDolarVenta = { valor: null };

  staticDolaresVenta: Configuracion[];
  isLoading: boolean;
  fechaDesde: Date;
  fechaHasta: Date;
  currentDate = new Date();

  validDates = [];
  maximoHasta: string;

  myFilter = (d: Date): boolean => {
    return !this.validDates[d.toISOString()];
  }

  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    private dialogService: DialogService,
    private router: Router
  ) { }

  hasCambioDolarVenta() {
    if (this.staticCambioDolarVenta.valor !== null) {
      return true;
    }
    return false;
  }

  async confirmCambioDolarVenta() {
      this.dialogService.confirm('Modificar cotización dolar',
      '¿Está seguro que desea modificar la cotización actual de venta del dólar?')
      .then( res => {
        if (res) {
          this.save(res);
        }
      });
  }

  async save(res) {
    if (res) {
      this.snackService.spinner(true);
      try {
        if (!this.cotizacion) {
          await this.backendService.update('/configuraciones/cambio_dolar_venta?dolar='
                                                      + this.operationalCambioDolarVenta.valor, null);
          await     this.backendService.get('/configuraciones/dolar_venta').toPromise().then(
            data => {
              this.staticCambioDolarVenta.valor = data;
            }
          );
          this.onUpdateDolarVenta.emit(true);
        } else {
          this.cotizacion.valor = this.operationalCambioDolarVenta.valor;
          await this.backendService.update(Cotizacion.path, this.cotizacion);
          this.router.navigate(['/cotizacion']);
        }
        this.snackService.success('Cotización actualizada');
        this.snackService.spinner(false);
      } catch (error) {
        this.snackService.spinner(false);
        this.snackService.error(error.message);
      }
    }
  }

  async ngOnInit() {
    this.backendService.get('/configuraciones/dolar_venta').toPromise().then(
      res => {
        this.staticCambioDolarVenta.valor = res;
      }
    );
  }


}
