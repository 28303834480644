import { Injectable } from '../../../../node_modules/@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '../../../../node_modules/@angular/router';
import { SnackService } from '../../shared-services/snack.service';
import { TipoPersona } from '../../models/tipo-persona.model';
import { SecurityService } from '../../security/security.service';
import { BackendService } from '../../core/backend.service';

@Injectable()
export class TiposPersonasResolver implements Resolve<TipoPersona> {
  constructor(
    protected router: Router,
    protected snackService: SnackService,
    protected securityService: SecurityService,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id || 'agregar';

      if (id === 'agregar') {
        let tipoPersona = new TipoPersona();
        return tipoPersona;
      }
      return await this.backendService.get<TipoPersona>(TipoPersona.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/tipo-persona']);
    }
  }
}
