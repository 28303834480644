import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MenuActiveService } from 'src/app/shared-services/menu-active.service';

@Component({
  selector: 'app-empty-home',
  templateUrl: './empty-home.component.html',
  styleUrls: ['./empty-home.component.scss']
})
export class EmptyHomeComponent implements OnInit {

  @Output() changeUrl = new EventEmitter<string>();

  constructor(
    private router: Router,
    private menuActiveService: MenuActiveService
  ) { }

  ngOnInit() {
    this.menuActiveService.onGetSubject(this.router.url);
  }

}
