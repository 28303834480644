export class EstadoCliente {

    static PendComprobacion;
    static PendFinalizacion;
    static PendAprobacion;
    static Aprobado;
    static PendRevision;
    static Rechazado;

    constructor() {
      EstadoCliente.PendComprobacion = 1;
      EstadoCliente.PendFinalizacion = 2;
      EstadoCliente.PendAprobacion = 3;
      EstadoCliente.Aprobado = 4;
      EstadoCliente.PendRevision = 5;
      EstadoCliente.Rechazado = 6;
    }

    get staticPendComprobacion() {
      return EstadoCliente.PendComprobacion;
    }
    get staticPendFinalizacion() {
      return EstadoCliente.PendFinalizacion;
    }
    get staticPendAprobacion() {
      return EstadoCliente.PendAprobacion;
    }
    get staticAprobado() {
      return EstadoCliente.Aprobado;
    }
    get staticPendRevision() {
      return EstadoCliente.PendRevision;
    }
    get staticRechazado() {
      return EstadoCliente.Rechazado;
    }

}
