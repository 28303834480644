import { Cliente } from './cliente.model';
import { User } from './user.model';
import { TipoMoneda } from './tipoMoneda.model';
import { Sucursal } from './sucursal.model';

export class Comprobante {

    static path = '/comprobante/';

    id: number;
    entidad: Cliente;
    proveedor: Cliente;
    codigo: string;
    tipo: string;
    sucursal: Sucursal;
    numero: string;
    fechaCreacion: Date;
    montoParcial: number;
    montoTotal: number;
    tipoMoneda: TipoMoneda;
    usuarioInterno: User;
    observaciones: string;
    pagar = false;
    fechaVencimiento: Date;

    constructor() {
        this.entidad = new Cliente();
        this.proveedor = new Cliente();
        this.sucursal = new Sucursal();
        this.tipoMoneda = new TipoMoneda();
        this.usuarioInterno = new User();
    }
}
