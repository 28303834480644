import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Este servicio comunica si se abre o se cierra el sidenav a cualquier interesado
@Injectable()
export class SidenavComunicationService {
  constructor() { }

  private sidenavSource = new Subject<boolean>();
  onSidenavChange = this.sidenavSource.asObservable();

  private getSidenavStatusSource = new Subject<void>();
  onGetSidenavStatus = this.getSidenavStatusSource.asObservable();


  // True: sidenav Open
  // False: sidenav Closed
  sidenavChange(bool: boolean) {
    this.sidenavSource.next(bool);
  }

  getSidenavStatus() {
    this.getSidenavStatusSource.next();
  }
}
