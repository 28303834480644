export class Email {

  id: number;
  direccion: string;
  principal: boolean;
  tipoEmailId: number;
  icono: string;
  description: string;

  constructor(tipoEmailId: number, direccion: string, principal?: boolean) {
    this.direccion = direccion;
    this.principal = principal;
    this.tipoEmailId = tipoEmailId;
    this.icono = 'email';
  }
}
