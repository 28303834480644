import {Component, Input, OnInit} from '@angular/core';
import {Cliente} from '../../models/cliente.model';
import {PathSociales} from "../../models/path-sociales.interface";
import {UniqueIdentifierService} from "../../shared-services/unique-identifier.service";

@Component({
  selector: 'app-cliente-redes-sociales',
  templateUrl: './cliente-redes-sociales.component.html',
  styleUrls: ['./cliente-redes-sociales.component.scss']
})
export class ClienteRedesSocialesComponent implements OnInit {

  constructor(private uniqueService: UniqueIdentifierService) { }

  @Input() pathSociales: PathSociales;

  ngOnInit() {
  }

}
