import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from '../../../../node_modules/rxjs';
import { MediaService } from '../../shared-services/media.service';
import { trigger, state, style, transition, animate } from '../../../../node_modules/@angular/animations';

@Component({
  selector: 'app-generic-filters',
  templateUrl: './generic-filters.component.html',
  styleUrls: ['./generic-filters.component.css'],
  animations: [
    trigger('hideAndShow', [
      state('0', style({})),
      state('1', style({})),
      transition(':enter, 0 => 1', [
        style({transform: 'translateX(-100%)'}),
        animate(250)
      ]),
      transition(':leave, 1 => 0', [
        animate(250, style({transform: 'translateX(-100%)'}))
      ]),
    ]),
  ],
})
export class GenericFiltersComponent implements OnInit, OnDestroy {

  @Input() show: boolean;
  @Output() showChange = new EventEmitter<boolean>();
  subscription: Subscription;
  isMobile: boolean;

  constructor(private mediaService: MediaService) {}

  ngOnInit() {
    this.subscription = this.mediaService.isMobile.subscribe(isMobile => this.isMobile = isMobile);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close() {
    this.show = false;
    setTimeout(() => this.showChange.emit(false), 225);
  }

}
