import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ScrollComunicationService {
  constructor() { }

  private scrollSource = new Subject<any>();
  onScroll = this.scrollSource.asObservable();

  scroll(event: any) {
    this.scrollSource.next(event);
  }
}
