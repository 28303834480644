import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalImageComponent } from './modal-image/modal-image.component';
import { ModalLinkConfirmacionComponent } from './modal-link-confirmacion/modal-link-confirmacion.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) { }

  public confirm(title: string, firstMessage?: string, msgList?: string[]): Promise<boolean> {
    let dialogRef: MatDialogRef<ModalConfirmComponent>;

    dialogRef = this.dialog.open(ModalConfirmComponent, { data: {title, firstMessage, msgList}});

    return dialogRef.afterClosed().toPromise();
  }

  public imageViewer(title: string, firstMessage?: string, imgSrc?: any): Promise<boolean> {
    let dialogRef: MatDialogRef<ModalImageComponent>;

    dialogRef = this.dialog.open(ModalImageComponent, { data: {title, firstMessage, imgSrc }});

    return dialogRef.afterClosed().toPromise();
  }

  public getLinkReenvio(link: string): Promise<any> {
    let dialogRef: MatDialogRef<ModalLinkConfirmacionComponent>;

    dialogRef = this.dialog.open(ModalLinkConfirmacionComponent, { data: {link}});

    return dialogRef.afterClosed().toPromise();
  }

}
