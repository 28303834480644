import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { BackendService } from '../../../core/backend.service';
import { Cliente } from '../../../models/cliente.model';
import { DataObject } from '../../../models/dataObject.model';
import { Sucursal } from '../../../models/sucursal.model';
import { Comprobante } from '../../../models/comprobante.model';
import { Operacion } from '../../../models/operacion.model';
import { OperacionMedioPago } from '../../../models/operacion-medio-pago.model';
import { MedioPago } from '../../../models/medio-pago.model';
import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { SnackService } from '../../../shared-services/snack.service';
import { OperacionVista } from '../../../models/operacion-vista.model';
import { ComprobanteOperacion } from '../../../models/comprobante-operacion.model';
// @ts-ignore
import ApexCharts from 'apexcharts';
import { FormGroup, Validators, FormBuilder, FormControl } from '../../../../../node_modules/@angular/forms';
import { MAT_STEPPER_GLOBAL_OPTIONS } from '../../../../../node_modules/@angular/cdk/stepper';
import { Foto } from '../../../models/foto.model';
import { takeUntil, take } from '../../../../../node_modules/rxjs/operators';
import { ReplaySubject, Subject } from '../../../../../node_modules/rxjs';
import { MatSelect } from '../../../../../node_modules/@angular/material';

@Component({
  selector: 'app-cuenta-corriente-view',
  templateUrl: './cuenta-corriente-view.component.html',
  styleUrls: ['./cuenta-corriente-view.component.scss']
})
export class CuentaCorrienteViewComponent implements OnInit, AfterViewInit, OnDestroy  {

  entidad: Cliente;
  bancos: Cliente[];
  sucursales: Sucursal[];

  comprobantes: Comprobante[];

  operacion: Operacion = new Operacion();
  mediosPago: MedioPago[];
  operacionMediosPago: OperacionMedioPago[] = [];
  cantidadTotalPagar = 0;
  isLoading = false;
  chart1: ApexCharts;
  chart2: ApexCharts;
  mensajeInicial = 'Seleccione una sucursal para encontrar comprobantes.';

  operacionVista: OperacionVista = new OperacionVista();
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  sucursal: Sucursal;
  guardo = false;
  foto = false;
  deleteFotoPath = MedioPago.path + 'eliminar-foto';
  getFotoPath = MedioPago.path + 'imagen';

  comprobantesAPagar = [];

  selectedIndex: number = 0;

  public sucursalFilterCtrl: FormControl = new FormControl();
  public filteredSucursales: ReplaySubject<Sucursal[]> = new ReplaySubject<Sucursal[]>(1);
  @ViewChild('sucursales', { static: true }) sucursalSelect: MatSelect;
  protected _onDestroySucursal = new Subject<void>();

  public bancoFilterCtrl: FormControl = new FormControl();
  public filteredBancos: ReplaySubject<Cliente[]> = new ReplaySubject<Cliente[]>(1);
  protected _onDestroyBanco = new Subject<void>();

  public codigoFilterCtrl: FormControl = new FormControl();
  public filteredCodigos: ReplaySubject<MedioPago[]> = new ReplaySubject<MedioPago[]>(1);
  protected _onDestroyCodigo = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackService: SnackService,
    private backendService: BackendService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });

    this.sucursalFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroySucursal))
      .subscribe(() => {
        this.filterSucursales();
    });

    this.bancoFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyBanco))
      .subscribe(() => {
        this.filterBancos();
    });

    this.codigoFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyCodigo))
      .subscribe(() => {
        this.filterCodigos();
    });

    await this.route.data.subscribe(async data => {
      this.entidad = data.cliente;
      this.operacion.entidad = this.entidad;
      this.operacion.fechaCreacion = new Date();
      if (data.operacionVista) {
        this.operacionVista = data.operacionVista;
        this.operacion = this.operacionVista.operacion;
        this.operacionMediosPago = this.operacionVista.operacionMediosPago;
        if (this.operacion.sucursal.id) {
          await this.getComprobantes(this.operacion.sucursal.id);
          this.operacionVista.operacionComprobantes.forEach(c => {
            c.comprobante.pagar = true;
            c.comprobante.montoParcial = c.comprobante.montoParcial - c.montoPagado;
            this.comprobantes.push(c.comprobante);
          });
          this.calcularTotalAPagar();
        }
      }
    });
    if (this.operacionMediosPago.length === 0) {
      this.addMedioPago();
    }
    await this.getSucursales();
    await this.getBancos();
    await this.getMediosPago();
    if (!this.operacion.sucursal.id) {
      await this.getSucursalUsuario();
    }

    this.filteredSucursales.next(this.sucursales.slice());
    this.filteredBancos.next(this.bancos.slice());
    this.filteredCodigos.next(this.mediosPago.slice());

    //this.crearGrafico();
    //this.crearGrafico2();
  }

  async getSucursales() {
    await this.backendService.get<DataObject<Sucursal>>(Sucursal.path + 'all', null).toPromise().then(res => {
      this.sucursales = res.data;
    });
  }

  async getComprobantes(sucursalId) {
    this.isLoading = true;
    await this.backendService.get<any>
      (Comprobante.path + 'sucursal/' + sucursalId  + '/entidad/' + this.entidad.id).toPromise().then(res => {
        this.comprobantes = res as Comprobante[];
    });
    this.isLoading = false;
  }

  async getMediosPago() {
    await this.backendService.get<DataObject<MedioPago>>(MedioPago.path + 'all', null).toPromise().then(res => {
      this.mediosPago = res.data;
    });
  }

  async getBancos() {
    await this.backendService.get<any>(Cliente.path + 'all', null).toPromise().then(res => {
      this.bancos = res as Cliente[];
    });
  }

  async onChangeSucursal(event) {
    await this.getComprobantes(event.value.id);
  }

  addMedioPago() {
    this.operacionMediosPago.push(new OperacionMedioPago());
  }

  deleteMedioPago(index) {
    this.operacionMediosPago.splice(index, 1);
    if (this.operacionMediosPago.length > 0) {
      this.calcularTotalPagado();
    }
    if (this.operacionMediosPago.length === 0) {
      this.addMedioPago();
    }
  }

  calcularTotalPagado() {
    this.operacion.importe = this.operacionMediosPago.reduce((sum, omp) => sum + +omp.monto, 0);
  }

  calcularTotalAPagar() {
    this.cantidadTotalPagar = this.comprobantes.filter(x => x.pagar === true).reduce((sum, current) => sum + (current.montoTotal - current.montoParcial), 0);
  }

  async confirmSave() {
    this.guardo = true;
    this.dialogService.confirm('Confirmar operación',
    '¿Está seguro que desea confirmar la operación?')
    .then( res => {
      if (res) {
        this.saveOperacion(res);
      }
    });
  }

  async saveOperacion(res) {
    if (res) {
      this.snackService.spinner(true);
      this.operacionVista.operacion = this.operacion;
      this.operacionVista.operacionMediosPago = this.operacionMediosPago;
      const comprobantesPagados = this.comprobantes.filter(c => c.pagar === true);
      for(let i = 0; i < comprobantesPagados.length; i++ ) {
        let operacionComprobante = new ComprobanteOperacion();
        operacionComprobante.comprobante = comprobantesPagados[i];
        operacionComprobante.montoPagado = comprobantesPagados[i].montoTotal - comprobantesPagados[i].montoParcial;
        this.operacionVista.operacionComprobantes.push(operacionComprobante);
      }
      if (this.operacionVista.operacion.id) { // Update
        try {
          this.saveFotos(this.operacionMediosPago);
          await this.backendService.update(OperacionVista.path + this.operacionVista.operacion.id, this.operacionVista);
          this.snackService.success('Operación editada');
          this.router.navigate(['/operaciones/' + this.entidad.id]);
        } catch (ex) {
          if (ex[0]) {
            this.snackService.error(ex[0].details);
          } else if (ex.errors) {
            this.snackService.error(ex.errors[0].defaultMessage);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      } else {
        try {
          const response = await this.backendService.createWithResponse(OperacionVista.path, this.operacionVista) as any;
          this.saveFotos(response);
          this.snackService.success('Operación creada');
          this.router.navigate(['/operaciones/' + this.entidad.id]);
        } catch (ex) {
          if (ex[0]) {
            this.snackService.error(ex[0].details);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      }
    }
  }

  async getSucursalUsuario() {
    await this.backendService.get<any>(Sucursal.path + 'sucursal-usuario', null).toPromise().then(res => {
      this.sucursal = res as Sucursal;
    });
    if (this.sucursal.id) {
      this.operacion.sucursal = this.sucursal;
      await this.getComprobantes(this.operacion.sucursal.id);
    }
  }

  elegirMedioPago(index) {
    const detalle = this.mediosPago.filter(mp => mp.id === this.operacionMediosPago[index].medioPago.id)[0].detalle;
    this.operacionMediosPago[index].medioPago.detalle = detalle;
  }

  subioFoto() {
    this.foto = true;
  }

  async saveFotos(response) {
    try {
      for (let j = 0; j < response.length; j ++) {
        let formdata: FormData;
        const archivos = this.operacionMediosPago[j].imagenes;
        for (let i = 0; i < this.operacionMediosPago[j].imagenes.length; i++) {
          if (archivos[i].id === undefined && archivos[i].file !== null && archivos[i].file !== undefined) {
            formdata = new FormData();
            formdata.append('file', archivos[i].file);
            formdata.append('name', archivos[i].name);
            formdata.append('id', response[j].id.toString());
            await this.backendService.createFile(MedioPago.path + Foto.path, formdata);
          }
        }
      }
    } catch (ex) {
      this.snackService.error(ex.message);
    }
  }
  //[ngClass]="expanded() ? 'min-height-100':'min-height-inherit'"
  expanded() {
    document.getElementById('card3').classList.remove('min-height-inherit');
    document.getElementById('card3').classList.add('min-height-100');
  }

  closed() {
    document.getElementById('card3').classList.remove('min-height-100');
    document.getElementById('card3').classList.add('min-height-inherit');
  }


  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroySucursal.next();
    this._onDestroySucursal.complete();
    this._onDestroyBanco.next();
    this._onDestroyBanco.complete();
    this._onDestroyCodigo.next();
    this._onDestroyCodigo.complete();
  }

  protected setInitialValue() {
    this.filteredSucursales
      .pipe(take(1), takeUntil(this._onDestroySucursal))
      .subscribe(() => {
        this.sucursalSelect.compareWith = (a: Sucursal, b: Sucursal) => a && b && a.id === b.id;
    });
  }

  protected filterSucursales() {
    if (!this.sucursales) {
      return;
    }
    let search = this.sucursalFilterCtrl.value;
    if (!search) {
      this.filteredSucursales.next(this.sucursales.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredSucursales.next(
      this.sucursales.filter(suc => suc.description.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterBancos() {
    if (!this.bancos) {
      return;
    }
    let search = this.bancoFilterCtrl.value;
    if (!search) {
      this.filteredBancos.next(this.bancos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredBancos.next(
      this.bancos.filter(ban => ban.name.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterCodigos() {
    if (!this.mediosPago) {
      return;
    }
    let search = this.codigoFilterCtrl.value;
    if (!search) {
      this.filteredCodigos.next(this.mediosPago.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCodigos.next(
      this.mediosPago.filter(mp => mp.detalle.toLowerCase().indexOf(search) > -1)
    );
  }

  onStepChange(stepIndex) {
    this.comprobantesAPagar = this.comprobantes.filter(c => c.pagar === true);
    this.selectedIndex = stepIndex.selectedIndex;
  }

  /*async crearGrafico() {
    this.destroyCharts();
    this.chart1 = new ApexCharts(document.getElementById('grafico1'), this.getOptions());
    this.chart1.render();

  }

  getOptions() {
    const factorEscala = 0.6;
    return {
      chart: {
        type: 'donut'
      },
      series: [2000, 30000],
      tooltip: {
        y: {
          formatter: (value) => value,
        },
      },
      labels: [`ARS`, `USD`],
      legend: {
        floating: 'false',
        fontSize: '11px',
        horizontalAlign: 'center',
        markers: {
          width: 11,
          height: 11,
        }
      },
      responsive: [
        {
          breakpoint: 6000,
          options: {
            dataLabels: {
              enabled: false,
            },
            chart: {
              width: 400 * factorEscala,
              height: 350 * factorEscala
            },
            legend: {
              position: 'bottom'
            }
          },

        },
        {
          breakpoint: 1150,
          options: {
            dataLabels: {
              enabled: true,
            },
            chart: {
              width: 1.25 * 400 * factorEscala,
              height: 1.25 * 350 * factorEscala,
            },
            legend: {
              position: 'bottom',
            }
          },

        }]
    };
  }

  destroyCharts() {
    let domElement = document.getElementById('grafico1');
    this.deleteChildren(domElement);
  }

  deleteChildren(domElement) {
    let child = domElement.lastElementChild;
    while (child) {
      domElement.removeChild(child);
      child = domElement.lastElementChild;
    }
  }

  async crearGrafico2() {
    this.destroyCharts2();
    this.chart2 = new ApexCharts(document.getElementById('grafico2'), this.getOptions2());
    this.chart2.render();

  }

  getOptions2() {
    const factorEscala = 0.6;
    return {
      chart: {
        type: 'donut'
      },
      series: [5000, 2000],
      tooltip: {
        y: {
          formatter: (value) => value,
        },
      },
      labels: [`ARS`, `USD`],
      legend: {
        floating: 'false',
        fontSize: '11px',
        horizontalAlign: 'center',
        markers: {
          width: 11,
          height: 11,
        }
      },
      responsive: [
        {
          breakpoint: 6000,
          options: {
            dataLabels: {
              enabled: false,
            },
            chart: {
              width: 400 * factorEscala,
              height: 350 * factorEscala
            },
            legend: {
              position: 'bottom'
            }
          },

        },
        {
          breakpoint: 1150,
          options: {
            dataLabels: {
              enabled: true,
            },
            chart: {
              width: 1.25 * 400 * factorEscala,
              height: 1.25 * 350 * factorEscala,
            },
            legend: {
              position: 'bottom',
            }
          },

        }]
    };
  }

  destroyCharts2() {
    let domElement = document.getElementById('grafico2');
    this.deleteChildren(domElement);
  }*/
}
