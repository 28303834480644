import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from '../security.service';
import { FormValidatorService } from '../../shared-services/form-validator.service';
import { NgForm } from '@angular/forms';
import { SnackService } from '../../shared-services/snack.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('form', {static: false}) public userForm: NgForm;

  credentials = { email: '', password: '' };
  isLoading = false;
  redirectUrl = 'home';
  hide = false;

  constructor(
    private formValidatorService: FormValidatorService,
    private router: Router,
    private securityService: SecurityService,
    private snackService: SnackService,
  ) { }

  ngOnInit() {
  }

  async login() {
    if (this.validateForm()) {
      this.invokeLogin();
    }
  }

  validateForm() {
    return this.formValidatorService.isFormValid(this.userForm);
  }

  async invokeLogin() {
    try {
      this.isLoading = true;
      await this.securityService.login(this.credentials);
      await this.router.navigateByUrl(this.redirectUrl);
    } catch (error) {
      this.isLoading = false;
      this.snackService.error('Usuario o contraseña inválidos');
    }
  }

}
