import { Injectable } from '../../../../node_modules/@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '../../../../node_modules/@angular/router';
import { Talle } from '../../models/talle.model';
import { SnackService } from '../../shared-services/snack.service';
import { BackendService } from '../../core/backend.service';

@Injectable()
export class TalleResolver implements Resolve<Talle> {
  constructor(
    protected router: Router,
    protected snackService: SnackService,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id || 'agregar';

      if (id === 'agregar') {
        return new Talle();
      }
      return await this.backendService.get<Talle>(Talle.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/talles']);
    }
  }
}
