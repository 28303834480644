import { Component, Input, EventEmitter, OnChanges, Output } from '@angular/core';

export interface IFilterOption {
  value: any;
  displayText: string;
  class: string;
}

// TODO: Refactorizar haciendo obligatorio el uso del tagID? O crear otro modelo distinto para expansor-multiple
export interface ISelectedValue {
  value: any;
  categoryName: string;
  viewValue: any;
  tagID?: number;
}

@Component({
  selector: 'app-expansor-with-chip',
  templateUrl: './expansor-with-chip.component.html',
  styleUrls: ['./expansor-with-chip.component.scss']
})
export class ExpansorWithChipComponent implements OnChanges {

  @Input() expanded = false;
  @Input() hideDivider = false;
  @Input() filterValues: IFilterOption[] = [];
  @Input() placeHolderText = '';
  @Input() categoryName = '';
  @Output() filterChangeEvent = new EventEmitter<ISelectedValue>();

  @Input() set selectedValue(value: any) {
    if (value !== undefined) {
      // Es necesario un timeout de 0 para que se cierre el ciclo y el componente exista como view children en el padre
      setTimeout(() => this.onFilterChange(value), 0);
    }
  }

  showChip = false;
  selectedTextValue = '';

  constructor() {}

  ngOnChanges() {
    if (this.selectedValue !== undefined) {
      setTimeout(() => this.onFilterChange(this.selectedValue), 0);
    }
  }

  onFilterChange(selectedValue: any) {
    this.showChip = true;
    this.selectedTextValue =  this.filterValues.filter(x => x.value === selectedValue)[0].displayText;
    this.filterChangeEvent.emit({value: selectedValue, categoryName: this.categoryName, viewValue: this.selectedTextValue});
  }

  onRemoveChip() {
    this.showChip = false;
  }

}
