import { Component, OnInit, ViewChild } from '@angular/core';
import { BackendService } from '../../../core/backend.service';
import { SnackService } from '../../../shared-services/snack.service';
import { Router } from '@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { SecurityService } from '../../security.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { Observer, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GenericListComponent } from '../../../shared-components/generic-list/generic-list.component';
import { User } from '../../../models/user.model';
import { PaginatedList } from '../../../models/paginatedList.model';
import { share } from 'rxjs/operators';
import { Filters } from '../../../models/filters.model';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit {
  isLoading = false;
  roleFiltersActive = false;
  addUrl = '/usuarios/agregar';
  atributoBusqueda: string = 'username';

  public filters: Filters = {
    name: undefined,
    page: 0,
    size: 10,
  };
  usuariosLista = [];

  @ViewChild(GenericListComponent, {static: false}) genericList;

  addText = 'Agregar Usuario';
  searchTitle = 'Usuarios';
  searchPlaceHolder = 'Ingrese el nombre del usuario que desea buscar';
  messageEmpty = 'No se encuentran usuarios.';
  messageFilters = 'No se encuentran usuarios que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_USUARIO';

  userList = this.backendService.newSubjectWithoutDistinct<Filters>().pipe(
    switchMap(filters => {
      const promise = this.backendService.get<PaginatedList<User>>(User.path, filters).toPromise();
      return (async () => {
        this.isLoading = true;
        try {
          const permissions = await promise;
          this.usuariosLista = permissions.data;
          return permissions;
        } catch (error) {
          return [];
        } finally {
          this.isLoading = false;
          this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
        }
      })();
    }))
  .pipe(share()) as Observer<Filters> & Observable<User[]>;

  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    public securityService: SecurityService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
  }

  onChangePassword(user: User) {
    this.router.navigate(['/usuarios/cambiar_password', user.id]);
  }

  async onResetPassword(user: User) {

    this.dialogService.confirm('Reestablecer contraseña',
    '¿Está seguro que desea reestablecer la contraseña del Usuario ' + user.username + '?')
    .then( res => {
      if (res) {
        this.resetPassword(res, user);
      }
    });
  }

  onEditUser(user: User) {
    this.router.navigate(['/usuarios/editar', user.id]);
  }

  async onDeleteUser(user: User) {

    this.dialogService.confirm('Eliminar Usuario',
    '¿Está seguro que desea eliminar el Usuario ' + user.username + '?')
    .then( res => {
      if (res) {
        this.deleteUser(res, user);
      }
    });
  }

  async onHabilitar(user: User) {

    this.dialogService.confirm('Habilitar Usuario',
    '¿Está seguro que desea habilitar al Usuario ' + user.username + '?')
    .then( res => {
      if (res) {
        this.enableUser(res, user);
      }
    });
  }

  async enableUser(res, user: User) {

    if (res) {
      this.snackService.spinner(true);
      try {
        const ruta = User.path + 'habilitar/' + user.id;
        await this.backendService.enable(User.path + 'habilitar/' + user.id);
        this.snackService.success('Usuario habilitado correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.genericList.refreshRoleList();
      }
    }
  }

  async onInhabilitar(user: User) {

    this.dialogService.confirm('Inhabilitar Usuario',
    '¿Está seguro que desea inhabiliar al Usuario ' + user.username + '?')
    .then( res => {
      if (res) {
        this.disableUser(res, user);
      }
    });
  }

  async disableUser(res, user: User) {

    if (res) {
      this.snackService.spinner(true);
      try {
        await this.backendService.disable(User.path + 'inhabilitar/' + user.id);
        this.snackService.success('Usuario inhabilitado correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.genericList.refreshRoleList();
      }
    }
  }

  async deleteUser(res, user: User) {

    if (res) {
      this.snackService.spinner(true);
      try {
        await this.backendService.delete(User.path + user.id);
        this.snackService.success('Usuario eliminado correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.genericList.refreshRoleList();
      }
    }
  }

  async resetPassword(res, user: User) {

    if (res) {
      this.snackService.spinner(true);
      try {
        await this.backendService.update('/usuarios_internos/reset_password?id=' + user.id, User);
        this.snackService.success('Contraseña reestablecida correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.genericList.refreshRoleList();
      }
    }
  }

}
