import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ParametricWithIcon } from '../../models/parametricWithIcon.model';
import { TelefonoCliente } from '../../models/telefonoCliente.model';
import { EmailCliente } from '../../models/emailCliente.model';
import { ApiService } from '../../core/api.service';
import { DataObject } from '../../models/dataObject.model';
import { Telefono } from '../../models/telefono.model';
import { Email } from '../../models/email.model';
import {Cliente} from '../../models/cliente.model';
@Component({
  selector: 'app-cliente-datos-contacto',
  templateUrl: './cliente-datos-contacto.component.html',
  styleUrls: ['./cliente-datos-contacto.component.scss']
})
export class ClienteDatosContactoComponent implements OnInit {
  @Input() telefonos = [];
  @Input() emails = [];
  @Input() cliente: Cliente;
  @Input() submitted;

  public tiposTelefonos: ParametricWithIcon[];
  public tiposEmails: ParametricWithIcon[];
  public maxLengthTelefonos = 3;
  public maxLengthEmails = 3;
  private TelefonoCliente = new TelefonoCliente();
  private EmailCliente = new EmailCliente();

  @ViewChild('form', {static: false}) public clienteForm;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.getTiposTelefonos();
    this.getTiposEmails();
  }

  async getTiposTelefonos() {
    await this.apiService.get<DataObject<ParametricWithIcon>>(ParametricWithIcon.path + 'tipos_telefonos/', null).toPromise().then(res => {
      this.tiposTelefonos = res.data;
    });
    for (const tipoTelefono of this.tiposTelefonos) {
      switch (tipoTelefono.id) {
        case this.TelefonoCliente.staticParticular:
          tipoTelefono.icono = 'phone';
          break;
        case this.TelefonoCliente.staticCelular:
          tipoTelefono.icono = 'phone_android';
          break;
        case this.TelefonoCliente.staticLaboral:
          tipoTelefono.icono = 'phonelink_ring';
          break;
        default:
          tipoTelefono.icono = 'phone';
      }
    }
  }
  async getTiposEmails() {
    await this.apiService.get<DataObject<ParametricWithIcon>>(ParametricWithIcon.path + 'tipos_email/', null).toPromise().then(res => {
      this.tiposEmails = res.data;
    });
    for (const tipoEmail of this.tiposEmails) {
      switch (tipoEmail.id) {
        case this.EmailCliente.staticPersonal:
          tipoEmail.icono = 'contact_mail';
          break;
        case this.EmailCliente.staticLaboral:
          tipoEmail.icono = 'email';
          break;
        default:
          tipoEmail.icono = 'email';
      }
    }
  }

  agregarTelefono() {
    this.telefonos.push(new Telefono('', 1));
  }

  agregarEmail() {
    this.emails.push(new Email(1, '', false));
  }

  eliminarTelefono(index: number) {
    this.telefonos.splice(index, 1);
    if (this.telefonos.length === 0) {
      this.agregarTelefono();
    }
  }
  eliminarEmail(index: number) {
    this.emails.splice(index, 1);
    if (this.emails.length === 0) {
      this.agregarEmail();
    }
  }

  mapTelefonoIdToDescription(tipoTelefonoId: string) {
    if (tipoTelefonoId == '1'){
      return 'Particular';
    }
    if (tipoTelefonoId == '2'){
      return 'Celular';
    }
    if (tipoTelefonoId == '3'){
      return 'Laboral';
    }
    return 'Undefined';
  }

  mapEmailIdToDescription(tipoEmailId: string) {
    if (tipoEmailId == '1'){
      return 'Personal';
    }
    if (tipoEmailId == '2'){
      return 'Laboral';
    }
    return 'Undefined';
  }
}
