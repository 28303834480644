import { NgModule } from '../../../node_modules/@angular/core';
import { CommonModule } from '../../../node_modules/@angular/common';
import { HttpClientModule } from '../../../node_modules/@angular/common/http';
import { CoreModule } from '../core/core.module';
import { VendorModule } from '../core/vendor.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { SharedServicesModule } from '../shared-services/shared-services.module';
import { SecurityModule } from '../security/security.module';
import { ComprobantesComponent } from './comprobantes/comprobantes.component';
import { ComprobanteTableComponent } from './comprobantes/comprobante-table/comprobante-table.component';
import { ComprobanteAddEditComponent } from './comprobantes/comprobante-add-edit/comprobante-add-edit.component';
import { ComprobanteResolver } from './comprobantes/comprobantes.resolver';
import { MedioPagoAddEditComponent } from './medios-pago/medio-pago-add-edit/medio-pago-add-edit.component';
import { MedioPagoTableComponent } from './medios-pago/medio-pago-table/medio-pago-table.component';
import { MediosPagoComponent } from './medios-pago/medios-pago.component';
import { MediosPagoResolver } from './medios-pago/medios-pago.resolver';
import { OperacionTableComponent } from './operaciones/operacion-table/operacion-table.component';
import { OperacionAddEditComponent } from './operaciones/operacion-add-edit/operacion-add-edit.component';
import { OperacionesComponent } from './operaciones/operaciones.component';
import { OperacionResolver } from './operaciones/operaciones.resolver';
import { EntidadOperacionComponent } from './entidad-operacion/entidad-operacion.component';
import { EntidadOperacionTableComponent } from './entidad-operacion/entidad-operacion-table/entidad-operacion-table.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        CoreModule,
        VendorModule,
        SharedComponentsModule,
        SharedServicesModule,
        SecurityModule,
    ],
    declarations: [
        ComprobantesComponent,
        ComprobanteTableComponent,
        ComprobanteAddEditComponent,
        MediosPagoComponent,
        MedioPagoTableComponent,
        MedioPagoAddEditComponent,
        OperacionesComponent,
        OperacionTableComponent,
        OperacionAddEditComponent,
        EntidadOperacionComponent,
        EntidadOperacionTableComponent
    ],
    exports: [
        ComprobantesComponent,
        ComprobanteTableComponent,
        ComprobanteAddEditComponent,
        MediosPagoComponent,
        MedioPagoTableComponent,
        MedioPagoAddEditComponent,
        OperacionesComponent,
        OperacionTableComponent,
        OperacionAddEditComponent,
        EntidadOperacionComponent,
        EntidadOperacionTableComponent
    ],
    providers: [
        ComprobanteResolver,
        MediosPagoResolver,
        OperacionResolver
    ]
    })
export class CobranzasPagosModule { }
