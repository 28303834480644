import { Permission } from './permission.model';

export class Role {

  static path = '/roles/';

  id: number;
  name: string;
  displayName: string;
  description: string;
  permissions?: Permission[];

}
