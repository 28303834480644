import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '../../../../node_modules/@angular/router';
import { MenuActiveService } from '../../shared-services/menu-active.service';

@Component({
  selector: 'app-comprobantes',
  templateUrl: './comprobantes.component.html',
  styleUrls: ['./comprobantes.component.css']
})
export class ComprobantesComponent implements OnInit {

  @Output() changeUrl = new EventEmitter<string>();

  constructor(
    private router: Router,
    private menuActiveService: MenuActiveService
  ) { }

  ngOnInit() {
    this.menuActiveService.onGetSubject(this.router.url);
  }
}
