import { OperationalParametric } from './operationalParametric.model';

export class Documento {

  static path = 'subir_documentacion';
  file: File;
  tipoDocumentacion = new OperationalParametric();
  path: string;
  id: number;
  nombreArchivo: string;
  archivoId: number;

  constructor(file: File) {
    this.file = file;
  }
}
