import { Component, OnInit, ViewChild } from '@angular/core';
import { Filters } from '../../../models/filters.model';
import { GenericListComponent } from '../../../shared-components/generic-list/generic-list.component';
import { switchMap, share } from '../../../../../node_modules/rxjs/operators';
import { PaginatedList } from '../../../models/paginatedList.model';
import { Operacion } from '../../../models/operacion.model';
import { Observer, Observable } from '../../../../../node_modules/rxjs';
import { BackendService } from '../../../core/backend.service';
import { SnackService } from '../../../shared-services/snack.service';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { SecurityService } from '../../../security/security.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';

@Component({
  selector: 'app-operacion-table',
  templateUrl: './operacion-table.component.html',
  styleUrls: ['./operacion-table.component.css']
})
export class OperacionTableComponent implements OnInit {

  isLoading = false;
  roleFiltersActive = false;
  addUrl;
  entidadId;

  public filters: Filters = {
    name: undefined,
    page: 0,
    size: 10,
    sort: 'id,desc'
  };
  operacionesLista = [];

  @ViewChild(GenericListComponent, {static: false}) genericList;

  addText = 'Agregar operación';
  searchTitle = 'Operaciones';
  searchPlaceHolder = 'Ingrese el número de la operación que desea buscar';
  messageEmpty = 'No se encuentran operaciones.';
  messageFilters = 'No se encuentran operaciones que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_OPERACION';
  atributoBusqueda = 'id';

  operacionesList = this.backendService.newSubjectWithoutDistinct<Filters>().pipe(
    switchMap(filters => {
      this.activatedRoute.params.subscribe(params => {
        this.entidadId = params['id'];
      });
      if (filters.search) {
        filters.search = filters.search + ',entidad.id:' + this.entidadId;
      } else {
        filters.search = 'entidad.id:' + this.entidadId;
      }
      const promise = this.backendService.get<PaginatedList<Operacion>>(Operacion.path, filters).toPromise();
      return (async () => {
        this.isLoading = true;
        try {
          const permissions = await promise;
          this.operacionesLista = permissions.data;
          return permissions;
        } catch (error) {
          return [];
        } finally {
          this.isLoading = false;
          this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
        }
      })();
    }))
  .pipe(share()) as Observer<Filters> & Observable<Operacion[]>;

  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    public securityService: SecurityService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.addUrl = '/operaciones/' + params['id'] + '/agregar';
    });
  }

  onEditOperacion(operacion: Operacion) {
    //this.router.navigate(['/operaciones/' + this.entidadId + '/editar/', operacion.id]);
  }

}
