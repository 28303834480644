import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ENTER } from '../../../../../node_modules/@angular/cdk/keycodes';
import { NgForm, FormControl } from '../../../../../node_modules/@angular/forms';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { SnackService } from '../../../shared-services/snack.service';
import { BackendService } from '../../../core/backend.service';
import { FormValidatorService } from '../../../shared-services/form-validator.service';
import { MediaService } from '../../../shared-services/media.service';
import { NavigationService } from '../../../shared-services/navigation.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { OperacionVista } from '../../../models/operacion-vista.model';
import { Operacion } from '../../../models/operacion.model';
import { ComprobanteOperacion } from '../../../models/comprobante-operacion.model';
import { OperacionMedioPago } from '../../../models/operacion-medio-pago.model';
import { MedioPago } from '../../../models/medio-pago.model';
import { Cliente } from '../../../models/cliente.model';
import { Sucursal } from '../../../models/sucursal.model';
import { DataObject } from '../../../models/dataObject.model';

@Component({
  selector: 'app-operacion-add-edit',
  templateUrl: './operacion-add-edit.component.html',
  styleUrls: ['./operacion-add-edit.component.scss']
})
export class OperacionAddEditComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER];
  selected = new FormControl(0);
  mediosPago: MedioPago[];
  entidades: Cliente[];
  sucursales: Sucursal[];

  @ViewChild('form', {static: false}) public operacionForm: NgForm;

  toppings = new FormControl();
  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef;

  validationFail = false;
  operacionVista: OperacionVista;

  messageTitle: string;

  isHD = false;

  entidadId;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackService: SnackService,
    private backendService: BackendService,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private navigationService: NavigationService,
    private dialogService: DialogService,
  ) { }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.entidadId = params['id'];
    });

    this.route.data.subscribe(async data => {

      if (data.operacionVista) {
        this.operacionVista = data.operacionVista;
        this.operacionVista.operacion = this.operacionVista.operacion ? this.operacionVista.operacion : new Operacion();
        this.operacionVista.operacionComprobantes = this.operacionVista.operacionComprobantes ? this.operacionVista.operacionComprobantes : [];
        this.operacionVista.operacionMediosPago = this.operacionVista.operacionMediosPago ? this.operacionVista.operacionMediosPago : [];
      } else {
        this.operacionVista = new OperacionVista();
        let entity;
        await this.backendService.get<Cliente>(Cliente.path + this.entidadId).toPromise().then(res => {
          entity = res;
        });
        this.operacionVista.operacion.entidad = entity;
        console.log(this.operacionVista.operacion.entidad);
        this.operacionVista.operacionComprobantes.push(new ComprobanteOperacion());
        this.operacionVista.operacionMediosPago.push(new OperacionMedioPago());
      }

    });

    this.messageTitle = this.operacionVista.operacion.id ? 'Edición de la operación' : 'Alta de la operación';

    this.mediaService.isHighDefinition.subscribe(isHD => this.isHD = isHD);

    await this.getMediosPago();
    await this.getEntidades();
    await this.getSucursales();

  }

  back() {
    this.navigationService.back();
  }

  async save() {
    if (this.validateForm()) {
      this.invokeSave();
    }
  }

  validateForm() {
    let validationsOK = false;
    if (this.formValidatorService.isFormValid(this.operacionForm)) {
      validationsOK = true;
      this.validationFail = false;
    } else {
      validationsOK = false;
      this.validationFail = true;
    }

    return validationsOK;
  }

  private async invokeSave() {

    if (this.validateForm()) {
      this.ConfirmSave();
    }
    return;
  }

  async ConfirmSave() {
    this.dialogService.confirm(this.messageTitle,
    '¿Está seguro que desea guardar la operación ' + this.operacionVista.operacion.id + '?')
    .then( res => {
      if (res) {
        this.saveOperacion(res);
      }
    });
  }

  async saveOperacion(res) {
    if (res) {

      this.snackService.spinner(true);
      if (this.operacionVista.operacion.id) { // Update
        try {
          await await this.backendService.update(OperacionVista.path + this.operacionVista.operacion.id, this.operacionVista);
          this.snackService.success('Operación editada');
          this.router.navigate(['/operaciones']);
        } catch (ex) {
          if (ex[0]) {
            this.snackService.error(ex[0].details);
          } else if (ex.errors) {
            this.snackService.error(ex.errors[0].defaultMessage);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      } else {
        try {
          await this.backendService.create(OperacionVista.path, this.operacionVista);
          this.snackService.success('Operación creada');
          this.router.navigate(['/operaciones']);
        } catch (ex) {
          if (ex[0]) {
            this.snackService.error(ex[0].details);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      }
    }
  }

  async getMediosPago() {
    await this.backendService.get<any>(MedioPago.path, null).toPromise().then(res => {
      this.mediosPago = res.data;
    });
  }

  async getEntidades() {
    await this.backendService.get<any>(Cliente.path + 'all', null).toPromise().then(res => {
      this.entidades = res as Cliente[];
    });
  }

  async getSucursales() {
    await this.backendService.get<DataObject<Sucursal>>(Sucursal.path + 'all', null).toPromise().then(res => {
      this.sucursales = res.data;
    });
  }

  eliminarMedioPago(index: number) {
    this.operacionVista.operacionMediosPago.splice(index, 1);
    if (this.operacionVista.operacionMediosPago.length === 0) {
      this.agregarMedioPago();
    }
  }

  agregarMedioPago() {
    this.operacionVista.operacionMediosPago.push(new OperacionMedioPago());
  }

  cambioComprobante(event) {
    this.selected.setValue(event);
  }

  agregarComprobante() {
    this.operacionVista.operacionComprobantes.push(new ComprobanteOperacion());
    this.selected.setValue(this.operacionVista.operacionComprobantes.length - 1);
  }

  eliminarComprobante() {
    this.operacionVista.operacionComprobantes.splice(this.selected.value, 1);
  }


}
