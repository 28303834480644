import { TipoMoneda } from './tipoMoneda.model';

export class MontoCompra {

  static path = 'monto_compra/';

  id: number;
  monto: number;
  tipoMoneda: TipoMoneda = new TipoMoneda();

}
