import { Component, OnInit, ViewChild, Inject, Output, EventEmitter } from '@angular/core';
import { SearchInputComponent } from '../../shared-components/search-input/search-input.component';
import { Subject, Observer, Observable } from 'rxjs';
import { Permission } from '../../models/permission.model';
import { BackendService } from '../../core/backend.service';
import { MatPaginatorIntl } from '@angular/material';
import { UpdateEventService } from '../../shared-services/update-event.service';
import { switchMap, share } from 'rxjs/operators';
import { PaginatedList } from '../../models/paginatedList.model';
import { SecurityService } from '../security.service';
import { HasAction } from '../../shared-components/generic-table/generic-table.component';
import { Filters } from '../../models/filters.model';
import { EnvService } from '../../shared-services/env.service';
import { Router } from '@angular/router';
import { MenuActiveService } from 'src/app/shared-services/menu-active.service';

const DECIMAL_FORMAT: (v: any) => any = (v: number) => v.toFixed(2);

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent implements OnInit {


  private backendUrl: string;

  public filters: Filters = {
    name: undefined,
    page: 0,
    size: 10,
  };

  @ViewChild(SearchInputComponent, {static: false})
  private permissionSearchComponent: SearchInputComponent;

  public showFilters = true;
  public totalItems: number;

  permissionFiltersActive = false;
  mostrarResumen = false;
  isLoading = false;
  public hasActions: HasAction;

  addText = 'Agregar Permiso';
  addUrl = 'adasdadsadsa';
  searchTitle = 'Permisos';
  searchPlaceHolder = 'Ingrese el nombre del permiso que desea buscar';
  messageEmpty = 'No se encuentran permisos.';
  messageFilters = 'No se encuentran permisos que coincidan con el criterio de búsqueda actual.';
  botonAgregar = false;
  permisosList = [];
  @Output() changeUrl = new EventEmitter<string>();
  atributoBusqueda = 'name';

  columns = [
    { name: 'name',  label: 'Permiso' },
    { name: 'displayName', label: 'Nombre' },
    { name: 'description', label: 'Descripcion' }
  ];

  permissionList = this.backendService.newSubjectWithoutDistinct<Filters>().pipe(
  switchMap(filters => {
    const usersPromise = this.backendService.get<PaginatedList<Permission>>(Permission.path, filters).toPromise();
    return (async () => {
      this.isLoading = true;
      try {
        const permissions = await usersPromise;
        this.permisosList = permissions.data;
        return permissions;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
        this.permissionFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
      }
    })();
  })).pipe(share()) as Observer<Filters> & Observable<Permission[]>;

  constructor(
    protected env: EnvService,
    private backendService: BackendService,
    private paginatorsLabels: MatPaginatorIntl,
    protected updateEventService: UpdateEventService,
    public securityService: SecurityService,
    private router: Router,
    private menuActiveService: MenuActiveService
  ) {
    this.backendUrl = env.api + '/backend';
  }

  ngOnInit() {
    this.hasActions = new HasAction();

    this.hasActions.hasAction = false;
    this.hasActions.actions = [];
    this.paginatorsLabels.itemsPerPageLabel = 'Filas por página:';
    this.refreshPermissionList();
    this.menuActiveService.onGetSubject(this.router.url);
  }


  refreshPermissionList() {
    this.permissionList.next(this.getFilters());
  }

  getFilters() {
    return this.filters;
  }

}
