import { Injectable } from '@angular/core';
import { Cliente } from '../models/cliente.model';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BackendService } from '../core/backend.service';

@Injectable()
export class ClienteResolver implements Resolve<Cliente> {
  constructor(
    protected router: Router,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id || 'agregar';

      if (id === 'agregar') {
        const cliente = new Cliente();
        return cliente;
      }

      return await this.backendService.get<Cliente>(Cliente.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/clientes']);
    }
  }
}
