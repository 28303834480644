import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject } from '../../../../../node_modules/rxjs';
import { CategoriaProducto } from '../../../models/categoria-producto.model';
import { Sugerencia } from '../../../models/sugerencia.model';
import { ENTER } from '../../../../../node_modules/@angular/cdk/keycodes';
import { NgForm, FormControl } from '../../../../../node_modules/@angular/forms';
import { SubcategoriaProducto } from '../../../models/subcategoria-producto.model';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { SnackService } from '../../../shared-services/snack.service';
import { BackendService } from '../../../core/backend.service';
import { FormValidatorService } from '../../../shared-services/form-validator.service';
import { MediaService } from '../../../shared-services/media.service';
import { NavigationService } from '../../../shared-services/navigation.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { MatAutocompleteSelectedEvent, MatChipInputEvent } from '../../../../../node_modules/@angular/material';
import { map, takeUntil } from '../../../../../node_modules/rxjs/operators';
import { DataObject } from 'src/app/models/dataObject.model';

@Component({
  selector: 'app-subcategoria-producto-add-edit',
  templateUrl: './subcategoria-producto-add-edit.component.html',
  styleUrls: ['./subcategoria-producto-add-edit.component.css']
})
export class SubcategoriaProductoAddEditComponent implements OnInit, OnDestroy {

  categorias: CategoriaProducto[];
  sugerencias: Observable<Sugerencia[]>;
  _selectedCategorias: CategoriaProducto[] = [];
  separatorKeysCodes: number[] = [ENTER];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  @ViewChild('form', {static: false}) public roleForm: NgForm;
  toppings = new FormControl();
  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef;

  validationFail = false;
  item: SubcategoriaProducto;

  messageTitle: string;

  isHD = false;

  categoriaAgregar : CategoriaProducto;
  public categoriaFilterCtrl: FormControl = new FormControl();
  public filteredCategorias: ReplaySubject<CategoriaProducto[]> = new ReplaySubject<CategoriaProducto[]>(1);
  // tslint:disable-next-line:variable-name
  protected _onDestroyCategoria = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackService: SnackService,
    private backendService: BackendService,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private navigationService: NavigationService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {

    this.getCategorias();
    this.getSugerencias();
    this.route.data.subscribe(data => {
      this.item = data.user;
      this.item.description = this.item.id ? this.item.description : '';
      if (!this.item.categorias) {
        this.item.categorias = [];
      } else {
        this.categoriaAgregar = this.item.categorias[0];
      }
      if (!this.item.sugerencias) {
        this.item.sugerencias = [];
      }
    });

    this.messageTitle = this.item.id  ? 'Editar Subcategoría' : 'Agregar Subcategoría';

    this.mediaService.isHighDefinition.subscribe(isHD => this.isHD = isHD);

    this.categoriaFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroyCategoria))
    .subscribe(() => {
      this.filterCat(this.categorias, this.categoriaFilterCtrl, this.filteredCategorias, 'description');
  });
  }

  ngOnDestroy() {
    this._onDestroyCategoria.next();
    this._onDestroyCategoria.complete();
  }

  protected filterCat(lista, filterCtrl, filtered, position) {
    if (!lista) {
      return;
    }
    let search = filterCtrl.value;
    if (!search) {
      filtered.next(lista.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    filtered.next(
      lista.filter(value => value[position].toLowerCase().indexOf(search) > -1)
    );
  }

  async getCategorias() {
    await this.backendService.get<DataObject<CategoriaProducto>>(CategoriaProducto.path + 'all', null).toPromise().then(res => {
      this.categorias = res.data;
      this.filteredCategorias.next(this.categorias.slice());
    });
  }

  async getSugerencias() {
    this.sugerencias = this.backendService.get<any>(Sugerencia.path, {});
  }

  back() {
    this.navigationService.back();
  }

  // remove(categoria: CategoriaProducto): void {

  //   const index = this.item.categorias.indexOf(categoria);
  //   if (index >= 0) {
  //     this.item.categorias.splice(index, 1);
  //   }
  //   this.filter();
  // }

  // selected(event: MatAutocompleteSelectedEvent): void {

  //   const index = this.item.categorias.indexOf(event.option.value);

  //   if (index >= 0) {
  //     return;
  //   }
  //   this.item.categorias.push(event.option.value);
  //   this.fruitInput.nativeElement.value = '';
  //   this.toppings.setValue(null);
  //   // this.renderer.invokeElementMethod(this.fruitInput.nativeElement, 'blur', []);
  //   this.filter();
  // }

  onChangeCategoria() {
    this.item.categorias = [];
    this.item.categorias.push(this.categoriaAgregar);
  }

  // add(event: MatChipInputEvent): void {
  //   const input = event.input;
  //   const value = event.value;
  // }

  // public categoriaSelected(cate: CategoriaProducto): boolean {
  //   for (const sugCate of this.item.categorias) {
  //     if (cate.id === sugCate.id) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  // private filter() {
  //   this.categorias
  //   .pipe(map(r => r.filter(p => this._selectedCategorias.indexOf(p) === 0)));
  // }
  async save() {
    if (this.validateForm()) {
      this.ConfirmSave();
    }
  }

  validateForm() {
    let validationsOK = false;
    // Valida el resto de los controles con sus validadores
    if (this.formValidatorService.isFormValid(this.roleForm)) {
      validationsOK = true;
      this.validationFail = false;
    } else {
      validationsOK = false;
      this.validationFail = true;
    }

    return validationsOK;
  }

  async ConfirmSave() {
    this.dialogService.confirm(this.messageTitle,
    '¿Está seguro que desea guardar la subcategoría ' + this.item.description + '?')
    .then( res => {
      if (res) {
        this.saveItem(res);
      }
    });
  }

  async saveItem(res) {
    if (res) {

      this.snackService.spinner(true);

      if (this.item.id) { // Update
        try {
          await await this.backendService.update(SubcategoriaProducto.path + this.item.id, this.item);
          this.snackService.success('Subcategoría editada');
          this.router.navigate(['/subcategorias']);
        } catch (ex) {
          if (ex.error.details) {
            this.snackService.error(ex.error.details);
          } else if (ex.error.message) {
            this.snackService.error(ex.error.message);
          } else if (ex.error[0].details) {
            this.snackService.error(ex.error[0].details);
          } else if (ex.error[0].message) {
            this.snackService.error(ex.error[0].message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      } else {
        try {
          await this.backendService.create(SubcategoriaProducto.path, this.item);
          this.snackService.success('Subcategoria creada');
          this.router.navigate(['/subcategorias']);
        } catch (ex) {
          if (ex.error.details) {
            this.snackService.error(ex.error.details);
          } else if (ex.error.message) {
            this.snackService.error(ex.error.message);
          } else if (ex.error[0].details) {
            this.snackService.error(ex.error[0].details);
          } else if (ex.error[0].message) {
            this.snackService.error(ex.error[0].message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      }
    }
  }

}
