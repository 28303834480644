import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Inject, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { NgForm, FormControl, FormGroup } from '@angular/forms';
import { Cliente } from '../../models/cliente.model';
import { EstadoCliente } from '../../models/estadoCliente.model';
import { PaisCliente } from '../../models/paisCliente.model';
import { TelefonoCliente } from '../../models/telefonoCliente.model';
import { EmailCliente } from '../../models/emailCliente.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../shared-services/navigation.service';
import { SnackService } from '../../shared-services/snack.service';
import { BackendService } from '../../core/backend.service';
import { DialogService } from '../../shared-components/modals/dialog.service';
import { FormValidatorService } from '../../shared-services/form-validator.service';
import { OperationalParametric } from '../../models/operationalParametric.model';
import { MontoCompra } from '../../models/montoCompra.model';
import { Direccion } from '../../models/direccion.model';
import { Departamento } from '../../models/departamento.model';
import { Provincia } from '../../models/provincia.model';
import { Telefono } from '../../models/telefono.model';
import { Email } from '../../models/email.model';
import { Documentacion } from '../../models/documentacion.model';
import { DataObject } from '../../models/dataObject.model';
import { Documento } from '../../models/documento.model';
import { Logo } from '../../models/logo.model';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../core/api.service';
import {} from 'googlemaps';
import { TipoServicio } from '../../models/tipo-servicio.model';
import { TipoPersona } from '../../models/tipo-persona.model';
import {Contacto} from '../../models/contacto.model';
import {CuentaBancaria} from '../../models/cuenta-bancaria.model';
import { RetencionGanancia } from '../../models/retencion-ganancia.model';
import {EscalaRetencionGanancia} from "../../models/escala-retencion-ganancia.model";

@Component({
  selector: 'app-cliente-add-edit',
  templateUrl: './cliente-add-edit.component.html',
  styleUrls: ['./cliente-add-edit.component.scss']
})
export class ClienteAddEditComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('clienteForm', {static: false}) public clienteForm: NgForm;

  submitted = false;
  agregaContacto = false;
  valido = true;
  submittedContact = false;
  datosComp = false;
  emailError = false;

  exp = new RegExp('[0-9]{8,14}');

  /*map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';*/
  latCap = -34.599722222222;
  lngCap = -58.431944444444;
  private hash: string;
  cliente: Cliente;
  private documentacionRespaldatoria: Documentacion[];
  validationFail = false;
  emailPrincipalFail = false;
  private montoCompraFail = false;
  private montoCompraTipoMonedaFail = false;
  EstadoCliente = new EstadoCliente();
  PaisCliente = new PaisCliente();
  private TelefonoCliente = new TelefonoCliente();
  private EmailCliente = new EmailCliente();
  selected = new FormControl(0);
  selectedCuenta = new FormControl(0);
  selectedContacto = new FormControl(0);
  provincias: Provincia[];
  paisesList: OperationalParametric[];
  // @ViewChild('map', {static: false}) mapElement: any;
  map: google.maps.Map;


  textmarker;

  infowindow = new google.maps.InfoWindow({
    content: 'Error al ubicar la dirección ingresada'
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private snackService: SnackService,
    private backendService: BackendService,
    private dialogService: DialogService,
    private formValidatorService: FormValidatorService,
    protected http: HttpClient,
    private apiService: ApiService,
    private changeDetector: ChangeDetectorRef
  ) { }

  async ngOnInit() {

    this.route.data.subscribe(data => {
      this.cliente = data.cliente;
      this.cliente.nacionalidad = this.cliente.nacionalidad ? this.cliente.nacionalidad : new OperationalParametric();
      this.cliente.emisorDocumento = this.cliente.emisorDocumento ? this.cliente.emisorDocumento : new OperationalParametric();
      this.cliente.tipoDocumento = this.cliente.tipoDocumento ? this.cliente.tipoDocumento : new OperationalParametric();
      this.cliente.situacionIva = this.cliente.situacionIva ? this.cliente.situacionIva : new OperationalParametric();
      this.cliente.montoCompraActual = this.cliente.montoCompraActual ? this.cliente.montoCompraActual : new MontoCompra();
      this.cliente.documentacionPendiente = this.cliente.documentacionPendiente ? this.cliente.documentacionPendiente : false;
      this.cliente.tipoServicio = this.cliente.tipoServicio ? this.cliente.tipoServicio : new TipoServicio();
      this.cliente.tipoPersona = this.cliente.tipoPersona ? this.cliente.tipoPersona : new TipoPersona();
      this.cliente.transporte = this.cliente.transporte ? this.cliente.transporte : new Cliente();
      this.cliente.nroIngresosBrutos = this.cliente.nroIngresosBrutos ? this.cliente.nroIngresosBrutos : undefined;
      this.cliente.retencionIngresosBrutos = this.cliente.retencionIngresosBrutos ? this.cliente.retencionIngresosBrutos : undefined;
      this.cliente.percepcionIngresosBrutos = this.cliente.percepcionIngresosBrutos ? this.cliente.percepcionIngresosBrutos : undefined;
      this.cliente.retencionGanancia = this.cliente.retencionGanancia ? this.cliente.retencionGanancia : new RetencionGanancia();
      this.cliente.validaPedido = this.cliente.validaPedido ? this.cliente.validaPedido : false;
      this.cliente.escalaRetencionGanancia = this.cliente.escalaRetencionGanancia ? this.cliente.escalaRetencionGanancia : new EscalaRetencionGanancia();
      this.cliente.inscriptoGanancias = this.cliente.inscriptoGanancias ? this.cliente.inscriptoGanancias : false;

      this.cliente.personaPoliticamenteExpuesta =
        this.cliente.personaPoliticamenteExpuesta ? this.cliente.personaPoliticamenteExpuesta : false;
      if (!this.cliente.genero) {
        this.cliente.genero = this.cliente.genero ? this.cliente.genero : new OperationalParametric();
        this.cliente.genero.id = 1;
      }
      if (!this.cliente.estado) {
        this.cliente.estado = this.cliente.estado ? this.cliente.estado : new OperationalParametric();
        this.cliente.estado.id = this.EstadoCliente.staticPendAprobacion;
      }
      if (!this.cliente.direcciones || this.cliente.direcciones.length === 0) {
        this.cliente.direcciones = [];
        this.cliente.direcciones.push(new Direccion());
      } else {
        if (!this.cliente.direcciones[0].departamentoProvincia) {
          this.cliente.direcciones[0].departamentoProvincia = new Departamento(1);
        }
        if (!this.cliente.direcciones[0].provincia) {
          this.cliente.direcciones[0].provincia = new Provincia(1);
        }
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.cliente.direcciones.length; i++) {
          this.cliente.direcciones[i].markers = [];
          this.createMarker();
          if (!this.cliente.direcciones[i].tipoDomicilio) {
            this.cliente.direcciones[i].tipoDomicilio = new OperationalParametric();
          }
        }
      }
      if (!this.cliente.telefonos || this.cliente.telefonos.length === 0) {
        this.cliente.telefonos = [];
        this.cliente.telefonos.push(new Telefono('', 1));
      } else {
        for (const telefono of this.cliente.telefonos) {
          switch (telefono.tipoTelefonoId) {
            case this.TelefonoCliente.staticParticular:
              telefono.icono = 'phone';
              break;
            case this.TelefonoCliente.staticCelular:
              telefono.icono = 'phone_android';
              break;
            case this.TelefonoCliente.staticLaboral:
              telefono.icono = 'phonelink_ring';
              break;
            default:
              telefono.icono = 'phone';
          }
        }
      }
      if (!this.cliente.emails || this.cliente.emails.length === 0) {
        this.cliente.emails = [];
        this.cliente.emails.push(new Email(1, '', false));
      } else {
        for (const email of this.cliente.emails) {
          switch (email.tipoEmailId) {
            case this.EmailCliente.staticPersonal:
              email.icono = 'contact_mail';
              break;
            case this.EmailCliente.staticLaboral:
              email.icono = 'email';
              break;
            default:
              email.icono = 'email';
          }
        }
      }

      if (!this.cliente.documentacionRespaldatoria) {
        this.cliente.documentacionRespaldatoria = [];
      }
    });
    this.getDocumentacionById();
    await this.getPaises();
    this.getProvincias();
  }

  agregarDireccion() {
    this.cliente.direcciones.push(new Direccion());
    this.selected.setValue(this.cliente.direcciones.length - 1);
  }

  eliminarDireccion() {
    this.cliente.direcciones.splice(this.selected.value, 1);
  }

  async getDocumentacionById() {
    await this.backendService.get<any>(Cliente.path + Documentacion.path, null).toPromise().then(res => {
      this.documentacionRespaldatoria = res;
    });
    this.createDocumentosIfNotExists();
  }

  createDocumentosIfNotExists() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.documentacionRespaldatoria.length; i ++) {
      if (this.documentacionRespaldatoria[i].documentos.length === 0) {
        this.documentacionRespaldatoria[i].documentos.push(new Documento(undefined));
      }
    }
  }

  back() {
    this.navigationService.back();
  }

  async confirmSave() {
    if (this.validateForm()) {
      this.dialogService.confirm(this.cliente.id ? 'Edición de cliente' : 'Alta de cliente',
      '¿Está seguro que desea guardar el cliente ' + this.cliente.name + ' ' + (this.cliente.lastname ? this.cliente.lastname : '') + '?')
      .then( res => {
        if (res) {
          this.saveCliente(res);
        }
      });
    }
  }

  validateForm() {
    let validationsOK = true;
    this.submitted = true;
    if (this.agregaContacto) {
      this.submittedContact = true;
      if (!this.validateContacts()) {
        validationsOK = false;
        this.validationFail = true;
        this.snackService.error('El contacto de la empresa debe tener un mail o teléfono');
        return validationsOK;
      } else if (!this.validarValidezContacto(this.cliente.contactos)) {
        validationsOK = false;
        return validationsOK;
      }
    }
    // Valida el resto de los controles con sus validadores
    if (!this.cliente.name) {
      validationsOK = false;
      this.validationFail = true;
    } else if (!this.cliente.cuitCuil) {
      validationsOK = false;
      this.validationFail = true;
    } else if (!this.cliente.situacionIva.id) {
      validationsOK = false;
      this.validationFail = true;
    } else if (!this.cliente.tipoPersona.id) {
      validationsOK = false;
      this.validationFail = true;
    } else if (!this.cliente.tipoServicio.id) {
      validationsOK = false;
      this.validationFail = true;
    } else if (this.cliente.direcciones.length === 0) {
      validationsOK = false;
      this.validationFail = true;
    } else if ( this.cliente.descuento && this.cliente.descuento.toString() !== '' && !this.esDecuentoValido()) {
        validationsOK = false;
        this.validationFail = true;
        this.snackService.error('El descuento debe ser un número entre 0 y 100 [%].');
    } else if (this.datosComportamiento()) {
        validationsOK = false;
        this.validationFail = true;
        this.snackService.error('Debe completar todos los campos en Datos de Comportamiento.');
    } else if (!this.validarDatosBancarios(this.cliente.cuentasBancarias)) {
      validationsOK = false;
    } else if (!this.checkIfArrayIsUnique(this.cliente.emails.map(email => email.direccion))) {
      validationsOK = false;
      this.validationFail = true;
      this.emailError = true;
      this.snackService.error('Los emails deben ser distintos.');
    } else {

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.cliente.direcciones.length; i ++) {
        if (!this.cliente.direcciones[i].pais.id || !this.cliente.direcciones[i].codigoPostal
          || !this.cliente.direcciones[i].ciudad || !this.cliente.direcciones[i].direccion
          || !this.cliente.direcciones[i].numero) {
          validationsOK = false;
          this.validationFail = true;
          this.snackService.error('Debe ingresar los datos obligatorios de las direcciones');
        } else if (this.cliente.direcciones[i].pais.id === 55 && !this.cliente.direcciones[i].provincia.id) {
          validationsOK = false;
          this.validationFail = true;
          this.snackService.error('Debe ingresar los datos obligatorios de las direcciones');
        }
      }
      if (!this.validationFail) {
        validationsOK = true;
        this.validationFail = false;
      }
    }

    if (this.checkEmailPrincipal()) {
      validationsOK = validationsOK && true;
    } else {
      this.emailPrincipalFail = true;
      validationsOK = false;
    }

    if (!validationsOK && !this.emailError) {
      this.snackService.error('Hay datos inválidos. Por favor revise los campos marcados en rojo.');
    }
    return validationsOK;
  }

  validateContacts() {
    if (this.cliente.contactos.length !== 0){
      return this.cliente.contactos[0].emails.some(mail => (mail.direccion.match(this.getRegexMails()))) ||
        this.cliente.contactos[0].telefonos.some(telefono => (telefono.numero.match(this.exp)));
    }
    return true;

  }

  validarTelefonos() {
    return this.cliente.telefonos.every(telefono => (telefono.numero.match(this.exp)));
  }

  validarMails() {
    return this.cliente.emails.every(mail => (mail.direccion.match(this.getRegexMails())));
  }

  private esDecuentoValido() {
    return this.cliente.descuento >= 0 && this.cliente.descuento <= 100;
  }

  private validarValidezContacto(contactos: Contacto[]) {
    for (const contacto of contactos) {
      if ((contacto.nombre === '' || contacto.nombre == null || contacto.nombre === undefined)
        && (contacto.apellido === '' || contacto.apellido == null || contacto.apellido === undefined) ) {
        this.validationFail = true;
        this.snackService.error('Debe ingresar el nombre o apellido del contacto');
        return false;
      }
      if (!this.checkIfArrayIsUnique(contacto.emails.map(email => email.direccion))) {
        this.validationFail = true;
        this.snackService.error('Debe ingresar correos distintos');
        return false;
      }
    }
    return true;
  }

  private validarDatosBancarios(cuentasBancarias: CuentaBancaria[]) {
    for (const cuentaBancaria of cuentasBancarias){
      if (cuentaBancaria.nombre === '' || cuentaBancaria.nombre == null || cuentaBancaria.nombre === undefined) {
        this.validationFail = true;
        this.snackService.error('Debe ingresar el nombre del banco');
        return false;
      }
      if (cuentaBancaria.cbu === '' || cuentaBancaria.cbu == null || cuentaBancaria.cbu === undefined) {
        this.validationFail = true;
        this.snackService.error('Debe ingresar el cbu');
        return false;
      }
      if (cuentaBancaria.alias === '' || cuentaBancaria.alias == null || cuentaBancaria.alias === undefined) {
        this.validationFail = true;
        this.snackService.error('Debe ingresar el alias del cbu');
        return false;
      }
    }
    return true;
  }

  datosComportamiento() {
    let limite = false;
    let transporte = false;
    let descuento = false;
    if (this.cliente.limiteCredito || this.cliente.transporte.id || this.cliente.descuento) {
      if (this.cliente.limiteCredito) {
        limite = this.cliente.limiteCredito.toString() === '';
      }
      if (this.cliente.transporte.id) {
        transporte = this.cliente.transporte.id.toString() === '';
      }
      if (this.cliente.descuento) {
        descuento = this.cliente.descuento.toString() === '';
      } else if (!limite || !transporte || !descuento) {
        this.datosComp = true;
        return this.datosComp;
      }
    }
    this.datosComp = limite || transporte || descuento;
    return this.datosComp;
  }

  private isValidRetencionIIBB(retencionIIBB: number) {
      if (!retencionIIBB){
        this.validationFail = true;
        this.snackService.error('Debe ingresar Retención de Ingresos Brutos IIBB(%)');
        return false;
      }
      if(!this.isValidPorcentaje2Decimales(retencionIIBB)){
        this.validationFail = true;
        this.snackService.error('El Porecentaje de Retención IIBB(%) debe tener a lo sumo 2 decimales y tener un formato válido.');
        return false;
      }
      if(!this.isValidPorcentaje0to100(retencionIIBB)){
        this.validationFail = true;
        this.snackService.error('El Porecentaje de Retención IIBB(%) debe ser un numero entre 0 y 100.');
        return false;
      }
      return true;
  }

  private isValidPercepcionIIBB(percepcionIIBB: number) {
    if (!percepcionIIBB){
      this.validationFail = true;
      this.snackService.error('Debe ingresar Percepción de Ingresos Brutos IIBB(%)');
      return false;
    }
    if(!this.isValidPorcentaje2Decimales(percepcionIIBB)){
      this.validationFail = true;
      this.snackService.error('El Porecentaje de Percepción IIBB(%) debe tener a lo sumo 2 decimales y tener un formato válido.');
      return false;
    }
    if(!this.isValidPorcentaje0to100(percepcionIIBB)){
      this.validationFail = true;
      this.snackService.error('El Porecentaje de Percepción IIBB(%) debe ser un numero entre 0 y 100.');
      return false;
    }
    return true;
  }

  private isValidPorcentaje0to100(porcentaje: number) {
    return porcentaje >= 0 && porcentaje <= 100;
  }

  private isValidPorcentaje2Decimales(porcentaje: number) {
    const porcentajeStr = porcentaje.toString();
    return porcentajeStr.match('^\\d+(\\.\\d{1,2})?$');
  }

  private isValidMail(mail: string) {
    return mail.match(this.getRegexMails()) != null;
  }

  checkEmailPrincipal() {
    for (const email of this.cliente.emails) {
      if (email.principal) {
        return true;
      }
    }
    return false;
  }

  async saveCliente(res) {
    if (res) {
      this.snackService.spinner(true);
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.cliente.direcciones.length; i++) {
        this.cliente.direcciones[i].markers = undefined;
      }
      this.cliente.cuitCuil = this.cliente.cuitCuil.split('-').join('');

      if (this.cliente.id) { // Update
        try {
          await this.backendService.update(Cliente.path + this.cliente.id, this.cliente);
          this.saveLogos(this.cliente.id);
          this.snackService.success('Cliente editado satisfactoriamente');
          this.router.navigate(['/clientes/detalles/' + this.cliente.id]);
        } catch (ex) {
          if (ex[0]) {
            this.snackService.error(ex[0].details);
          } else if (ex.errors) {
            this.snackService.error(ex.errors[0].defaultMessage);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      } else {
        try {
          const response = await this.backendService.createWithResponse(Cliente.path, this.cliente) as any;
          this.saveLogos(response.id);
          this.router.navigate(['/clientes']);
          this.snackService.success('Cliente creado satisfactoriamente');
        } catch (ex) {
          if (ex[0]) {
            this.snackService.error(ex[0].details);
          } else if (ex.error.errors) {
            this.snackService.error(ex.error.errors[0].defaultMessage);
          } else if (ex.error.message) {
            this.snackService.error(ex.error.message);
          } else if (ex.error[0]) {
            this.snackService.error(ex.error[0].details);
          } else {
            this.snackService.error('Error');
          }
        } finally {
          this.snackService.spinner(false);
        }
      }
    }
  }
  async saveLogos(clienteId) {
    try {
      let formdata: FormData;
      const archivos = this.cliente.logos;
      for (let i = 0; i < this.cliente.logos.length; i++) {
        if (archivos[i].id === undefined && archivos[i].file !== null && archivos[i].file !== undefined) {
          formdata = new FormData();
          formdata.append('file', archivos[i].file);
          formdata.append('name', archivos[i].name);
          formdata.append('id', clienteId);
          await this.backendService.createFile(Cliente.path + Logo.path, formdata);
        }
      }
    } catch (ex) {
      this.snackService.error(ex.message);
    }
  }
  ngAfterViewInit() {

    this.crearMapa();

    this.textmarker = new google.maps.Marker({
      position: this.map.getCenter(),
      map: this.map,
      visible: false
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  async getProvincias() {
    await this.apiService.get<DataObject<Provincia>>(Provincia.path + 'provincias/', null).toPromise().then(res => {
      this.provincias = res.data;
    });
  }

  async getPaises() {
    this.apiService.get<any>(OperationalParametric.path + 'paises/', null).toPromise().then(res => {
      this.paisesList = res.data;
    });
  }

  cambioDireccion(event) {
    this.selected.setValue(event);
    this.crearMapa();
    this.textmarker = new google.maps.Marker({
      position: this.map.getCenter(),
      map: this.map,
      visible: false
    });
  }

  cambioContacto(event) {
    this.selectedContacto.setValue(event);
  }
  cambioCuentaBancaria(event) {
    this.selectedCuenta.setValue(event);
  }

  crearMapa() {
    const mapProperties = {
      center: new google.maps.LatLng(this.latCap, this.lngCap),
      zoom: 2,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(document.getElementById('map-' + this.selected.value),    mapProperties);

    this.map.addListener('click', async (event) => {
      this.deleteAllMarkers();
      this.quitarEstilo();
      let response = null;
      await this.http.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},
          ${event.latLng.lng()}&key=AIzaSyC8eisRn7mMqWtC-sdMQ37EVWpS_Q-0-vo`).toPromise().then(res => {
            // console.log(res);
            response = res;
      });

      const addressList = response.results[0].address_components;

      // Codigo postal
      const codigoPostal = addressList.find(c => {
        return (c.types.find(p => {
          return p === 'postal_code';
        })) !== undefined;
      });
      this.cliente.direcciones[this.selected.value].codigoPostal = codigoPostal ? codigoPostal.long_name : '';

      // Altura
      const altura = addressList.find(c => {
        return (c.types.find(p => {
          return p === 'street_number';
        })) !== undefined;
      });
      this.cliente.direcciones[this.selected.value].numero = altura ? altura.long_name : '';

      // Calle
      const calle = addressList.find(c => {
        return (c.types.find(p => {
          return p === 'route';
        })) !== undefined;
      });
      this.cliente.direcciones[this.selected.value].direccion = calle ? calle.long_name : '';

      // Pais
      const pais = addressList.find(c => {
        return (c.types.find(p => {
          return p === 'country';
        })) !== undefined;
      });
      this.cliente.direcciones[this.selected.value].pais = this.paisesList.find(p => p.description === pais.long_name.toUpperCase());

      // Provincia
      if (this.cliente.direcciones[this.selected.value].pais.id === 55) {
        const provincia = addressList.find(c => {
          return (c.types.find(p => {
            return p === 'administrative_area_level_1';
          })) !== undefined;
        });
        if (provincia.short_name === 'CABA') {
          this.cliente.direcciones[this.selected.value].provincia = this.provincias.find(p => p.codigoAfip === '0');
        } else {
          this.cliente.direcciones[this.selected.value].provincia = this.provincias.find(p => p.nombre === provincia.long_name);
        }
      }
      // ciudad
      if (this.cliente.direcciones[this.selected.value].pais.id === 55 &&
        this.cliente.direcciones[this.selected.value].provincia.id === 1) {
        this.cliente.direcciones[this.selected.value].ciudad = this.cliente.direcciones[this.selected.value].provincia.nombre;
      } else {
      const ciudad = addressList.find(c => {
        return (c.types.find(p => {
          return p === 'locality';
        })) !== undefined;
      });
      this.cliente.direcciones[this.selected.value].ciudad = ciudad ? ciudad.long_name : '';
    }

      this.addMarker(event.latLng);

    });
  }

  async createMarker() {
    let response;
    let direccion = '';
    const direccionCliente = this.cliente.direcciones[this.selected.value];
    direccion += direccionCliente.numero ? direccionCliente.numero : '';
    direccion += direccionCliente.direccion ? '+' + direccionCliente.direccion : '';
    if (direccionCliente.pais.id === 55) {
      if (direccionCliente.provincia) {
        direccion += direccionCliente.provincia.nombre ? ',+' + direccionCliente.provincia.nombre : '';
      }
    }
    direccion += direccionCliente.ciudad ? ',+' + direccionCliente.ciudad : '';
    direccion += direccionCliente.pais ? ',+' + direccionCliente.pais.description : '';
    // console.log(direccion);
    await this.http.get(
      // tslint:disable-next-line:max-line-length
      `https://maps.googleapis.com/maps/api/geocode/json?address=${direccion}&key=AIzaSyC8eisRn7mMqWtC-sdMQ37EVWpS_Q-0-vo`).toPromise().then(res => {
          // console.log(res);
          response = res;
    });
    let bounds;
    if (response.results.length === 0) {
      this.applyGamma();
    } else {
      this.quitarEstilo();
      if (response.results[0].geometry.bounds) {
        // tslint:disable-next-line:max-line-length
        bounds = new google.maps.LatLngBounds(new google.maps.LatLng(response.results[0].geometry.bounds.southwest.lat, response.results[0].geometry.bounds.southwest.lng),
        new google.maps.LatLng(response.results[0].geometry.bounds.northeast.lat, response.results[0].geometry.bounds.northeast.lng));
        this.map.fitBounds(bounds);
        this.deleteAllMarkers();
        if (this.completoDatosDireccion()) {
          this.applyGamma();
        }
      } else {
        this.deleteAllMarkers();
        if (this.completoDatosDireccion()) {
          // tslint:disable-next-line:max-line-length
          bounds = new google.maps.LatLngBounds(new google.maps.LatLng(response.results[0].geometry.viewport.southwest.lat, response.results[0].geometry.viewport.southwest.lng),
          new google.maps.LatLng(response.results[0].geometry.viewport.northeast.lat, response.results[0].geometry.viewport.northeast.lng));
          this.map.fitBounds(bounds);
          this.addMarker(response.results[0].geometry.location);
        } else {
          this.applyGamma();
        }
      }
    }
  }

  addMarker(latLng) {
    this.deleteAllMarkers();
    const marker = new google.maps.Marker({
      position: latLng,
      map: this.map,
    });
    this.cliente.direcciones[this.selected.value].markers.push(marker);
  }

  deleteAllMarkers() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.cliente.direcciones[this.selected.value].markers.length; i++) {
      this.cliente.direcciones[this.selected.value].markers[i].setMap(null);
      this.cliente.direcciones[this.selected.value].markers.pop();
    }
  }

  applyGamma() {

    const mapStyles = [{
        stylers: [{
            gamma: 6
        }]
    }];

    this.map.setOptions({
        styles: mapStyles
    });

    this.map.addListener('center_changed', () => {
      this.textmarker.setPosition(this.map.getCenter());
    });

    this.infowindow.open(this.map, this.textmarker);
  }

  quitarEstilo() {
    this.map.setOptions({
      styles: [{stylers: [{gamma: 0}]}]
    });

    this.infowindow.close();

  }

  completoDatosDireccion() {
    const direccion = this.cliente.direcciones[this.selected.value];
    // tslint:disable-next-line:max-line-length
    return direccion.pais.id && (direccion.provincia ? (direccion.provincia.id || direccion.ciudad) : false) && direccion.direccion && direccion.numero;
  }

  eliminarContacto() {
    this.cliente.contactos.splice(this.selectedContacto.value, 1);
  }
  agregarContacto() {
    const contacto = new Contacto();
    contacto.emails.push(new Email(1, '', false));
    contacto.telefonos.push(new Telefono('', 1));
    this.cliente.contactos.push(contacto);

    this.selectedContacto.setValue(this.cliente.contactos.length - 1);
    this.agregaContacto = true;
  }

  random(index: any) {
    return Math.floor(Math.random() * 6) + index;
  }

  getValorUnico() {
    return Date.now();
  }

  eliminarCuentaBancaria() {
    this.cliente.cuentasBancarias.splice(this.selectedCuenta.value, 1);
  }

  agregarCuentaBancaria() {
    this.cliente.cuentasBancarias.push(new CuentaBancaria());
    this.selectedCuenta.setValue(this.cliente.cuentasBancarias.length - 1);
  }

  getRegexMails() {
    // tslint:disable-next-line:max-line-length
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  checkIfArrayIsUnique(myArray) {
    this.emailError = myArray.length === new Set(myArray).size;
    return this.emailError;
  }
}
