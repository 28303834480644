import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {UniqueIdentifierService} from "../../shared-services/unique-identifier.service";

@Component({
  selector: 'app-cliente-datos-bancarios',
  templateUrl: './cliente-datos-bancarios.component.html',
  styleUrls: ['./cliente-datos-bancarios.component.scss']
})
export class ClienteDatosBancariosComponent implements OnChanges {

  @Input() cuentaBancaria;
  @Input() index;
  @Input() submitted;

  @ViewChild('form', {static: false}) public clienteForm;

  constructor(private uniqueService: UniqueIdentifierService) { }

  ngOnChanges() {
    if (this.submitted) {
      if (this.clienteForm) {
        this.clienteForm.submitted = true;
      }
    }
  }

  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*$/;

    if (!reg.test(input)) {
      e.preventDefault();
    }
  }
}
