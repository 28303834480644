import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicBackgroundComponent } from './public-background/public-background.component';
import { SharedServicesModule } from '../shared-services/shared-services.module';
import { CoreModule } from '../core/core.module';
import { VendorModule } from '../core/vendor.module';
import { SidenavComunicationService } from './layout/sidenav-comunitacation.service';
import { LayoutComponent } from './layout/layout.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { GenericListComponent } from './generic-list/generic-list.component';
import { ModalConfirmComponent } from './modals/modal-confirm/modal-confirm.component';
import { DialogService } from './modals/dialog.service';
import { CovalentFileModule } from '@covalent/core';
import { EmptyListPatternComponent } from './empty-list-pattern/empty-list-pattern.component';
import { ModalImageComponent } from './modals/modal-image/modal-image.component';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { ModalLinkConfirmacionComponent } from './modals/modal-link-confirmacion/modal-link-confirmacion.component';
import { CambioEmailComponent } from './cambio-email/cambio-email.component';
import { ChipAutocompleteComponent } from './chip-autocomplete/chip-autocomplete.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { ChipListAutocompleteComponent } from './chip-list-autocomplete/chip-list-autocomplete.component';
import { CambioDolarVentaComponent } from './cambio-dolar-venta/cambio-dolar-venta.component';
import { CambioDolarCompraComponent } from './cambio-dolar-compra/cambio-dolar-compra.component';
import { ExpansorWithChipComponent } from './expansor-with-chip/expansor-with-chip.component';
import { ExpansorWithChipMultipleComponent } from './expansor-with-chip-multiple/expansor-with-chip-multiple.component';
import { GenericFiltersComponent } from './generic-filters/generic-filters.component';
import { MenuActiveService } from '../shared-services/menu-active.service';
import { UploadFileComponent } from './upload-file/upload-file.component';

const components = [
  PublicBackgroundComponent,
  LayoutComponent,
  SearchInputComponent,
  GenericListComponent,
  ModalConfirmComponent,
  EmptyListPatternComponent,
  ModalImageComponent,
  GenericTableComponent,
  ModalLinkConfirmacionComponent,
  CambioEmailComponent,
  ChipAutocompleteComponent,
  ChipListAutocompleteComponent,
  CambioDolarVentaComponent,
  CambioDolarCompraComponent,
  ExpansorWithChipComponent,
  ExpansorWithChipMultipleComponent,
  GenericFiltersComponent,
  UploadFileComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedServicesModule,
    CoreModule,
    VendorModule,
    CovalentFileModule,
    SlideshowModule
  ],
  declarations: components,
  exports: components,
  providers: [
    SidenavComunicationService,
    DialogService,
    MenuActiveService
  ],
  entryComponents: [
    ModalConfirmComponent,
    ModalImageComponent,
    ModalLinkConfirmacionComponent
  ],
})
export class SharedComponentsModule { }
