import { Comprobante } from './comprobante.model';
import { Operacion } from './operacion.model';

export class ComprobanteOperacion {

    id: number;
    comprobante: Comprobante;
    operacion: Operacion;
    montoPagado: number;

    constructor() {
        this.comprobante = new Comprobante();
        this.operacion = new Operacion();
    }

}
