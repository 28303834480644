import { Injectable } from '../../../../node_modules/@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '../../../../node_modules/@angular/router';
import { Entidad } from '../../models/entidad.model';
import { BackendService } from '../../core/backend.service';

@Injectable()
export class EntidadResolver implements Resolve<Entidad> {
  constructor(
    protected router: Router,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id || 'agregar';

      if (id === 'agregar') {
        const entidad = new Entidad();
        return entidad;
      }

      return await this.backendService.get<Entidad>(Entidad.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/entidades']);
    }
  }
}
