import { Injectable, Inject } from '@angular/core';
import { StorageProvider } from './browser-providers';
import { SnackService } from '../shared-services/snack.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { NavigationService } from '../shared-services/navigation.service';
import { BackendService } from './backend.service';
import { EnvService } from '../shared-services/env.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BackendService {

  constructor(
  protected env: EnvService,
  @Inject(StorageProvider) protected storage: Storage,
  protected navigationService: NavigationService,
  protected snackService: SnackService,
  protected http: HttpClient) {
    super(env, storage, navigationService, snackService, http);
    this.backendUrl = env.api;
  }
}
