export class Departamento {

    static path = '/parametricas/';

    id: number;
    nombre: string;
    codigo: string;

    constructor(id: number) {
      this.id = id;
    }

}
