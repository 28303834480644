import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from '../core/core.module';
import { VendorModule } from '../core/vendor.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { SharedServicesModule } from '../shared-services/shared-services.module';
import { SecurityModule } from '../security/security.module';
import { ClientesComponent } from './clientes.component';
import { ClienteTableComponent } from './cliente-table/cliente-table.component';
import { ClienteDetailsComponent } from './cliente-details/cliente-details.component';
import { ClienteResolver } from './cliente.resolver';
import { ClienteDatosPersonalesCardComponent } from './cliente-datos-personales-card/cliente-datos-personales-card.component';
import { ClienteAddEditComponent } from './cliente-add-edit/cliente-add-edit.component';
import { ClienteDatosAdministrativosComponent } from './cliente-datos-administrativos/cliente-datos-administrativos.component';
import { ClienteDatosDireccionesComponent } from './cliente-datos-direcciones/cliente-datos-direcciones.component';
import { ClienteDatosContactoComponent } from './cliente-datos-contacto/cliente-datos-contacto.component';
import { ClienteDatosPersonalesComponent } from './cliente-datos-personales/cliente-datos-personales.component';
import { ClienteLogoUploadComponent } from './cliente-logo-upload/cliente-logo-upload.component';
import { ClienteLogoListComponent } from './cliente-logo-list/cliente-logo-list.component';
import { TiposPersonasComponent } from './tipos-personas/tipos-personas.component';
import { TiposPersonasTableComponent } from './tipos-personas/tipos-personas-table/tipos-personas-table.component';
import { TiposPersonasAddEditComponent } from './tipos-personas/tipos-personas-add-edit/tipos-personas-add-edit.component';
import { TiposServicioComponent } from './tipos-servicio/tipos-servicio.component';
import { TiposServicioTableComponent } from './tipos-servicio/tipos-servicio-table/tipos-servicio-table.component';
import { TiposServicioAddEditComponent } from './tipos-servicio/tipos-servicio-add-edit/tipos-servicio-add-edit.component';
import { TiposPersonasResolver } from './tipos-personas/tipos-personas.resolver';
import { TiposServicioResolver } from './tipos-servicio/tipos-servicio.resolver';
import { ClienteFiltersComponent } from './cliente-filters/cliente-filters.component';
import {MatChipsModule} from '@angular/material/chips';
import { ClienteDatosComportamientoComponent} from "./cliente-datos-comportamiento/cliente-datos-comportamiento.component";
import { ClienteOtrosContactosComponent } from './cliente-otros-contactos/cliente-otros-contactos.component';
import { ClienteDatosBancariosComponent } from './cliente-datos-bancarios/cliente-datos-bancarios.component';
import { CuentaCorrienteComponent } from './cuenta-corriente/cuenta-corriente.component';
import { CuentaCorrienteViewComponent } from './cuenta-corriente/cuenta-corriente-view/cuenta-corriente-view.component';
import { ClienteRedesSocialesComponent } from './cliente-redes-sociales/cliente-redes-sociales.component';
import {TooltipDirectiveModule} from '../shared-directives/tooltip.directive';

@NgModule({
    imports: [
      CommonModule,
      HttpClientModule,
      CoreModule,
      VendorModule,
      SharedComponentsModule,
      SharedServicesModule,
      SecurityModule,
      MatChipsModule,
      TooltipDirectiveModule
    ],
    declarations: [
      ClientesComponent,
      ClienteTableComponent,
      ClienteDetailsComponent,
      ClienteDatosPersonalesCardComponent,
      ClienteAddEditComponent,
      ClienteDatosAdministrativosComponent,
      ClienteDatosDireccionesComponent,
      ClienteDatosContactoComponent,
      ClienteDatosPersonalesComponent,
      ClienteLogoUploadComponent,
      ClienteLogoListComponent,
      TiposPersonasComponent,
      TiposPersonasTableComponent,
      TiposPersonasAddEditComponent,
      TiposServicioComponent,
      TiposServicioTableComponent,
      TiposServicioAddEditComponent,
      ClienteFiltersComponent,
      ClienteDatosComportamientoComponent,
      ClienteOtrosContactosComponent,
      ClienteDatosBancariosComponent,
      CuentaCorrienteComponent,
      CuentaCorrienteViewComponent,
      ClienteRedesSocialesComponent
    ],
    exports: [
      ClientesComponent,
      ClienteTableComponent,
      ClienteDatosPersonalesCardComponent,
      ClienteAddEditComponent,
      ClienteDatosAdministrativosComponent,
      ClienteDatosDireccionesComponent,
      ClienteDatosContactoComponent,
      ClienteDatosPersonalesComponent,
      TiposPersonasComponent,
      TiposPersonasTableComponent,
      TiposPersonasAddEditComponent,
      TiposServicioComponent,
      TiposServicioTableComponent,
      TiposServicioAddEditComponent,
      ClienteFiltersComponent,
      CuentaCorrienteComponent,
      CuentaCorrienteViewComponent
    ],
    providers: [
      ClienteResolver,
      TiposPersonasResolver,
      TiposServicioResolver
    ]
  })
export class ClienteModule { }
