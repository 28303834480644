import {Component, OnInit, Input, OnChanges, ViewChild, OnDestroy} from '@angular/core';
import { Cliente } from '../../models/cliente.model';
import { OperationalParametric } from '../../models/operationalParametric.model';
import { ApiService } from '../../core/api.service';
import { DataObject } from '../../models/dataObject.model';
import { TipoPersona } from '../../models/tipo-persona.model';
import { BackendService } from '../../core/backend.service';
import { TipoServicio } from '../../models/tipo-servicio.model';
import { RetencionGanancia } from '../../models/retencion-ganancia.model';
import {UniqueIdentifierService} from "../../shared-services/unique-identifier.service";
import {EscalaRetencionGanancia} from "../../models/escala-retencion-ganancia.model";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {ReplaySubject, Subject} from "rxjs";

@Component({
  selector: 'app-cliente-datos-administrativos',
  templateUrl: './cliente-datos-administrativos.component.html',
  styleUrls: ['./cliente-datos-administrativos.component.scss']
})
export class ClienteDatosAdministrativosComponent implements OnInit, OnChanges, OnDestroy {

  @Input() cliente: Cliente;
  @Input() requiredSitIva = false;
  @Input() requiredCuitCuil = false;
  @Input() buttonClicked = false;
  @Input() submitted;


  situacionesIva: OperationalParametric[];
  tiposPersona: TipoPersona[];
  tiposServicio: TipoServicio[];
  retencionesGanancia: RetencionGanancia[];

  @ViewChild('form', {static: false}) public clienteForm;
  escalasRetencionGanancias: EscalaRetencionGanancia[];

  public retencionFilterCtrl: FormControl = new FormControl();
  protected _onDestroyRetencion = new Subject<void>();
  public filteredRetenciones: ReplaySubject<EscalaRetencionGanancia[]> = new ReplaySubject<EscalaRetencionGanancia[]>(1);

  constructor(
    private apiService: ApiService,
    private backendService: BackendService,
    private uniqueService: UniqueIdentifierService
  ) { }

  async ngOnInit() {
    await this.getSituacionesIva();
    await this.getTiposPersona();
    await this.getTiposServicio();
    await this.getRetencionesGanancias();
    await this.getEscalasRetencionGanancias();


    this.filterRetenciones();
    this.retencionFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyRetencion))
      .subscribe(() => {
        this.filterRetenciones();
      });
  }

  ngOnChanges() {
    if (this.submitted) {
      this.clienteForm.submitted = true;
    }

    if (this.escalasRetencionGanancias) {
      this.filteredRetenciones.next(this.escalasRetencionGanancias.slice());
    }
  }

  async getSituacionesIva() {
    await this.apiService.get<DataObject<OperationalParametric>>(OperationalParametric.path + 'situaciones_iva/', null).toPromise().then(res => {
      this.situacionesIva = res.data;
    });
  }

  async getTiposPersona() {
    await this.backendService.get<DataObject<TipoPersona>>(TipoPersona.path + 'all', null).toPromise().then(res => {
      this.tiposPersona = res.data;
    });
  }

  async getTiposServicio() {
    await this.backendService.get<DataObject<TipoServicio>>(TipoServicio.path + 'all', null).toPromise().then(res => {
      this.tiposServicio = res.data;
    });
  }

  async getRetencionesGanancias() {
    await this.apiService.get<DataObject<RetencionGanancia>>(OperationalParametric.path + RetencionGanancia.path, null).toPromise().then(res => {
      this.retencionesGanancia = res.data;
    });
  }

  async getEscalasRetencionGanancias() {
    await this.backendService.get<EscalaRetencionGanancia[]>(EscalaRetencionGanancia.path, null).toPromise().then(res => {
      this.escalasRetencionGanancias = res  as EscalaRetencionGanancia[];
    });
  }


  validateNumberWithComa(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*\.?\d*$/;

    if (!reg.test(input)) {
      e.preventDefault();
    }
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      return c1.id === c2.id;
    } else {return false; }
  }

  ngOnDestroy() {
    this._onDestroyRetencion.next();
    this._onDestroyRetencion.complete();
  }

  protected filterRetenciones() {
    if (!this.escalasRetencionGanancias) {
      return;
    }
    let search = this.retencionFilterCtrl.value;
    if (!search) {
      this.filteredRetenciones.next(this.escalasRetencionGanancias.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredRetenciones.next(
      this.escalasRetencionGanancias.filter(rete => rete.detalle.toLowerCase().indexOf(search) > -1)
    );
  }

}
