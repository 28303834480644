import { InjectionToken } from '@angular/core';

import { LANG_ES_NAME, LANG_ES_TRANS } from './translations-lang-es';

export const TRANSLATIONS = new InjectionToken('translations');

export const dictionary = {
    [LANG_ES_NAME]: LANG_ES_TRANS,
};

// providers
export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS, useValue: dictionary },
];
