import { Operacion } from './operacion.model';
import { MedioPago } from './medio-pago.model';
import { Cliente } from './cliente.model';
import { Foto } from './foto.model';

export class OperacionMedioPago {

    id: number;
    medioPago: MedioPago;
    operacion: Operacion;
    monto: number;
    banco: Cliente;
    fechaEmision: Date;
    fechaVencimiento: Date;
    numeroComprobante: string;
    sucursalComprobante: string;
    imagenes: Foto[];
    orden: boolean;

    constructor() {
        this.medioPago = new MedioPago();
        this.operacion = new Operacion();
        this.banco = new Cliente();
        this.imagenes = [];
        this.monto = 0;
    }

}
