import { Injectable } from '../../../../node_modules/@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '../../../../node_modules/@angular/router';
import { BackendService } from '../../core/backend.service';
import { OperacionVista } from '../../models/operacion-vista.model';

@Injectable()
export class OperacionResolver implements Resolve<OperacionVista> {
  constructor(
    protected router: Router,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.opId || 'agregar';
      if (id === 'agregar') {
        const operacion = new OperacionVista();
        return operacion;
      }

      return await this.backendService.get<OperacionVista>(OperacionVista.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/operaciones']);
    }
  }
}
