import { Component, OnInit, ViewChild } from '@angular/core';
import { Filters } from '../../../models/filters.model';
import { GenericListComponent } from '../../../shared-components/generic-list/generic-list.component';
import { switchMap, share } from '../../../../../node_modules/rxjs/operators';
import { PaginatedList } from '../../../models/paginatedList.model';
import { Cliente } from '../../../models/cliente.model';
import { Observer, Observable } from '../../../../../node_modules/rxjs';
import { BackendService } from '../../../core/backend.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { SecurityService } from '../../../security/security.service';

@Component({
  selector: 'app-entidad-operacion-table',
  templateUrl: './entidad-operacion-table.component.html',
  styleUrls: ['./entidad-operacion-table.component.css']
})
export class EntidadOperacionTableComponent implements OnInit {
  isLoading = false;
  roleFiltersActive = false;
  addUrl = '';
  private nameSearch = '';

  public filters: Filters = {
    name: undefined,
    page: 0,
    size: 10,
  };
  entidadesLista = [];

  @ViewChild(GenericListComponent, {static: false}) genericList;

  addText = 'Agregar entidades';
  searchTitle = 'Entidades';
  searchPlaceHolder = 'Ingrese la entidad que desea buscar';
  messageEmpty = 'No se encuentran entidades.';
  messageFilters = 'No se encuentran entidades que coincidan con el criterio de búsqueda actual.';
  botonAgregar = false;
  permisoCrear = 'CREAR_ENTIDAD';
  atributoBusqueda = 'textsearch';

  entidadesList = this.backendService.newSubjectWithoutDistinct<Filters>().pipe(
    switchMap(filters => {
      const promise = this.backendService.get<PaginatedList<Cliente>>(Cliente.path, filters).toPromise();
      return (async () => {
        this.isLoading = true;
        try {
          const permissions = await promise;
          this.entidadesLista = permissions.data;
          return permissions;
        } catch (error) {
          return [];
        } finally {
          this.isLoading = false;
          this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
        }
      })();
    }))
  .pipe(share()) as Observer<Filters> & Observable<Cliente[]>;

  constructor(
    private backendService: BackendService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    public securityService: SecurityService,
  ) { }

  ngOnInit() {
  }

  onCreateOperacion(entidad: Cliente) {
    this.router.navigate(['/operaciones/' + entidad.id + '/agregar']);
  }

  async onFiltersChanged(event) {
    this.filters.search = await this.buildSearch();
    this.genericList.refreshRoleList();
  }

  buildSearch() {
    let searchString: string;
    if (this.nameSearch) {
      searchString += 'textsearch:' + this.nameSearch + ',';
    }

    return searchString;
  }

  onViewOperaciones(entidad: Cliente) {
    this.router.navigate(['/operaciones', entidad.id]);
  }
}
