
export class TipoConfiguracion {

  static path = '/configuraciones/';

  id: number;
  description: string;
  created: Date;
  updated: Date;
  deleted: Date;

}
