import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from '../../models/user.model';
import { SnackService } from '../../shared-services/snack.service';
import { SecurityService } from '../security.service';
import { BackendService } from '../../core/backend.service';

@Injectable()
export class UserResolver implements Resolve<User> {
  constructor(
    protected router: Router,
    protected snackService: SnackService,
    protected securityService: SecurityService,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<User> {
    try {
      const id = route.params.id || 'agregar';
      if (id === 'agregar') {

        let usuario = new User();
        return usuario;
      }

      return await this.backendService.get<User>(User.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/usuarios']);
    }
  }
}
