import { Component, OnInit, Input, ElementRef, ViewChild, EventEmitter, Output, OnChanges } from '@angular/core';
import { OperationalParametric } from '../../models/operationalParametric.model';
import { Observable } from 'rxjs';
import { ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { ApiService } from '../../core/api.service';
import { DataObject } from '../../models/dataObject.model';
import { startWith, map } from 'rxjs/operators';
import { MatChipInputEvent, MatAutocompleteSelectedEvent } from '@angular/material';

@Component({
  selector: 'app-chip-autocomplete',
  templateUrl: './chip-autocomplete.component.html',
  styleUrls: ['./chip-autocomplete.component.css']
})
export class ChipAutocompleteComponent implements OnInit, OnChanges {

  @Input() modelo;
  @Input() label: string;
  @Input() atributo;
  @Input() lista;
  @Input() submitted;
  @Output() onChange = new EventEmitter<void>();

  filteredOptions: Observable<OperationalParametric[]>;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER];
  myControl: FormControl = new FormControl();
  @ViewChild('clienteInput', {static: false}) clienteInput: ElementRef;
  private paisCtrl: FormControl;
  public focused = false;
  constructor(
    private apiService: ApiService) {

  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.lista) {
      this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterUsers(val))
      );
    }
    if (this.submitted) {
      this.myControl.markAsTouched();
    }
  }

  add(event: MatChipInputEvent): void {
    // debugger
    const input = event.input;
    const value = event.value;

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.myControl.setValue(null);
    this.onChange.emit();
  }

  filterUsers(val: any): OperationalParametric[] {
    if (val == null) {
      return this.lista;
    } else {
        return this.lista.filter(option =>
        option[this.atributo].toLowerCase().indexOf(val.toLowerCase()) === 0);
    }
  }

  remove(): void {
    this.modelo.id = undefined;
    this.onChange.emit();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.modelo[this.atributo] = event.option.value;
    for (const x of this.lista) {
      if (x[this.atributo] && x[this.atributo] === this.modelo[this.atributo]) {
        this.modelo.id = x.id;
        break;
      }
    }
    this.clienteInput.nativeElement.value = '';
    this.myControl.setValue(null);
    this.onChange.emit();
    // this.renderer.invokeElementMethod(this.clienteInput.nativeElement, 'blur', []);
  }

}
