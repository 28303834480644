import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable } from '../../../../../node_modules/rxjs';
import { Sugerencia } from '../../../models/sugerencia.model';
import { ENTER } from '../../../../../node_modules/@angular/cdk/keycodes';
import { NgForm, FormControl } from '../../../../../node_modules/@angular/forms';
import { CategoriaProducto } from '../../../models/categoria-producto.model';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { SnackService } from '../../../shared-services/snack.service';
import { BackendService } from '../../../core/backend.service';
import { FormValidatorService } from '../../../shared-services/form-validator.service';
import { MediaService } from '../../../shared-services/media.service';
import { NavigationService } from '../../../shared-services/navigation.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { MatAutocompleteSelectedEvent, MatChipInputEvent } from '../../../../../node_modules/@angular/material';
import { map } from '../../../../../node_modules/rxjs/operators';

@Component({
  selector: 'app-categoria-producto-add-edit',
  templateUrl: './categoria-producto-add-edit.component.html',
  styleUrls: ['./categoria-producto-add-edit.component.css']
})
export class CategoriaProductoAddEditComponent implements OnInit {

  sugerencias: Observable<Sugerencia[]>;
  _selectedSugerencias: Sugerencia[] = [];
  separatorKeysCodes: number[] = [ENTER];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  @ViewChild('form', {static: false}) public roleForm: NgForm;
  toppings = new FormControl();
  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef;

  validationFail = false;
  item: CategoriaProducto;

  messageTitle: string;

  isHD = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackService: SnackService,
    private backendService: BackendService,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private navigationService: NavigationService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {

    this.getSugerencias();
    this.route.data.subscribe(data => {
      this.item = data.user;
      this.item.description = this.item.id ? this.item.description : '';
      if (!this.item.sugerencias) {
        this.item.sugerencias = [];
      }

    });

    this.messageTitle = this.item.id  ? 'Editar Categoría' : 'Agregar Categoría';

    this.mediaService.isHighDefinition.subscribe(isHD => this.isHD = isHD);
  }

  async getSugerencias() {
    this.sugerencias = this.backendService.get(Sugerencia.path, {});
  }

  back() {
    this.navigationService.back();
  }

  remove(sugerencia: Sugerencia): void {

    const index = this.item.sugerencias.indexOf(sugerencia);
    if (index >= 0) {
      this.item.sugerencias.splice(index, 1);
    }
    this.filter();
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    const index = this.item.sugerencias.indexOf(event.option.value);

    if (index >= 0) {
      return;
    }
    this.item.sugerencias.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    this.toppings.setValue(null);
    // this.renderer.invokeElementMethod(this.fruitInput.nativeElement, 'blur', []);
    this.filter();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
  }

  public sugerenciaSelected(sug: Sugerencia): boolean {
    for (const sugCate of this.item.sugerencias) {
      if (sug.id === sugCate.id) {
        return true;
      }
    }
    return false;
  }

  private filter() {
    this.sugerencias
    .pipe(map(r => r.filter(p => this._selectedSugerencias.indexOf(p) === 0)));
  }
  async save() {
    if (this.validateForm()) {
      this.ConfirmSave();
    }
  }

  validateForm() {
    let validationsOK = false;

    // Valida el resto de los controles con sus validadores
    if (this.formValidatorService.isFormValid(this.roleForm) && this.item.imagen) {
      validationsOK = true;
      this.validationFail = false;
    } else {
      validationsOK = false;
      this.validationFail = true;
    }

    return validationsOK;
  }

  async ConfirmSave() {
    this.dialogService.confirm(this.messageTitle,
    '¿Está seguro que desea guardar la categoría ' + this.item.description + '?')
    .then( res => {
      if (res) {
        this.saveItem(res);
      }
    });
  }

  addFile(event: any) {
    this.item.imagen = event.target.files[0];
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.item.imagen = (reader.result as string).split(',')[1];
      };
    }
  }

  async saveItem(res) {
    if (res) {

      this.snackService.spinner(true);

      if (this.item.id) { // Update
        try {
          await await this.backendService.update(CategoriaProducto.path + this.item.id, this.item);
          this.snackService.success('Categoría editada');
          this.router.navigate(['/categorias']);
        } catch (ex) {
          if (ex.error.message) {
            this.snackService.error(ex.error.message);
          } else if (ex.error.details) {
            this.snackService.error(ex.error.details);
          } else if (ex.error[0].message) {
            this.snackService.error(ex.error[0].message);
          } else if (ex.error[0].details) {
            this.snackService.error(ex.error[0].details);
          }
        } finally {
          this.snackService.spinner(false);
        }
      } else {
        try {
          await this.backendService.create(CategoriaProducto.path, this.item);
          this.snackService.success('Categoria creada');
          this.router.navigate(['/categorias']);
        } catch (ex) {
          if (ex.error.message) {
            this.snackService.error(ex.error.message);
          } else if (ex.error.details) {
            this.snackService.error(ex.error.details);
          } else if (ex.error[0].message) {
            this.snackService.error(ex.error[0].message);
          } else if (ex.error[0].details) {
            this.snackService.error(ex.error[0].details);
          }
        } finally {
          this.snackService.spinner(false);
        }
      }
    }
  }



}
