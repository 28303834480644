import { NgModule } from '../../../node_modules/@angular/core';
import { CommonModule } from '../../../node_modules/@angular/common';
import { HttpClientModule } from '../../../node_modules/@angular/common/http';
import { CoreModule } from '../core/core.module';
import { VendorModule } from '../core/vendor.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { SharedServicesModule } from '../shared-services/shared-services.module';
import { SecurityModule } from '../security/security.module';
import { ConfiguracionComponent } from './configuracion.component';
import { CotizacionTableComponent } from './cotizacion/cotizacion-table/cotizacion-table.component';
import { CotizacionComponent } from './cotizacion/cotizacion.component';
import { CotizacionEditComponent } from './cotizacion/cotizacion-edit/cotizacion-edit.component';
import { CotizacionResolver } from './cotizacion/cotizacion.resolver';

@NgModule({
    imports: [
      CommonModule,
      HttpClientModule,
      CoreModule,
      VendorModule,
      SharedComponentsModule,
      SharedServicesModule,
      SecurityModule,
    ],
    declarations: [
        ConfiguracionComponent,
        CotizacionComponent,
        CotizacionTableComponent,
        CotizacionEditComponent
    ],
    exports: [
        ConfiguracionComponent,
        CotizacionTableComponent,
        CotizacionEditComponent
    ],
    providers: [
        CotizacionResolver
    ]
  })
export class ConfiguracionModule { }
