export class OperationalParametric {

    static path = '/parametricas/';
    id: number;
    description: string;

    constructor() {

    }

}
