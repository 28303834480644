import { User } from './user.model';
import { Sucursal } from './sucursal.model';
import { Cliente } from './cliente.model';

export class Operacion {

    static path = '/operacion/';

    id: number;
    recibo: string;
    entidad: Cliente;
    importe: number;
    sucursal: Sucursal;
    fechaCreacion: Date;
    observaciones: string;
    usuarioInterno: User;

    constructor() {
        this.entidad = new Cliente();
        this.sucursal = new Sucursal();
        this.importe = 0;
    }
}
