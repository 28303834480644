import { Sugerencia } from './sugerencia.model';
import { CategoriaProducto } from './categoria-producto.model';

export class SubcategoriaProducto {

  static path = '/productos/subcategorias/';

  id: number;
  description: string;
  origen: string;
  categorias?: CategoriaProducto[];
  sugerencias?: Sugerencia[];

  interna(): boolean {
    return this.origen === CategoriaProducto.codInterna;
  }

  get sugerenciaString() {
    let s = '';
    this.sugerencias.forEach(v => s += v.description + ', ');
    s = s.length > 100 ? s.substr(0, 100) + '[...]' : s.substr(0, s.length - 2);
    return s;
  }

  get categoriaString() {
    let s = '';
    this.categorias.forEach(v => s += v.description + ', ');
    s = s.length > 100 ? s.substr(0, 100) + '[...]' : s.substr(0, s.length - 2);
    return s;
  }
}
