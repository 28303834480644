import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '../../../../../node_modules/@angular/forms';
import { Entidad } from '../../../models/entidad.model';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { SnackService } from '../../../shared-services/snack.service';
import { BackendService } from '../../../core/backend.service';
import { FormValidatorService } from '../../../shared-services/form-validator.service';
import { MediaService } from '../../../shared-services/media.service';
import { NavigationService } from '../../../shared-services/navigation.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';

@Component({
  selector: 'app-entidad-add-edit',
  templateUrl: './entidad-add-edit.component.html',
  styleUrls: ['./entidad-add-edit.component.css']
})
export class EntidadAddEditComponent implements OnInit {


  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  @ViewChild('form', {static: false}) public roleForm: NgForm;

  validationFail = false;
  item: Entidad;

  messageTitle: string;

  isHD = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackService: SnackService,
    private backendService: BackendService,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private navigationService: NavigationService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {

    this.route.data.subscribe(data => {
      this.item = data.user;
      this.item.description = this.item.id ? this.item.description : '';
    });

    this.messageTitle = this.item.id  ? 'Editar Entidad' : 'Agregar Entidad';

    this.mediaService.isHighDefinition.subscribe(isHD => this.isHD = isHD);
  }

  back() {
    this.navigationService.back();
  }

  async save() {
    if (this.validateForm()) {
      this.invokeSave();
    }
  }

  validateForm() {
    let validationsOK = false;

    // Valida el resto de los controles con sus validadores
    if (this.formValidatorService.isFormValid(this.roleForm)) {
      validationsOK = true;
      this.validationFail = false;
    } else {
      validationsOK = false;
      this.validationFail = true;
    }

    return validationsOK;
  }

  private async invokeSave() {

    if (this.validateForm()) {
      this.ConfirmSave();
    }

    return;
  }

  async ConfirmSave() {
    this.dialogService.confirm(this.messageTitle,
    '¿Está seguro que desea guardar la entidad ' + this.item.description + '?')
    .then( res => {
      if (res) {
        this.saveItem(res);
      }
    });
  }

  async saveItem(res) {
    if (res) {

      this.snackService.spinner(true);

      if (this.item.id) { // Update
        try {
          await await this.backendService.update(Entidad.path + this.item.id, this.item);
          this.snackService.success('Entidad editada');
          this.router.navigate(['/entidades']);
        } catch (ex) {
          this.snackService.error(ex.message);
        } finally {
          this.snackService.spinner(false);
        }
      } else {
        try {
          await this.backendService.create(Entidad.path, this.item);
          this.snackService.success('Entidad creada');
          this.router.navigate(['/entidades']);
        } catch (ex) {
          this.snackService.error(ex.message);
        } finally {
          this.snackService.spinner(false);
        }
      }
    }
  }


}
