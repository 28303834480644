import { OperationalParametric } from './operationalParametric.model';
import { Provincia } from './provincia.model';
import { Departamento } from './departamento.model';
import { PaisCliente } from './paisCliente.model';
import {EmailCliente} from "./emailCliente.model";
import {Email} from "./email.model";
import {Telefono} from "./telefono.model";
import {PathSociales} from "./path-sociales.interface";

export class Contacto implements PathSociales{

  id: number;
  nombre:string;
  apellido:string;
  cargoObservaciones:string;
  emails: Email[] = [];
  telefonos: Telefono[] = [];
  websitePath: string;
  twitterPath: string;
  facebookPath: string;
  instagramPath: string;
  constructor() {
  }



}
