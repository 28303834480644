import { Email } from './email.model';
import { Sector } from './sector.model';

export class Subcliente {
    public static path = '/subclientes/';

    id: number;
    username: string;
    password: string;
    retryPassword: string;
    clientePadreId: number;
    sector: Sector;
    email: Email;
    montoMax: number;
    idTipoMontoMax: number;
    enable: boolean;
}
