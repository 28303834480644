import { Injectable } from '../../../../node_modules/@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '../../../../node_modules/@angular/router';
import { TipoServicio } from '../../models/tipo-servicio.model';
import { SnackService } from '../../shared-services/snack.service';
import { SecurityService } from '../../security/security.service';
import { BackendService } from '../../core/backend.service';


@Injectable()
export class TiposServicioResolver implements Resolve<TipoServicio> {
  constructor(
    protected router: Router,
    protected snackService: SnackService,
    protected securityService: SecurityService,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id || 'agregar';

      if (id === 'agregar') {
        let tipoServicio = new TipoServicio();
        return tipoServicio;
      }
      return await this.backendService.get<TipoServicio>(TipoServicio.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/roles']);
    }
  }
}
