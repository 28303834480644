import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { NgForm, FormControl } from '@angular/forms';
import { Role } from '../../../models/role.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackService } from '../../../shared-services/snack.service';
import { BackendService } from '../../../core/backend.service';
import { FormValidatorService } from '../../../shared-services/form-validator.service';
import { MediaService } from '../../../shared-services/media.service';
import { NavigationService } from '../../../shared-services/navigation.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { ReplaySubject, Subject } from 'rxjs';
import { Permission } from '../../../models/permission.model';
import { MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { DataObject } from '../../../models/dataObject.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-role-add-edit',
  templateUrl: './role-add-edit.component.html',
  styleUrls: ['./role-add-edit.component.scss']
})
export class RoleAddEditComponent implements OnInit, OnDestroy, AfterViewChecked {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER];

  @ViewChild('form', {static: false}) public roleForm: NgForm;

  toppings = new FormControl();
  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef;

  validationFail = false;
  role: Role;

  messageTitle: string;

  isHD = false;

  public permisoFilterCtrl: FormControl = new FormControl();
  public filteredPermissions: ReplaySubject<Permission[]> = new ReplaySubject<Permission[]>(1);
  protected _onDestroyPermissions = new Subject<void>();
  permisoAGuardar;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackService: SnackService,
    private backendService: BackendService,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private navigationService: NavigationService,
    private dialogService: DialogService,
    private changeDetector: ChangeDetectorRef
  ) { }

  permissionList: Permission[];

  async ngOnInit() {

    this.route.data.subscribe(data => {
      this.role = data.role;

      if (!this.role.permissions) {
        this.role.permissions = [];
      }
    });

    this.messageTitle = this.role.id ? 'Edición de rol' : 'Alta de rol';

    await this.backendService.get<DataObject<Permission>>(Permission.path + 'all').toPromise().then(res => {
      this.permissionList = res.data;
    });

    this.permisoFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroyPermissions))
    .subscribe(() => {
      this.filterPermisos();
  });
    await this.filteredPermissions.next(this.permissionList.slice());
    this.filter();
    this.mediaService.isHighDefinition.subscribe(isHD => this.isHD = isHD);
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this._onDestroyPermissions.next();
    this._onDestroyPermissions.complete();
  }

  protected filterPermisos() {
    if (!this.permissionList) {
      return;
    }
    let search = this.permisoFilterCtrl.value;
    if (!search) {
      this.filteredPermissions.next(this.permissionList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredPermissions.next(
      this.permissionList.filter(per => per.name.toLowerCase().indexOf(search) > -1)
    );
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      return c1.id === c2.id;
    } else {return false; }
  }

  back() {
    this.navigationService.back();
  }

  async save() {
    if (this.validateForm()) {
      this.ConfirmSave();
    }
  }

  validateForm() {
    let validationsOK = false;

    // Valida el resto de los controles con sus validadores
    if (this.formValidatorService.isFormValid(this.roleForm)) {
      validationsOK = true;
      this.validationFail = false;
    } else {
      validationsOK = false;
      this.validationFail = true;
    }

    return validationsOK;
  }

  remove(permission: Permission): void {
    const index = this.role.permissions.indexOf(permission);

    if (index >= 0) {
      this.role.permissions.splice(index, 1);
    }

    this.filter();
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    for (const permission of this.role.permissions) {
      if (permission.id === event.option.value.id) {
        return;
      }
    }

    this.role.permissions.push(event.option.value);

    this.fruitInput.nativeElement.value = '';
    this.toppings.setValue(null);
    // this.renderer.invokeElementMethod(this.fruitInput.nativeElement, 'blur', []);
    this.filter();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

  }

  async ConfirmSave() {
    this.dialogService.confirm(this.messageTitle,
    '¿Está seguro que desea guardar el rol ' + this.role.name + '?')
    .then( res => {
      if (res) {
        this.saveRole(res);
      }
    });
  }

  async saveRole(res) {
    if (res) {

      this.snackService.spinner(true);

      if (this.role.id) { // Update
        try {
          await await this.backendService.update(Role.path + this.role.id, this.role);
          this.snackService.success('Rol editado');
          this.router.navigate(['/roles']);
        } catch (ex) {
          if (ex.error.details) {
            this.snackService.error(ex.error.details);
          } else if (ex.error.message) {
            this.snackService.error(ex.error.message);
          } else if (ex.error[0].details) {
            this.snackService.error(ex.error[0].details);
          } else if (ex.error[0].message) {
          this.snackService.error(ex.error[0].message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      } else {
        try {
          await this.backendService.create(Role.path, this.role);
          this.snackService.success('Rol creado');
          this.router.navigate(['/roles']);
        } catch (ex) {
          if (ex.error) {
            this.snackService.error(ex.error.details);
          } else if (ex.error.message) {
            this.snackService.error(ex.error.message);
          } else if (ex.error[0].details) {
            this.snackService.error(ex.error[0].details);
          } else if (ex.error[0].message) {
          this.snackService.error(ex.error[0].message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      }
    }
  }

  public permissionSelected(permission: Permission): boolean {
    for (const rolePermission of this.role.permissions) {
      if (permission.id === rolePermission.id) {
        return true;
      }
    }
    return false;
  }

  private filter() {
    this.permissionList.filter(p => this.role.permissions.indexOf(p) === 0);
  }

}
