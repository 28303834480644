import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedServicesModule } from './shared-services/shared-services.module';
import { VendorModule } from './core/vendor.module';
import { CoreModule } from './core/core.module';
import { SecurityModule } from './security/security.module';
import { HomeModule } from './home/home.module';
import { ClienteModule } from './clientes/cliente.module';
import { ProductosModule } from './productos/productos.module';
import { ConfiguracionModule } from './configuracion/configuracion.module';
import { EnvServiceProvider } from './shared-services/env.service.provider';
import { CobranzasPagosModule } from './cobranzas-pagos/cobranzas-pagos.module';
import {CKEditorModule} from "ng2-ckeditor";


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedServicesModule,
    SecurityModule,
    VendorModule,
    CoreModule,
    HomeModule,
    ClienteModule,
    ProductosModule,
    ConfiguracionModule,
    CobranzasPagosModule,
    CKEditorModule
  ],
  providers: [
    EnvServiceProvider
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
