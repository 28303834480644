import { Component, OnInit, ViewChild } from '@angular/core';
import { Filters } from '../../../models/filters.model';
import { GenericTableComponent, HasAction, Actions } from '../../../shared-components/generic-table/generic-table.component';
import { Subject, Subscription, Observer, Observable } from '../../../../../node_modules/rxjs';
import { switchMap, share } from '../../../../../node_modules/rxjs/operators';
import { PaginatedList } from '../../../models/paginatedList.model';
import { Entidad } from '../../../models/entidad.model';
import { EnvService } from '../../../shared-services/env.service';
import { BackendService } from '../../../core/backend.service';
import { MatPaginatorIntl } from '../../../../../node_modules/@angular/material';
import { SnackService } from '../../../shared-services/snack.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { MediaService } from '../../../shared-services/media.service';
import { SecurityService } from '../../../security/security.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';

@Component({
  selector: 'app-entidad-table',
  templateUrl: './entidad-table.component.html',
  styleUrls: ['./entidad-table.component.css']
})
export class EntidadTableComponent implements OnInit {


  private backendUrl: string;

  public filters: Filters = {
    name: undefined,
    page: 0,
    size: 10,
  };

  columns = [
    { name: 'id', label: 'Identificador' },
    { name: 'description', label: 'Entidad' }
  ];

  public showFilters = true;
  isMobile: boolean;

  @ViewChild(GenericTableComponent, {static: false})
  private genericTableComponent: GenericTableComponent;

  private getNewPage = new Subject<Filters>();

  public totalItems: number;

  public hasActions: HasAction;

  roleFiltersActive = false;
  mostrarResumen = false;
  isLoading = false;

  rolesLista = [];

  filtersLoaded = false;
  subscriptions: Subscription[] = [];
  addText = 'Agregar Entidad';
  addUrl = '/entidades/agregar';
  searchTitle = 'Entidad';
  searchPlaceHolder = 'Ingrese el nombre de la entidad que desea buscar';
  messageEmpty = 'No se encuentran entidades.';
  messageFilters = 'No se encuentran entidades que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_ENTIDAD';

  roleList = this.backendService.newSubjectWithoutDistinct<Filters>().pipe(
  switchMap(filters => {
    const usersPromise = this.backendService.get<PaginatedList<Entidad>>(Entidad.path, filters).toPromise();
    return (async () => {
      this.isLoading = true;
      try {
        const permissions = await usersPromise;
        this.rolesLista = permissions.data;
        return permissions;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
        this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
      }
    })();
  })).pipe(share()) as Observer<Filters> & Observable<Entidad[]>;

  constructor(
    protected env: EnvService,
    private backendService: BackendService,
    private paginatorsLabels: MatPaginatorIntl,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    private mediaService: MediaService,
    public securityService: SecurityService,
    private dialogService: DialogService,
  ) {
    this.backendUrl = env.api + '/backend';
  }

  ngOnInit() {
    this.showFilters = true;
    this.filtersLoaded = true;

    this.hasActions = new HasAction();

    this.hasActions.hasAction = true;
    this.hasActions.actions = [{ 'element': Actions.edit, 'permission': 'EDITAR_ENTIDAD'},
                              { 'element': Actions.delete, 'permission': 'ELIMINAR_ENTIDAD'}];

    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.showFilters = !this.isMobile;
    }));

    this.paginatorsLabels.itemsPerPageLabel = 'Filas por página:';

    this.roleList.subscribe(t => {
      this.isLoading = false;
      this.totalItems = t['totalElements'];
    });

    this.refreshRoleList();
  }

  refreshRoleList() {
    this.roleList.next(this.getFilters());
  }

  getDefaultFilters() {
    this.filters = {
      name : undefined,
      page: 0,
      size: 10,
    };
  }

  getFilters() {
    return this.filters;
  }

  onEditEntidad(ent: any) {
    this.router.navigate(['/entidades/editar', ent.id]);
  }

  async onDeleteEntidad(ent: Entidad) {

    this.dialogService.confirm('Eliminar entidad',
    '¿Está seguro que desea eliminar el entidad ' + ent.description + '?')
    .then( res => {
      if (res) {
        this.deleteEntidad(res, ent);
      }
    });

  }

  async deleteEntidad(res, ent: Entidad) {

    if (res) {

      this.snackService.spinner(true);

      try {
        await this.backendService.delete(Entidad.path + ent.id);
        this.snackService.success('Entidad eliminada correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.refreshRoleList();
      }

    }

  }

}
