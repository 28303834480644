import { Email } from './email.model';
import { OperationalParametric } from './operationalParametric.model';
import { Role } from './role.model';
import { Telefono } from './telefono.model';
import { Foto } from './foto.model';
import { Sucursal } from './sucursal.model';

export class User {

  static path = '/usuarios_internos/';

  id: number;
  username: string;
  name: string;
  lastname: string;
  emails: Email[];
  email_direccion: string;
  genero: OperationalParametric;
  habilitado: boolean;
  ultimoLogin: any;
  roles?: Role[];
  password: string;
  retryPassword: string;
  telefonos: Telefono[];
  fotos: Foto[] = [];
  enable = true;
  sucursales: Sucursal[];
}
