import {Component, Input, OnInit, OnChanges, ViewChild} from '@angular/core';
import {Contacto} from "../../models/contacto.model";
import {ParametricWithIcon} from "../../models/parametricWithIcon.model";
import {TelefonoCliente} from "../../models/telefonoCliente.model";
import {EmailCliente} from "../../models/emailCliente.model";
import {ApiService} from "../../core/api.service";
import {DataObject} from "../../models/dataObject.model";
import {Telefono} from "../../models/telefono.model";
import {Email} from "../../models/email.model";
import { MatRadioChange } from '@angular/material';
import {Cliente} from "../../models/cliente.model";
import {UniqueIdentifierService} from "../../shared-services/unique-identifier.service";

@Component({
  selector: 'app-cliente-otros-contactos',
  templateUrl: './cliente-otros-contactos.component.html',
  styleUrls: ['./cliente-otros-contactos.component.scss']
})
export class ClienteOtrosContactosComponent implements OnInit, OnChanges {
  @Input() contacto: Contacto;
  @Input() value;
  @Input() cliente: Cliente;
  @Input() submitted;

  public tiposTelefonos: ParametricWithIcon[];
  public tiposEmails: ParametricWithIcon[];
  public maxLengthTelefonos = 3;
  public maxLengthEmails = 3;
  private TelefonoCliente = new TelefonoCliente();
  private EmailCliente = new EmailCliente();
  mailPrincipal: any;
  @ViewChild('form', {static: false}) public clienteForm;

  constructor(private apiService: ApiService, private uniqueService: UniqueIdentifierService) { }

  ngOnInit() {
    this.getTiposTelefonos();
    this.getTiposEmails();
    for (const email of this.contacto.emails) {
      if (email.principal) {
        this.mailPrincipal = email.direccion;
        break;
      }
    }
  }

  ngOnChanges() {
    if (this.submitted) {
      this.clienteForm.submitted = true;
    }
  }

  async getTiposTelefonos() {
    await this.apiService.get<DataObject<ParametricWithIcon>>(ParametricWithIcon.path + 'tipos_telefonos/', null).toPromise().then(res => {
      this.tiposTelefonos = res.data;
    });
    for (const tipoTelefono of this.tiposTelefonos) {
      switch (tipoTelefono.id) {
        case this.TelefonoCliente.staticParticular:
          tipoTelefono.icono = 'phone';
          break;
        case this.TelefonoCliente.staticCelular:
          tipoTelefono.icono = 'phone_android';
          break;
        case this.TelefonoCliente.staticLaboral:
          tipoTelefono.icono = 'phonelink_ring';
          break;
        default:
          tipoTelefono.icono = 'phone';
      }
    }
  }
  async getTiposEmails() {
    await this.apiService.get<DataObject<ParametricWithIcon>>(ParametricWithIcon.path + 'tipos_email/', null).toPromise().then(res => {
      this.tiposEmails = res.data;
    });
    for (const tipoEmail of this.tiposEmails) {
      switch (tipoEmail.id) {
        case this.EmailCliente.staticPersonal:
          tipoEmail.icono = 'contact_mail';
          break;
        case this.EmailCliente.staticLaboral:
          tipoEmail.icono = 'email';
          break;
        default:
          tipoEmail.icono = 'email';
      }
    }
  }

  agregarTelefono() {
    this.contacto.telefonos.push(new Telefono('', 1));
  }

  agregarEmail() {
    this.contacto.emails.push(new Email(1, '', false));
  }

  eliminarTelefono(index: number) {
    this.contacto.telefonos.splice(index, 1);
    if (this.contacto.telefonos.length === 0) {
      this.agregarTelefono();
    }
  }
  eliminarEmail(index: number) {
    this.contacto.emails.splice(index, 1);
    if (this.contacto.emails.length === 0) {
      this.agregarEmail();
    }
  }

  mapTelefonoIdToDescription(tipoTelefonoId: number) {
    if (tipoTelefonoId == 1){
      return 'Particular';
    }
    if (tipoTelefonoId == 2){
      return 'Celular';
    }
    if (tipoTelefonoId == 3){
      return 'Laboral';
    }
    return 'Undefined';
  }

  mapEmailIdToDescription(tipoEmailId: number) {
    if (tipoEmailId == 1){
      return 'Personal';
    }
    if (tipoEmailId == 2){
      return 'Laboral';
    }
    return 'Undefined';
  }
  setMailPrincipal($event) {
   for (let mail of this.contacto.emails){
     if (mail.direccion == $event.value){
       this.mailPrincipal = mail.direccion;
       mail.principal = true;
     }else{
       mail.principal = false;
     }
   }
  }

  eliminarContacto() {
    this.cliente.contactos.splice(this.value, 1);
  }

  mapTelefonoIdToIcono(tipoTelefonoId: any) {
    if (tipoTelefonoId == '1'){
      return 'phone';
    }
    if (tipoTelefonoId == '2'){
      return 'phone_android';
    }
    if (tipoTelefonoId == '3'){
      return 'phonelink_ring';
    }
    return undefined;
  }

  mapEmailIdToIcono(tipoEmailId: any) {
    if (tipoEmailId == '1'){
      return 'contact_mail';
    }
    if (tipoEmailId == '2'){
      return 'email';
    }
    return 'Undefined';
  }
}
