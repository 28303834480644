import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ENTER } from '../../../../../node_modules/@angular/cdk/keycodes';
import { NgForm, FormControl } from '../../../../../node_modules/@angular/forms';
import { Comprobante } from '../../../models/comprobante.model';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { SnackService } from '../../../shared-services/snack.service';
import { BackendService } from '../../../core/backend.service';
import { FormValidatorService } from '../../../shared-services/form-validator.service';
import { MediaService } from '../../../shared-services/media.service';
import { NavigationService } from '../../../shared-services/navigation.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { Cliente } from '../../../models/cliente.model';
import { Sucursal } from '../../../models/sucursal.model';
import { User } from '../../../models/user.model';
import { DataObject } from '../../../models/dataObject.model';
import { TipoMoneda } from '../../../models/tipoMoneda.model';

@Component({
  selector: 'app-comprobante-add-edit',
  templateUrl: './comprobante-add-edit.component.html',
  styleUrls: ['./comprobante-add-edit.component.css']
})
export class ComprobanteAddEditComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER];

  @ViewChild('form', {static: false}) public comprobanteForm: NgForm;

  toppings = new FormControl();
  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef;

  validationFail = false;
  comprobante: Comprobante;

  messageTitle: string;

  isHD = false;
  entidades: Cliente[];
  sucursales: Sucursal[];
  tiposMoneda: TipoMoneda[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackService: SnackService,
    private backendService: BackendService,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private navigationService: NavigationService,
    private dialogService: DialogService,
  ) { }

  async ngOnInit() {

    this.route.data.subscribe(data => {

      this.comprobante = data.comprobante;
      this.comprobante.entidad = this.comprobante.entidad ? this.comprobante.entidad : new Cliente();
      this.comprobante.proveedor = this.comprobante.proveedor ? this.comprobante.proveedor : new Cliente();
      this.comprobante.codigo = this.comprobante.codigo ? this.comprobante.codigo : undefined;
      this.comprobante.tipo = this.comprobante.tipo ? this.comprobante.tipo : undefined;
      this.comprobante.sucursal = this.comprobante.sucursal ? this.comprobante.sucursal : new Sucursal();
      this.comprobante.numero = this.comprobante.numero ? this.comprobante.numero : undefined;
      this.comprobante.fechaCreacion = this.comprobante.fechaCreacion ? this.comprobante.fechaCreacion : undefined;
      this.comprobante.montoParcial = this.comprobante.montoParcial ? this.comprobante.montoParcial : undefined;
      this.comprobante.montoTotal = this.comprobante.montoTotal ? this.comprobante.montoTotal : undefined;
      this.comprobante.tipoMoneda = this.comprobante.tipoMoneda ? this.comprobante.tipoMoneda : new TipoMoneda();
      this.comprobante.usuarioInterno = this.comprobante.usuarioInterno ? this.comprobante.usuarioInterno : new User();
      this.comprobante.observaciones = this.comprobante.observaciones ? this.comprobante.observaciones : undefined;

    });

    this.messageTitle = this.comprobante.id ? 'Editar Comprobante' : 'Agregar Comprobante';

    this.mediaService.isHighDefinition.subscribe(isHD => this.isHD = isHD);

    await this.getEntidades();
    await this.getTiposMoneda();
    await this.getSucursales();
  }

  back() {
    this.navigationService.back();
  }

  async save() {
    if (this.validateForm()) {
      this.ConfirmSave();
    }
  }

  validateForm() {
    let validationsOK = false;
    if (this.formValidatorService.isFormValid(this.comprobanteForm)) {
      if (!this.validaEntidadProveedor()) {
        validationsOK = false;
        this.validationFail = true;
        this.snackService.error('El comprobante debe tener Cliente o Proveedor.');
      } else {
          validationsOK = true;
          this.validationFail = false;
      }
    } else {
      validationsOK = false;
      this.validationFail = true;
    }
    return validationsOK;
  }

  async ConfirmSave() {
    this.dialogService.confirm(this.messageTitle,
    '¿Está seguro que desea guardar el comprobante ' + this.comprobante.numero + '?')
    .then( res => {
      if (res) {
        this.saveComprobante(res);
      }
    });
  }

  async saveComprobante(res) {
    if (res) {

      this.snackService.spinner(true);
      if (this.comprobante.id) { // Update
        try {
          await await this.backendService.update(Comprobante.path + this.comprobante.id, this.comprobante);
          this.snackService.success('Comprobante editado');
          this.router.navigate(['/comprobante']);
        } catch (ex) {
          if (ex.error.message) {
            this.snackService.error(ex.error.message);
          } else if (ex.error.details) {
            this.snackService.error(ex.error.details);
          } else if (ex.error[0].details) {
            this.snackService.error(ex.error[0].details);
          } else if (ex.error[0].message) {
            this.snackService.error(ex.error[0].message);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      } else {
        try {
          await this.backendService.create(Comprobante.path, this.comprobante);
          this.snackService.success('Comprobante creado');
          this.router.navigate(['/comprobante']);
        } catch (ex) {
          if (ex.error.message) {
            this.snackService.error(ex.error.message);
          } else if (ex.error.details) {
            this.snackService.error(ex.error.details);
          } else if (ex.error[0].details) {
            this.snackService.error(ex.error[0].details);
          } else if (ex.error[0].message) {
            this.snackService.error(ex.error[0].message);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      }
    }
  }

  async getEntidades() {
    await this.backendService.get<any>(Cliente.path + 'all', null).toPromise().then(res => {
      this.entidades = res as Cliente[];
    });
  }

  async getSucursales() {
    await this.backendService.get<DataObject<Sucursal>>(Sucursal.path + 'all', null).toPromise().then(res => {
      this.sucursales = res.data;
    });
  }

  async getTiposMoneda() {
    await this.backendService.getRawPath<DataObject<TipoMoneda>>(TipoMoneda.path).toPromise().then(res => {
      this.tiposMoneda = res.data;
    });
  }

  vaciarProveedor() {
    this.comprobante.proveedor = new Cliente();
  }

  vaciarEntidad() {
    this.comprobante.entidad = new Cliente();
  }

  validaEntidadProveedor() {
    return (this.comprobante.entidad.id !== undefined || this.comprobante.proveedor.id !== undefined);
  }

}
