import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ENTER } from '../../../../../node_modules/@angular/cdk/keycodes';
import { NgForm, FormControl } from '../../../../../node_modules/@angular/forms';
import { MedioPago } from '../../../models/medio-pago.model';
import { TipoMoneda } from '../../../models/tipoMoneda.model';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { SnackService } from '../../../shared-services/snack.service';
import { BackendService } from '../../../core/backend.service';
import { FormValidatorService } from '../../../shared-services/form-validator.service';
import { MediaService } from '../../../shared-services/media.service';
import { NavigationService } from '../../../shared-services/navigation.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { DataObject } from '../../../models/dataObject.model';

@Component({
  selector: 'app-medio-pago-add-edit',
  templateUrl: './medio-pago-add-edit.component.html',
  styleUrls: ['./medio-pago-add-edit.component.css']
})
export class MedioPagoAddEditComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER];

  @ViewChild('form', {static: false}) public medioPagoForm: NgForm;

  toppings = new FormControl();
  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef;

  validationFail = false;
  medioPago: MedioPago;

  messageTitle: string;

  isHD = false;

  tiposMoneda: TipoMoneda[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackService: SnackService,
    private backendService: BackendService,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private navigationService: NavigationService,
    private dialogService: DialogService,
  ) { }

  async ngOnInit() {

    this.route.data.subscribe(data => {

      this.medioPago = data.medioPago;
      this.medioPago.detalle = this.medioPago.detalle ? this.medioPago.detalle : undefined;
      this.medioPago.tipoMoneda = this.medioPago.tipoMoneda ? this.medioPago.tipoMoneda : new TipoMoneda();
      this.medioPago.fungible = this.medioPago.fungible ? this.medioPago.fungible : false;
    });

    this.messageTitle = this.medioPago.id ? 'Editar Medio de Pago' : 'Agregar Medio de Pago';

    this.mediaService.isHighDefinition.subscribe(isHD => this.isHD = isHD);

    await this.getTiposMoneda();
  }

  back() {
    this.navigationService.back();
  }

  async save() {
    if (this.validateForm()) {
      this.ConfirmSave();
    }
  }

  validateForm() {
    let validationsOK = false;
    if (this.formValidatorService.isFormValid(this.medioPagoForm)) {
      validationsOK = true;
      this.validationFail = false;
    } else {
      validationsOK = false;
      this.validationFail = true;
    }

    return validationsOK;
  }

  async ConfirmSave() {
    this.dialogService.confirm(this.messageTitle,
    '¿Está seguro que desea guardar el medio de pago ' + this.medioPago.detalle + '?')
    .then( res => {
      if (res) {
        this.saveComprobante(res);
      }
    });
  }

  async saveComprobante(res) {
    if (res) {

      this.snackService.spinner(true);
      if (this.medioPago.id) { // Update
        try {
          await await this.backendService.update(MedioPago.path + this.medioPago.id, this.medioPago);
          this.snackService.success('Medio de pago editado');
          this.router.navigate(['/medio-pago']);
        } catch (ex) {
          if (ex.error[0]) {
            this.snackService.error(ex.error[0].details);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      } else {
        try {
          await this.backendService.create(MedioPago.path, this.medioPago);
          this.snackService.success('Medio de pago creado');
          this.router.navigate(['/medio-pago']);
        } catch (ex) {
          if (ex.error[0]) {
            this.snackService.error(ex.error[0].details);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      }
    }
  }

  async getTiposMoneda() {
    await this.backendService.getRawPath<DataObject<TipoMoneda>>(TipoMoneda.path).toPromise().then(res => {
      this.tiposMoneda = res.data;
    });
  }

}
