import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { Telefono } from '../../../models/telefono.model';

@Component({
  selector: 'app-usuario-phone-list',
  templateUrl: './usuario-phone-list.component.html',
  styleUrls: ['./usuario-phone-list.component.scss']
})
export class UsuarioPhoneListComponent implements OnInit, OnChanges {

  @Input() submitted;
  @Input() telefono: Telefono;
  @Input() required: boolean;

  @ViewChild('form', {static: false}) public clienteForm;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.submitted) {
      if (this.clienteForm) {
      this.clienteForm.submitted = true;
      }
    }
  }

}
