import { Injectable } from '@angular/core';
import { distinctUntilChanged, share, startWith, map } from 'rxjs/operators';
import { TdMediaService } from '@covalent/core';

@Injectable()
export class MediaService {
  isMobile = this.mediaService.registerQuery('gt-sm').pipe(
  distinctUntilChanged(),
  share(),
  startWith(this.mediaService.query('gt-sm')),
  map(x => !x));

  isHighDefinition = this.mediaService.registerQuery('xl').pipe(
  distinctUntilChanged(),
  share(),
  startWith(this.mediaService.query('xl')));
  constructor(
    private mediaService: TdMediaService,
  ) { }
}
