import { OperationalParametric } from './operationalParametric.model';

export class Imagen {

  static path = 'subir_imagen';
  file: File;
  path: string;
  id: number;
  name: string;
  archivoId: number;
  url?: any;
  color: OperationalParametric;
  colores: OperationalParametric[];
  tipoImagen: OperationalParametric;

  constructor(file: File) {
    this.file = file;
  }
}
