import { EnvServiceProvider } from './app/shared-services/env.service.provider';
import { AppModule } from './app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '../node_modules/@angular/core';

if (EnvServiceProvider.useFactory().production) {
  enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
