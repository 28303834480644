import { Component, OnInit, Inject } from '@angular/core';
import { Filters } from '../../../models/filters.model';
import { HasAction, Actions } from '../../../shared-components/generic-table/generic-table.component';
import { Subscription, Observer, Observable } from '../../../../../node_modules/rxjs';
import { CategoriaProducto } from '../../../models/categoria-producto.model';
import { PaginatedList } from '../../../models/paginatedList.model';
import { BackendService } from '../../../core/backend.service';
import { MatPaginatorIntl } from '../../../../../node_modules/@angular/material';
import { SnackService } from '../../../shared-services/snack.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { MediaService } from '../../../shared-services/media.service';
import { SecurityService } from '../../../security/security.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { switchMap, share } from '../../../../../node_modules/rxjs/operators';
import { EnvService } from '../../../shared-services/env.service';

@Component({
  selector: 'app-categoria-producto-table',
  templateUrl: './categoria-producto-table.component.html',
  styleUrls: ['./categoria-producto-table.component.css']
})
export class CategoriaProductoTableComponent implements OnInit {
  private backendUrl: string;

  public filters: Filters = {
    description: undefined,
    page: 0,
    size: 10,
  };

  columns = [
    { name: 'description',  label: 'Descripcion' }
  ];

  public showFilters = true;
  isMobile: boolean;

  public totalItems: number;

  public hasActions: HasAction;

  filtersActive = false;
  mostrarResumen = false;
  isLoading = false;

  filtersLoaded = false;
  subscriptions: Subscription[] = [];

  addText = 'Agregar Categoría';
  addUrl = '/categorias/agregar';
  searchTitle = 'Categoría';
  searchPlaceHolder = 'Ingrese el nombre del categoría que desea buscar';
  messageEmpty = 'No se encuentran categoría.';
  messageFilters = 'No se encuentran categoría que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_CATEGORIA_PRODUCTO';
  categoriaLista = [];
  atributoBusqueda: string = 'description';
  itemsList = this.backendService.newSubjectWithoutDistinct<Filters>().pipe(
    switchMap(filters => {
      const categoriasPromise = this.backendService.get<PaginatedList<CategoriaProducto>>(CategoriaProducto.path, filters).toPromise();
      return (async () => {
        this.isLoading = true;
        try {
          const categorias = await categoriasPromise;
          this.categoriaLista = categorias.data;
          return categorias;
        } catch (error) {
          return [];
        } finally {
          this.isLoading = false;
          this.filtersActive = !(this.filters.search === '' || this.filters.search === undefined);
        }
      })();
  })).pipe(share()) as Observer<Filters> & Observable<CategoriaProducto[]>;


  constructor(
    protected env: EnvService,
    private backendService: BackendService,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    private mediaService: MediaService,
    public securityService: SecurityService,
    private dialogService: DialogService,
  ) {
    this.backendUrl = env.api + '/backend';
  }

  ngOnInit() {

    this.showFilters = true;
    this.filtersLoaded = true;

    this.hasActions = new HasAction();

    this.hasActions.hasAction = true;
    this.hasActions.actions = [{ 'element': Actions.edit, 'permission': 'EDITAR_CATEGORIA_PRODUCTO'},
                              { 'element': Actions.delete, 'permission': 'ELIMINAR_CATEGORIA_PRODUCTO'}];

    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.showFilters = !this.isMobile;
    }));

    this.itemsList.subscribe(t => {
      this.isLoading = false;
      this.totalItems = t['totalElements'];
    });

    this.refreshItemsList();
  }

  refreshItemsList() {
    this.itemsList.next(this.getFilters());
  }

  getDefaultFilters() {
    this.filters = {
      description : undefined,
      page: 0,
      size: 10,
    };
  }

  getFilters() {
    return this.filters;
  }

  onEdit(role: any) {
    this.router.navigate(['/categorias/editar', role.id]);
  }

  async onDelete(categoria: any) {

    this.dialogService.confirm('Eliminar Categoria',
    '¿Está seguro que desea eliminar las categorias ' + categoria.description + '?')
    .then( res => {
      if (res) {
        this.deleteCategoria(res, categoria);
      }
    });

  }

  async deleteCategoria(res, categoria: CategoriaProducto) {

    if (res) {

      this.snackService.spinner(true);

      try {
        await this.backendService.delete(CategoriaProducto.path + categoria.id);
        this.snackService.success('Categorías de Productos eliminadas correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.refreshItemsList();
      }

    }

  }

}
