import { Component, OnInit } from '@angular/core';
import { Filters } from '../../models/filters.model';
import { EstadoCliente } from '../../models/estadoCliente.model';
import { TelefonoCliente } from '../../models/telefonoCliente.model';
import { EmailCliente } from '../../models/emailCliente.model';
import { Subcliente } from '../../models/subcliente.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '../../core/backend.service';
import { SnackService } from '../../shared-services/snack.service';
import { DialogService } from '../../shared-components/modals/dialog.service';
import { Cliente } from '../../models/cliente.model';
import { MotivoRechazo } from '../../models/motivoRechazo.model';
import { DataObject } from '../../models/dataObject.model';
import {FormControl} from "@angular/forms";
import {Telefono} from "../../models/telefono.model";
import {Email} from "../../models/email.model";

@Component({
  selector: 'app-cliente-details',
  templateUrl: './cliente-details.component.html',
  styleUrls: ['./cliente-details.component.scss']
})
export class ClienteDetailsComponent implements OnInit {

  private descargaDocumentacion = document.createElement('a');
  cliente: any;

  EstadoCliente = new EstadoCliente();
  private aprobFlag = false;
  private TelefonoCliente = new TelefonoCliente();
  private EmailCliente = new EmailCliente();
  private linkReenvio: {id: number, description: string};
  subclientes: Subcliente[] = [];
  private observacion: string;
  private motivosRechazo: MotivoRechazo[];
  selected = new FormControl(0);
  selectedForContactos = new FormControl(0);
  selectedCuentas = new FormControl(0);

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private backendService: BackendService,
    private snackService: SnackService,
    private dialogService: DialogService,
  ) { }

  async ngOnInit() {
    await this.route.data.subscribe(data => {
      this.cliente = data.cliente;
      this.cliente.motivosRechazoId = [];


      if (data.cliente.motivoRechazo) {
        this.observacion = data.cliente.motivoRechazo;
      } else {
        this.observacion = '';
      }
    });
    await this.getSubclientes();
    for (const telefono of this.cliente.telefonos) {
      switch (telefono.tipoTelefonoId) {
        case this.TelefonoCliente.staticParticular:
          telefono.icono = 'phone';
          telefono.description = this.TelefonoCliente.staticDescParticular;
          break;
        case this.TelefonoCliente.staticCelular:
          telefono.icono = 'phone_android';
          telefono.description = this.TelefonoCliente.staticDescCelular;
          break;
        case this.TelefonoCliente.staticLaboral:
          telefono.icono = 'phonelink_ring';
          telefono.description = this.TelefonoCliente.staticDescLaboral;
          break;
        default:
          telefono.icono = 'phone';
          telefono.description = 'Teléfono';
      }

    }
    for (const email of this.cliente.emails) {
      switch (email.tipoEmailId) {
        case this.EmailCliente.staticPersonal:
          email.icono = 'contact_mail';
          email.description = this.EmailCliente.staticDescPersonal;
          break;
        case this.EmailCliente.staticLaboral:
          email.icono = 'email';
          email.description = this.EmailCliente.staticDescLaboral;
          break;
        default:
          email.icono = 'email';
          email.description = 'Email';
      }
    }
  }

  async confirmRehabilitar() {

    this.dialogService.confirm('Rehabilitar Cliente',
    '¿Está seguro que desea rehabilitar el cliente ' + this.cliente.name + ' ' + this.cliente.lastname + '?')
    .then( res => {
      if (res) {
        this.rehabilitar(res);
      }
    });

  }

  async aprobarRechazarCliente() {
    this.snackService.spinner(true);
    try {
      this.cliente.motivoRechazo = 'Rechazado';
      this.cliente.motivosRechazoId = [];
      await this.backendService.create(Cliente.path + 'aprobar_rechazar/' + this.cliente.id, this.cliente ) ;
      this.router.navigate(['/clientes/detalles/' + this.cliente.id]);
      this.snackService.success('Cliente rechazado satisfactoriamente');
    } catch (ex) {
      if (ex[0]) {
        this.snackService.error(ex[0].details);
      } else if (ex.errors) {
        this.snackService.error(ex.errors[0].defaultMessage);
      } else {
        this.snackService.error(ex.message);
      }
    } finally {
      this.snackService.spinner(false);
    }
  }

  async rechazar(res) {
    if (res) {

      this.snackService.spinner(true);

      try {

        this.cliente.motivosRechazoId = res[0];
        this.cliente.observacion = res[1];
        await this.backendService.create(Cliente.path + 'rechazar/' + this.cliente.id, this.cliente);
        this.snackService.success('Cliente rechazado');
        this.cliente.estado.id = 6;
        this.cliente.enable = false;
        this.cliente.estado.description = 'Rechazado';
        this.cliente.motivoRechazo = res[1];
        this.cliente.motivosRechazo = res[2];
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
      }
    }
  }

  async rehabilitar(res) {
    if (res) {

      this.snackService.spinner(true);

      try {
        await this.backendService.create(Cliente.path + 'rehabilitar/' + this.cliente.id, null);
        this.snackService.success('Cliente rehabilitado');
        this.cliente.enable = true;
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
      }
    }
  }

  async confirmRestablecer(id) {
    this.dialogService.confirm('Restablecer Contraseña',
    '¿Está seguro que desea restablecer la contraseña del cliente?')
    .then( res => {
      if (res) {
        this.restablecer(res, id);
      }
    });
  }

  async confirmReenvioEmail() {
    this.dialogService.confirm('Reenviar Email de Confirmación',
    '¿Está seguro que desea reenviar el email de confirmación al cliente?')
    .then( res => {
      if (res) {
        this.reenviarEmail(res);
      }
    });
  }

  editar(id: number) {
    this.router.navigate(['/clientes/editar', id]);
  }

  editarSubcliente(id: number) {
    this.router.navigate(['/clientes/editar-usuario/' , id]);
  }

  async getMotivosRechazo() {
    await this.backendService.get<DataObject<MotivoRechazo>>(Cliente.path + 'motivosrechazos/all', null).toPromise().then(res => {
      this.motivosRechazo = res.data;
    });
  }

  async getSubclientes() {
    await this.backendService.get<DataObject<Subcliente>>(Subcliente.path + 'all/' + this.cliente.id, null).toPromise().then(res => {
       this.subclientes = res.data;
    });
  }

  private openWindow(data) {
    try {
      const blob = new Blob([data], { type: 'application/zip' });
      this.descargaDocumentacion.href = window.URL.createObjectURL(blob);
      this.descargaDocumentacion.download = this.cliente.name || this.cliente.lastname ?
        this.cliente.name + '_' + this.cliente.lastname + '.zip' : this.cliente.emails[0].direccion + '.zip';
      this.descargaDocumentacion.click();
    } catch (ex) {
      console.log(ex);
    }
  }

  async restablecer(res, id) {
    if (res) {

      this.snackService.spinner(true);
      try {
        await this.backendService.update('/subclientes/reset_password?id=' + id, Cliente);
        this.snackService.success('Contraseña reestablecida correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
      }
    }
  }

  async reenviarEmail(res) {
    if (res) {

      this.snackService.spinner(true);
      try {
        await this.backendService.update('/clientes/resend_email?id=' + this.cliente.id, Cliente);
        this.snackService.success('Email de confirmación reenviado correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
      }
    }
  }

  validateAprob() {
    if (!this.cliente.montoCompraActual && !this.aprobFlag) {
      this.snackService.error('Primero debe asignarle un monto límite de compra al cliente');
      return false;
    }
    if (this.cliente.estado.id !== EstadoCliente.PendAprobacion) {
      this.snackService.error('El cliente no se encuentra en condiciones para ser aprobado');
      return false;
    }
    return true;
  }

  async aprobar(res) {
    if (res) {

      this.snackService.spinner(true);

      try {
        await this.backendService.create(Cliente.path + 'aprobar/' + this.cliente.id, null);
        this.snackService.success('Cliente aprobado');
        this.cliente.estado.id = 4;
        this.cliente.estado.description = 'Aprobado';
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
      }
    }
  }

  async confirmLinkReenvio() {
    this.snackService.spinner(true);

    await this.backendService.get<any>('/clientes/get_link_reenvio?id=' + this.cliente.id).toPromise()
    .then(res  => {
      this.linkReenvio = res as {id: number, description: string};
    });

    this.snackService.spinner(false);
    this.dialogService.getLinkReenvio(this.linkReenvio.description);
  }

  onAddUsuario(id) {
    this.router.navigate(['/clientes/agregar-usuario/' + id]);
  }

  onEditMontoMax(id) {
    this.router.navigate(['/clientes/' + id + '/monto-max-usuarios']);
  }

  async onSubclienteEnableChange(subcliente: Subcliente, event: any) {
    this.snackService.spinner(true);
    console.log(event, subcliente.enable);

    try {
      await this.backendService.update(Subcliente.path + 'actualizar-estado/' + subcliente.id, subcliente);
      this.snackService.success('Subcliente modificado');
    } catch (ex) {
      console.log(ex);
      subcliente.enable = !subcliente.enable;
      this.snackService.error('No se pudo modificar el estado del subcliente');
    } finally {
      this.snackService.spinner(false);
    }
  }

  mapEmailIdToDescription(tipoEmailId: string) {
    if (tipoEmailId == '1'){
      return 'Personal';
    }
    if (tipoEmailId == '2'){
      return 'Laboral';
    }
    return 'Undefined';
  }

  mapEmailIdToIcono(tipoEmailId: string) {
    if (tipoEmailId == '1'){
      return 'contact_mail';
    }
    if (tipoEmailId == '2'){
      return 'email';
    }
    return 'Undefined';
  }


  mapTelefonoIdToDescription(tipoTelefonoId: string) {
    if (tipoTelefonoId == '1'){
      return 'Particular';
    }
    if (tipoTelefonoId == '2'){
      return 'Celular';
    }
    if (tipoTelefonoId == '3'){
      return 'Laboral';
    }
    return undefined;
  }

  mapTelefonoIdToIcono(tipoTelefonoId: string) {
    if (tipoTelefonoId == '1'){
      return 'phone';
    }
    if (tipoTelefonoId == '2'){
      return 'phone_android';
    }
    if (tipoTelefonoId == '3'){
      return 'phonelink_ring';
    }
    return undefined;
  }

  getIcon(id: number) {
    if(id == 1){
      return 'send';
    }
    if(id == 2){
      return 'business_center';
    }
    if(id == 3){
      return 'homel';
    }
    return '';
  }

  cuentaCorriente(id) {
    this.router.navigate(['/operaciones', id]);
  }

  getMailNoVacios(emails: Email[]) {
    return emails.filter(email => email.direccion !== '' );
  }

  getTelefonosNoVacios(telefonos: Telefono[]) {
    return telefonos.filter(telefono => telefono.numero !== '' );
  }
}
