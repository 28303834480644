import { Injectable } from '../../../../node_modules/@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '../../../../node_modules/@angular/router';
import { Cotizacion } from '../../models/cotizacion.model';
import { SnackService } from '../../shared-services/snack.service';
import { BackendService } from '../../core/backend.service';

@Injectable()
export class CotizacionResolver implements Resolve<Cotizacion> {
  constructor(
    protected router: Router,
    protected snackService: SnackService,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id;

      return await this.backendService.get<Cotizacion>(Cotizacion.path + id).toPromise();
    } catch (error) {
      this.snackService.error('No puede modificar cotizaciones eliminadas');
      this.router.navigate(['/cotizacion']);
    }
  }
}
