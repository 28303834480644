import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from '../../environments/environment';
import { TranslateService } from './translate.service';

import { TRANSLATION_PROVIDERS } from './translations';
import { BackendService } from './backend.service';

import {
  StorageProvider,
  HistoryProvider,
  LocationProvider,
} from './browser-providers';
import { ApiService } from './api.service';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '../app-routing.module';

const modules = [
  HttpClientModule,
  FormsModule,
  RouterModule,
  AppRoutingModule,
  ReactiveFormsModule,
];

@NgModule({
  imports: modules,
  exports: modules,
  providers: [
    { provide: StorageProvider, useValue: localStorage },
    { provide: HistoryProvider, useValue: history },
    { provide: LocationProvider, useValue: location },
    TranslateService,
    TRANSLATION_PROVIDERS,
    ApiService,
    BackendService,
  ]
})
export class CoreModule { }
