import { Injectable } from '../../../../node_modules/@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '../../../../node_modules/@angular/router';
import { CategoriaProducto } from '../../models/categoria-producto.model';
import { SnackService } from '../../shared-services/snack.service';
import { BackendService } from '../../core/backend.service';


@Injectable()
export class CategoriaProductoResolver implements Resolve<CategoriaProducto> {
  constructor(
    protected router: Router,
    protected snackService: SnackService,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id || 'agregar';

      if (id === 'agregar') {
        return new CategoriaProducto();
      }
      return await this.backendService.get<CategoriaProducto>(CategoriaProducto.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/categorias']);
    }
  }
}
