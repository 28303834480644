import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {  SnackService, SnackServiceMsgComponent,
  SnackServiceSpinnerComponent,
  SnackServicePermissionMessageComponent } from './snack.service';
import { FormValidatorService } from './form-validator.service';
import { VendorModule } from '../core/vendor.module';
import { MediaService } from './media.service';
import { ScrollComunicationService } from './scroll-comunication.service';
import { NavigationService } from './navigation.service';
import { TdMediaService } from '@covalent/core';
import { UpdateEventService } from './update-event.service';
import { StrengthPasswordService } from './strength-password.service';


const imports = [
  CommonModule,
  VendorModule
];

const declarations = [
  SnackServiceMsgComponent,
  SnackServiceSpinnerComponent,
  SnackServicePermissionMessageComponent,
];

@NgModule({
  imports, declarations,
  providers: [
    FormValidatorService,
    SnackService,
    NavigationService,
    MediaService,
    ScrollComunicationService,
    UpdateEventService,
    StrengthPasswordService
  ],
  exports: [
    ...imports,
    ...declarations,
  ],
  entryComponents: [
    SnackServiceMsgComponent,
  ],
})
export class SharedServicesModule { }
