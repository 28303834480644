import { Component, OnInit } from '@angular/core';
import { Filters } from '../../../models/filters.model';
import { HasAction, Actions } from '../../../shared-components/generic-table/generic-table.component';
import { Subscription, Observer, Observable } from '../../../../../node_modules/rxjs';
import { Talle } from '../../../models/talle.model';
import { PaginatedList } from '../../../models/paginatedList.model';
import { BackendService } from '../../../core/backend.service';
import { SnackService } from '../../../shared-services/snack.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { MediaService } from '../../../shared-services/media.service';
import { SecurityService } from '../../../security/security.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { switchMap, share } from '../../../../../node_modules/rxjs/operators';

@Component({
  selector: 'app-talle-table',
  templateUrl: './talle-table.component.html',
  styleUrls: ['./talle-table.component.css']
})
export class TalleTableComponent implements OnInit {

  // @Output() subtitle = 'Listado';

  public filters: Filters = {
    description: undefined,
    page: 0,
    size: 10,
    sort: 'orden'
  };

  columns = [
    { name: 'orden',  label: 'Orden' },
    { name: 'description',  label: 'Descripcion' }
  ];

  public showFilters = true;
  isMobile: boolean;

  public totalItems: number;

  public hasActions: HasAction;

  filtersActive = false;
  mostrarResumen = false;
  isLoading = false;

  filtersLoaded = false;
  subscriptions: Subscription[] = [];

  addText = 'Agregar Talle';
  addUrl = '/talles/agregar';
  searchTitle = 'Talle';
  searchPlaceHolder = 'Ingrese el nombre del talle que desea buscar';
  messageEmpty = 'No se encuentran talle.';
  messageFilters = 'No se encuentran talles que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_TALLE';
  tallesLista = [];
  atributoBusqueda: string = 'description';
  itemsList = this.backendService.newSubjectWithoutDistinct<Filters>()
  .pipe(switchMap(filters => {
    const tallesPromise = this.backendService.get<PaginatedList<Talle>>(Talle.path, filters).toPromise();
    return (async () => {
      this.isLoading = true;
      try {
        const talles = await tallesPromise;
        this.tallesLista = talles.data;
        return talles;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
        this.filtersActive = !(this.filters.search === '' || this.filters.search === undefined);
      }
    })();
  })).pipe(share()) as Observer<Filters> & Observable<Talle[]>;

  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    private mediaService: MediaService,
    public securityService: SecurityService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.showFilters = true;
    this.filtersLoaded = true;

    this.hasActions = new HasAction();

    this.hasActions.hasAction = true;
    this.hasActions.actions = [{ 'element': Actions.edit, 'permission': 'EDITAR_SUGERENCIA'},
                              { 'element': Actions.delete, 'permission': 'ELIMINAR_SUGERENCIA'}];

    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.showFilters = !this.isMobile;
    }));

    this.itemsList.subscribe(t => {
      this.isLoading = false;
      this.totalItems = t['totalElements'];
    });

    this.refreshItemsList();
  }


  refreshItemsList() {
    this.itemsList.next(this.getFilters());
  }

  getDefaultFilters() {
    this.filters = {
      description : undefined,
      sort: 'orden',
      page: 0,
      size: 10,
    };
  }

  getFilters() {
    return this.filters;
  }

  onEdit(role: Talle) {
    this.router.navigate(['/talles/editar', role.id]);
  }


  async onDelete(talle: Talle) {

    this.dialogService.confirm('Eliminar talle',
    '¿Está seguro que desea eliminar el talle ' + talle.description + '?')
    .then( res => {
      if (res) {
        this.deleteSugerencia(res, talle);
      }
    });

  }

  async deleteSugerencia(res, talle: Talle) {

    if (res) {

      this.snackService.spinner(true);

      try {
        await this.backendService.delete(Talle.path + talle.id);
        this.snackService.success('Talles eliminados correctamente');
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
        this.refreshItemsList();
      }

    }

  }

}
