import { NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';

import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  MatChipsModule,
  MatIconModule,
  MatIconRegistry,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatNativeDateModule, DateAdapter, NativeDateAdapter,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatMenuModule,
  MatToolbarModule,
  MatSidenavModule,
  MatCardModule,
  MatTabsModule,
  MatExpansionModule,
  MatListModule,
  MatGridListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatBadgeModule,
  MatStepperModule
} from '@angular/material';

import {
  CovalentDataTableModule,
  CovalentMediaModule,
  CovalentPagingModule,
  CovalentSearchModule,
  CovalentExpansionPanelModule,
  CovalentNotificationsModule,
  CovalentMenuModule,
  CovalentStepsModule,
} from '@covalent/core';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { MyDatePickerModule } from 'mydatepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const modules = [
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  MatChipsModule,
  MatIconModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatMenuModule,
  MatToolbarModule,
  MatSidenavModule,
  MatCardModule,
  MatTabsModule,
  MatExpansionModule,
  MatListModule,
  MatGridListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  MatDialogModule,
  CovalentDataTableModule,
  CovalentMediaModule,
  CovalentPagingModule,
  CovalentSearchModule,
  CovalentExpansionPanelModule,
  CovalentNotificationsModule,
  CovalentMenuModule,
  CovalentStepsModule,
  PasswordStrengthBarModule,
  MyDatePickerModule,
  MatBadgeModule,
  MatStepperModule,
  NgxMatSelectSearchModule
];

@NgModule({
  imports: modules,
  exports: modules,
  providers: [
    ]
})

export class VendorModule {
  constructor(
    icons: MatIconRegistry,
    sanitizer: DomSanitizer,
    dateAdapter: DateAdapter<NativeDateAdapter>,
  ) {
    dateAdapter.setLocale('es' || navigator.language);
    icons.registerFontClassAlias('fontawesome', 'fa');
    function add(name, url) {
      icons.addSvgIcon(name, sanitizer.bypassSecurityTrustResourceUrl(url));
    }
    add('cambiemos', 'assets/images/logo_color.png');

  }
}
