import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '../../../../node_modules/@angular/router';
import { MenuActiveService } from '../../shared-services/menu-active.service';

@Component({
  selector: 'app-medios-pago',
  templateUrl: './medios-pago.component.html',
  styleUrls: ['./medios-pago.component.css']
})
export class MediosPagoComponent implements OnInit {

  @Output() changeUrl = new EventEmitter<string>();

  constructor(
    private router: Router,
    private menuActiveService: MenuActiveService
  ) { }

  ngOnInit() {
    this.menuActiveService.onGetSubject(this.router.url);
  }
}
