import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchInputComponent } from '../../shared-components/search-input/search-input.component';
import { Subject, Subscription, Observer, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '../../core/backend.service';
import { MatPaginatorIntl, MatIconRegistry } from '@angular/material';
import { UpdateEventService } from '../../shared-services/update-event.service';
import { MediaService } from '../../shared-services/media.service';
import { NavigationService } from '../../shared-services/navigation.service';
import { EstadoCliente } from '../../models/estadoCliente.model';
import { Cliente } from '../../models/cliente.model';
import { share, switchMap } from 'rxjs/operators';
import { PaginatedList } from '../../models/paginatedList.model';
import { SecurityService } from '../../security/security.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Filters } from '../../models/filters.model';
import { ISelectedChip } from '../cliente-filters/cliente-filters.component';

enum ClienteEstadoLastLogin {
  ultimaSemana = 1,
  masUnaSemana = 2,
  masDosSemanas = 3
}

@Component({
  selector: 'app-cliente-table',
  templateUrl: './cliente-table.component.html',
  styleUrls: ['./cliente-table.component.scss']
})
export class ClienteTableComponent implements OnInit {

  public EstadoCliente = new EstadoCliente();
  selectedChips: ISelectedChip[] = [];
  private nameSearch = '';

  public filters: Filters = {
    name: undefined,
    email: undefined,
    page: 0,
    size: 50,
    sort: 'created,desc'
  };

  public showFilters = false;
  isMobile: boolean;

  @ViewChild(SearchInputComponent, {static: false})
  private searchComponent: SearchInputComponent;

  public totalItems: number;

  roleFiltersActive = false;
  mostrarResumen = false;
  isLoading = false;

  subscriptions: Subscription[] = [];
  addText = 'Agregar Cliente';
  addUrl = '/clientes/agregar';
  searchTitle = 'Clientes';
  searchPlaceHolder = 'Ingrese el nombre del cliente que desea buscar';
  messageEmpty = 'No se encuentran clientes.';
  messageFilters = 'No se encuentran clientes que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_CLIENTE';
  clientesLista = [];
  atributoBusqueda: string = 'textsearch';

  clientesList = this.backendService.newSubjectWithoutDistinct<Filters>().pipe(
  switchMap(filters => {
    const usersPromise = this.backendService.get<PaginatedList<Cliente>>(Cliente.path, filters).toPromise();
    return (async () => {
      this.isLoading = true;
      try {
        const permissions = await usersPromise;
        this.clientesLista = permissions.data;
        return permissions;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
        this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
      }
    })();
  })).pipe(share()) as Observer<Filters> & Observable<Cliente[]>;

  constructor(
    protected route: ActivatedRoute,
    private backendService: BackendService,
    protected router: Router,
    protected updateEventService: UpdateEventService,
    private mediaService: MediaService,
    protected navigation: NavigationService,
    public securityService: SecurityService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      'pend_revision_act_doc',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/sharp-notification_important.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pend_aprobacion_email',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/sharp-unsubscribe.svg')
    );
  }

  ngOnInit() {

    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
    }));

    this.refresList();
  }

  refresList() {
    this.clientesList.next(this.getFilters());
    this.showFilters = false;
  }

  getDefaultFilters() {
    this.filters = {
      name : undefined,
      email : undefined,
      page: 0,
      size: 50,
    };
  }

  getFilters() {
    return this.filters;
  }

  detallesCliente(id: number) {
    this.router.navigate(['/clientes/detalles', id]);
  }

  onVerAcuerdos(cliente: Cliente) {
    this.router.navigate(['/clientes/ver-acuerdos', cliente.id]);
  }

  async onFiltersChanged(event) {
    this.selectedChips = event;
    this.filters.search = await this.buildSearch(this.selectedChips);
    this.refresList();
  }

  buildSearch(chips: ISelectedChip[]) {
    let searchString: string;
    searchString = this.buildSearchFilter(chips.filter(x => x.categoryName === 'Última conexión'), 'ultimoLogin', true);
    searchString += this.buildSearchFilter(chips.filter(x => x.categoryName === 'Último pedido'), 'ultimoPedido', true);
    if (this.nameSearch) {
      searchString += 'textsearch:' + this.nameSearch + ',';
    }

    return searchString;
  }

  buildSearchFilter(chips: ISelectedChip[], categoryName: string, filterLike: boolean, operator: string = ':') {
    if (chips.length > 0) {
      let search = filterLike ? categoryName + operator : categoryName + operator + '[';
      let flag = false;
      for (const chip of chips) {
        if (flag) {
          search += ',';
        }
        search += chip.tagId;
        flag = true;
      }
      return filterLike ? search += ',' : search += '],';
    }
    return '';
  }

  removeChip(event) {
    this.refresList();
  }

  cuentaCorriente(id) {
    this.router.navigate(['/operaciones', id]);
  }

}
