import { Component, OnInit, ViewChild } from '@angular/core';
import { Filters } from '../../../models/filters.model';
import { Subscription, Observer, Observable } from '../../../../../node_modules/rxjs';
import { Cotizacion } from '../../../models/cotizacion.model';
import { PaginatedList } from '../../../models/paginatedList.model';
import { TipoConfiguracion } from '../../../models/tipo-configuracion.model';
import { switchMap, share } from '../../../../../node_modules/rxjs/operators';
import { BackendService } from '../../../core/backend.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { MediaService } from '../../../shared-services/media.service';
import { SecurityService } from '../../../security/security.service';
import { MatPaginatorIntl } from '../../../../../node_modules/@angular/material';
import { Page } from '../../../models/page.model';
import { SearchInputComponent } from '../../../shared-components/search-input/search-input.component';

@Component({
  selector: 'app-cotizacion-table',
  templateUrl: './cotizacion-table.component.html',
  styleUrls: ['./cotizacion-table.component.css']
})
export class CotizacionTableComponent implements OnInit {

  private filters: Filters = {
    page: 0,
    size: 50,
    sort: 'created,desc'
  };

  @ViewChild(SearchInputComponent, {static: false})
  private searchComponent: SearchInputComponent;

  public showFilters = true;
  isMobile: boolean;

  public totalItems: number;

  roleFiltersActive = false;
  mostrarResumen = false;
  isLoading = false;

  filtersLoaded = false;
  subscriptions: Subscription[] = [];

  addText = 'Agregar Cotizacion';
  addUrl = '';
  searchTitle = 'Cotizacion';
  searchPlaceHolder = '';
  messageEmpty = '';
  messageFilters = '';
  botonAgregar = false;
  permisoCrear = '';
  cotizacionLista = [];

  cotizacionList = this.backendService.newSubjectWithoutDistinct<Filters>()
  .pipe(switchMap(filters => {
    const usersPromise = this.backendService.get<PaginatedList<Cotizacion>>(TipoConfiguracion.path + 'cotizacion' , filters).toPromise();
    return (async () => {
      this.isLoading = true;
      try {
        const permissions = await usersPromise;
        this.cotizacionLista = permissions.data;
        return permissions;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
        this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
      }
    })();
  })).pipe(share()) as Observer<Filters> & Observable<Cotizacion[]>;

  constructor(
    private backendService: BackendService,
    public router: Router,
    private paginatorsLabels: MatPaginatorIntl,
    protected updateEventService: UpdateEventService,
    private mediaService: MediaService,
    public securityService: SecurityService,
  ) {
  }

  ngOnInit() {

    this.showFilters = true;
    this.filtersLoaded = true;

    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.showFilters = !this.isMobile;
    }));

    this.paginatorsLabels.itemsPerPageLabel = 'Filas por página:';
    this.cotizacionList.subscribe(t => {
      this.isLoading = false;
      this.totalItems = t['totalElements'];
    });

    this.refreshRoleList();
  }

  refreshRoleList() {
    this.cotizacionList.next(this.getFilters());
  }

  getDefaultFilters() {
    this.filters = {
      page: 0,
      size: 50,
      sort: 'created,desc'
    };
  }

  getFilters() {
    return this.filters;
  }

  onChange(event: any) {
    this.refreshRoleList();
  }

  detallesCotizacion(id: number) {
    this.router.navigate(['/cotizacion', id]);
  }
    // Seteo de filtros
    toggleFilters() {
      this.showFilters = !this.showFilters;
    }

    onPageChange(page: Page) {
      this.filters.page = page.pageIndex;
      this.filters.size = page.pageSize;
      this.refreshRoleList();
    }

    onSearch(searchText) {
      this.filters.search = 'name:' + searchText;
      this.refreshRoleList();
    }

    clearSearch() {
      this.searchComponent.searchBox.value = '';
      this.refreshRoleList();
    }

    getData(response: Response) {
      const data = response.json();
      if (response.ok) { return data; }
      throw data['hydra:description'] || data['message'];
    }

    displayFilters(boolean: boolean) {
      this.showFilters = boolean;
    }

    onAdd(url: string) {
      this.router.navigate([url]);
    }

    onFiltersChanged(event) {
      this.refreshRoleList();
    }

    removeChip(event) {
      this.refreshRoleList();
    }

}
