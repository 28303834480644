import { Sugerencia } from './sugerencia.model';
import { SubcategoriaProducto } from './subcategoria-producto.model';

export class CategoriaProducto {

  static path = '/productos/categorias/';

  static codInterna = 'SMARTOFFICE';

  id: number;
  description: string;
  imagen: string;
  origen: string;
  sugerencias?: Sugerencia[];
  subcategorias?: SubcategoriaProducto[];

  get sugerenciaString() {
    let s = '';
    this.sugerencias.forEach(v => s += v.description + ', ');
    s = s.length > 100 ? s.substr(0, 100) + '[...]' : s.substr(0, s.length - 2);
    return s;
  }

  get subcategoriaString() {
    let s = '';
    this.subcategorias.forEach(v => s += v.description + ', ');
    s = s.length > 100 ? s.substr(0, 100) + '[...]' : s.substr(0, s.length - 2);
    return s;
  }

  interna() {
    return this.origen === CategoriaProducto.codInterna;
  }
}
