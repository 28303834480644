import { Component, OnInit, ViewChild, AfterViewChecked, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { NgForm, FormControl } from '@angular/forms';
import { User } from '../../../models/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackService } from '../../../shared-services/snack.service';
import { BackendService } from '../../../core/backend.service';
import { FormValidatorService } from '../../../shared-services/form-validator.service';
import { MediaService } from '../../../shared-services/media.service';
import { NavigationService } from '../../../shared-services/navigation.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { Role } from '../../../models/role.model';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { OperationalParametric } from '../../../models/operationalParametric.model';
import { Telefono } from '../../../models/telefono.model';
import { MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { Foto } from '../../../models/foto.model';
import { TelefonoCliente } from '../../../models/telefonoCliente.model';
import { Genero } from '../../../models/genero.model';
import { DataObject } from '../../../models/dataObject.model';
import { Sucursal } from '../../../models/sucursal.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-add-edit',
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.css']
})
export class UserAddEditComponent implements OnInit, OnDestroy, AfterViewChecked {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  perfil = false;
  separatorKeysCodes: number[] = [ENTER];

  @ViewChild('form', {static: false}) public userForm: NgForm;

  private TelefonoCliente = new TelefonoCliente();

  validationFail = false;
  user: User;
  sucursales: Sucursal[];
  messageTitle: string;
  rolAGuardar;

  isHD = false;
  foto = false;
  guardo = false;
  phoneTouched = false;

  public sucursalFilterCtrl: FormControl = new FormControl();
  public filteredSucursales: ReplaySubject<Sucursal[]> = new ReplaySubject<Sucursal[]>(1);
  protected _onDestroySucursal = new Subject<void>();

  public rolFilterCtrl: FormControl = new FormControl();
  public filteredRoles: ReplaySubject<Role[]> = new ReplaySubject<Role[]>(1);
  protected _onDestroyRole = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackService: SnackService,
    private backendService: BackendService,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private navigationService: NavigationService,
    private dialogService: DialogService,
    private changeDetector: ChangeDetectorRef
  ) {}

  _selectedRole: Role[] = [];

  roleList: Role[];
  generoList: Genero[];
  hide1 = false;
  hide2 = false;

  async ngOnInit() {
    this.route.data.subscribe(data => {

      this.user = data.user;
      this.user.genero = this.user.genero ? this.user.genero : new OperationalParametric();
      this.user.password = this.user && this.user.password ? this.user.password : null;
      this.user.roles = this.user.id ? this.user.roles : [];
      this.user.fotos = this.user.fotos ? this.user.fotos : [];
      this.user.sucursales = this.user.sucursales ? this.user.sucursales : [];
      this.rolAGuardar = this.user.roles.length > 0 ? this.user.roles[0] : undefined;

      if (data.title === 'Perfil') {
        this.perfil = true;
      }

      if (this.user.emails) {
        for (const email of this.user.emails) {
          if (email.principal) {
            this.user.email_direccion = email.direccion;
            break;
          }
        }
      }
      if (!this.user.telefonos || this.user.telefonos.length === 0) {
        this.user.telefonos = [];
        this.user.telefonos.push(new Telefono('', 1));
      } else {
        for (const telefono of this.user.telefonos) {
          switch (telefono.tipoTelefonoId) {
            case this.TelefonoCliente.staticParticular:
              telefono.icono = 'phone';
              break;
            case this.TelefonoCliente.staticCelular:
              telefono.icono = 'phone_android';
              break;
            case this.TelefonoCliente.staticLaboral:
              telefono.icono = 'phonelink_ring';
              break;
            default:
              telefono.icono = 'phone';
          }
        }
      }

    });

    await this.getSucursales();

    this.sucursalFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroySucursal))
    .subscribe(() => {
      this.filterSucursales();
  });

    this.rolFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroyRole))
    .subscribe(() => {
      this.filterRoles();
  });

    this.messageTitle = this.user.id ? 'Edición de usuario' : 'Alta de usuario';

    await this.backendService.get<DataObject<Role>>(Role.path + 'all').toPromise().then(res => {
      this.roleList = res.data;
    });
    await this.backendService.getRawPath<DataObject<Genero>>(Genero.path).toPromise().then(res => {
      this.generoList = res.data;
    });
    await this.filteredSucursales.next(this.sucursales.slice());
    await this.filteredRoles.next(this.roleList.slice());
    this.filter();
    this.mediaService.isHighDefinition.subscribe(isHD => this.isHD = isHD);
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this._onDestroySucursal.next();
    this._onDestroySucursal.complete();
    this._onDestroyRole.next();
    this._onDestroyRole.complete();
  }

  protected filterSucursales() {
    if (!this.sucursales) {
      return;
    }
    let search = this.sucursalFilterCtrl.value;
    if (!search) {
      this.filteredSucursales.next(this.sucursales.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredSucursales.next(
      this.sucursales.filter(suc => suc.description.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterRoles() {
    if (!this.roleList) {
      return;
    }
    let search = this.rolFilterCtrl.value;
    if (!search) {
      this.filteredRoles.next(this.roleList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredRoles.next(
      this.roleList.filter(rol => rol.name.toLowerCase().indexOf(search) > -1)
    );
  }

  onChangeRol() {
    this.user.roles = [];
    this.user.roles.push(this.rolAGuardar);
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      return c1.id === c2.id;
    } else {return false; }
  }

  subioFoto() {
    this.foto = true;
  }

  /*
  async getFotoById() {
    this.fotos = [];
    await this.backendService
      .get(Documentacion, Cliente.path + Documentacion.path, null).toPromise().then(res => {
        Array.from(res).forEach(doc => {
          var documentacion = new OperationalDocumentacion();
          Object.assign(documentacion, doc);
          this.documentacionRespaldatoria.push(documentacion);
        });
        for (const documentacion of this.documentacionRespaldatoria) {
          if (documentacion.documentos.length === 0) {
            documentacion.documentos.push(new Documento(undefined));
          }
        };
      });
  }
*/

  back() {
    this.navigationService.back();
  }

  async save() {
    this.guardo = true;
    if (this.validateForm()) {
      this.ConfirmSave();
    }
  }

  validateForm() {
    let validationsOK = false;
    this.phoneTouched = true;

    if (this.formValidatorService.isFormValid(this.userForm) && (this.foto || this.user.fotos[0].id !== undefined) === true &&
    this.user.telefonos[0].numero.length > 0 && this.user.roles !== []) {

      if (!this.user.id && this.user.password !== this.user.retryPassword) {
        this.snackService.error('Las claves no coinciden');

        validationsOK = false;
        this.validationFail = true;

        return validationsOK;
      }
      if (this.containDuplicates(this.user.telefonos.map(telefono => telefono.numero))) {
        this.snackService.error('Los números de teléfono deben ser distintos.');

        validationsOK = false;
        this.validationFail = true;

        return validationsOK;
      }
      validationsOK = true;
      this.validationFail = false;

    } else {
      validationsOK = false;
      this.validationFail = true;
      this.snackService.error('Hay datos inválidos. Por favor revise los campos marcados en rojo.');
    }

    return validationsOK;
  }

  remove(role: Role): void {

    const index = this.user.roles.indexOf(role);
    if (index >= 0) {
      this.user.roles.splice(index, 1);
    }
    this.filter();
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    const index = this.user.roles.indexOf(event.option.value);

    if (index >= 0) {
      return;
    }
    this.user.roles.push(event.option.value);
    // this.renderer.invokeElementMethod(this.fruitInput.nativeElement, 'blur', []);
    this.filter();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
  }

  async ConfirmSave() {
    this.dialogService.confirm(this.messageTitle,
    '¿Está seguro que desea guardar el usuario ' + this.user.name + '?')
    .then( res => {
      if (res) {
        this.saveRole(res);
      }
    });
  }

  async saveRole(res) {
    if (res) {

      this.snackService.spinner(true);
      if (this.user.id) { // Update
        try {
          await await this.backendService.update(User.path + this.user.id, this.user);
          this.saveFotos(this.user.id);
          if (this.perfil) {
            this.snackService.success('Perfil editado');
            this.router.navigate(['/home']);
          } else {
            this.snackService.success('Usuario editado');
            this.router.navigate(['/usuarios']);
          }
        } catch (ex) {
          if (ex[0]) {
            this.snackService.error(ex[0].details);
          } else if (ex.errors) {
            this.snackService.error(ex.errors[0].defaultMessage);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      } else {
        try {
          const response = await this.backendService.createWithResponse(User.path, this.user) as any;
          this.saveFotos(response.id);
          this.snackService.success('Usuario creado');
          this.router.navigate(['/usuarios']);
        } catch (ex) {
          if (ex[0]) {
            this.snackService.error(ex[0].details);
          } else if (ex.error[0]) {
            this.snackService.error(ex.error[0].details);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      }
    }
  }

  public roleSelected(role: Role): boolean {
    for (const roleUser of this.user.roles) {
      if (role.id === roleUser.id) {
        return true;
      }
    }
    return false;
  }

  private filter() {
    this.roleList.filter(p => this._selectedRole.indexOf(p) === 0);
  }

  cambiarPassword() {
    this.router.navigate(['/usuarios/cambiar_mi_password']);
  }

  async saveFotos(userId) {
    try {
      let formdata: FormData;
      const archivos = this.user.fotos;
      for (let i = 0; i < this.user.fotos.length; i++) {
        if (archivos[i].id === undefined && archivos[i].file !== null && archivos[i].file !== undefined) {
          formdata = new FormData();
          formdata.append('file', archivos[i].file);
          formdata.append('name', archivos[i].name);
          formdata.append('id', userId);
          await this.backendService.createFile(User.path + Foto.path, formdata);
        }
      }
    } catch (ex) {
      this.snackService.error(ex.message);
    }
  }

  async getSucursales() {
    await this.backendService.get<DataObject<Sucursal>>(Sucursal.path + 'all', null).toPromise().then(res => {
      this.sucursales = res.data;
    });
  }

  containDuplicates(myArray) {
    return myArray.length !== new Set(myArray).size;
  }


}
