export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  public apiBack = 'http://localhost:8080/1.0/backend';
  public api = 'http://localhost:8080/1.0';
  public clientes = '/clientes';
  public production = false;
  public captcha = true;
  // Whether or not to enable debug mode
  public enableDebug = true;

  constructor() {
  }

}