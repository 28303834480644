import { Component, OnInit, Input } from '@angular/core';
import { Logo } from '../../models/logo.model';
import { OperationalParametric } from '../../models/operationalParametric.model';
import { EstadoCliente } from '../../models/estadoCliente.model';
import { BackendService } from '../../core/backend.service';
import { SnackService } from '../../shared-services/snack.service';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { DialogService } from '../../shared-components/modals/dialog.service';
import { Cliente } from '../../models/cliente.model';

@Component({
  selector: 'app-cliente-logo-upload',
  templateUrl: './cliente-logo-upload.component.html',
  styleUrls: ['./cliente-logo-upload.component.css']
})
export class ClienteLogoUploadComponent implements OnInit {

  @Input() logos: Logo[];
  @Input() estado: OperationalParametric;
  @Input() editable = false;

  private re = /(?:\.([^.]+))?$/;
  private EstadoCliente = new EstadoCliente();
  seleccionarArchivo = 'Seleccionar imágen';
  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService) { }

  ngOnInit() {
    if (this.logos.length === 0) {
      this.agregarImagen();
    }
    const imgs = [];
    this.logos.forEach(i => {
      const img = new Logo(null);
      img.id = i.id;
      img.name = i.name;
      img.path = i.path;
      if (i.path && i.path.indexOf('http') < 0) {
        this.getImages(img);
      } else {
        img.url = i.path + i.name;
      }

      imgs.push(img);
    });
    this.logos.splice(0);
    imgs.forEach(i => this.logos.push(i));
  }

  getImages(imagen: Logo) {
    this.backendService.getImagenById(Cliente.path + 'logo/' + imagen.id).subscribe(data => {
      const urlCreator = window.URL;
      const safeUrl: any = this.sanitizer.bypassSecurityTrustUrl(
          urlCreator.createObjectURL(data));
      imagen.url = safeUrl;
    });
  }

  agregarImagen() {
    this.logos.push(new Logo(undefined));
  }
  eliminarImagen(index: number) {
    if (this.logos[index].id) {
      this.backendService.get<any>(Cliente.path + 'logo/usos/' + this.logos[index].id).toPromise().then( d => {
        const msg = '¿Está seguro que desea eliminar el logo ' + this.logos[index].name + '?';
        let msg2 = '';
        if (d) {
          msg2 = 'El logo ha sido usado en acuerdos, en esos acuerdos seguirá vigente pero no podrá usarse en acuerdos nuevos';
        }

        this.dialogService.confirm('Eliminar archivo', '', [msg, msg2])
        .then( res => {
          if (res) {
            this.deleteFromDb(this.logos[index].id);
            this.logos.splice(index, 1);
            if (this.logos.length === 0) {
              this.logos.push(new Logo(undefined));
            }
          }
        });
      } );
    } else {
      this.logos.splice(index, 1);
    }
  }

  deleteFromDb(id: number) {
    this.backendService.delete(Cliente.path + 'eliminar-logo/' + id);
  }
  addFile(event, index: number) {
    if (this.soloImagenesOPdf(event.target.files[0].name)) {
      this.logos[index].file = event.target.files[0];
      this.logos[index].path = event.target.files[0].name;
      this.logos[index].name = event.target.files[0].name;
      this.logos[index].id = undefined;
    }
  }

  soloImagenesOPdf(fileName: string) {
    let ext = this.re.exec(fileName)[1];
    ext = ext.toLowerCase();
    if (ext !== 'png' && ext !== 'jpg' && ext !== 'svg' && ext !== 'jpeg' && ext !== 'gif' && ext !== 'pdf') {
      this.snackService.error('Solo estan permitidos archivos de tipo ".png", ".jpg", ".svg", ".jpeg", ".gif" o ".pdf"');
      return false;
    }
    return true;
  }


  public openWindow(img: Logo) {
    this.dialogService.imageViewer(img.name, '', img.url);
    // window.open(img.url, '_blank');
  }

}
