import { Injectable } from '@angular/core';
import { Resolve, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { SnackService } from '../../../shared-services/snack.service';
import { SecurityService } from '../../security.service';
import { BackendService } from '../../../core/backend.service';
import { User } from '../../../models/user.model';

@Injectable()
export class UserChangePasswordResolver implements Resolve<any> {
  constructor(
    protected router: Router,
    protected snackService: SnackService,
    protected securityService: SecurityService,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id || 'me';

      if (id === 'me') {
        return { myPassword: true, user: null };
      }

      return { myPassword: false, user: await this.backendService.get<User>(User.path + id).toPromise() };
    } catch (error) {
      this.router.navigate(['/usuarios']);
    }
  }
}
