import { Component, OnInit, ViewChild } from '@angular/core';
import { Filters } from '../../../models/filters.model';
import { GenericListComponent } from '../../../shared-components/generic-list/generic-list.component';
import { switchMap, share } from '../../../../../node_modules/rxjs/operators';
import { PaginatedList } from '../../../models/paginatedList.model';
import { Observer, Observable } from '../../../../../node_modules/rxjs';
import { BackendService } from '../../../core/backend.service';
import { SnackService } from '../../../shared-services/snack.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { UpdateEventService } from '../../../shared-services/update-event.service';
import { SecurityService } from '../../../security/security.service';
import { DialogService } from '../../../shared-components/modals/dialog.service';
import { Comprobante } from '../../../models/comprobante.model';

@Component({
  selector: 'app-comprobante-table',
  templateUrl: './comprobante-table.component.html',
  styleUrls: ['./comprobante-table.component.css']
})
export class ComprobanteTableComponent implements OnInit {
  isLoading = false;
  roleFiltersActive = false;
  addUrl = '/comprobante/agregar';

  public filters: Filters = {
    name: undefined,
    page: 0,
    size: 10,
    sort: 'fechaCreacion,desc'
  };
  comprobantesLista = [];

  @ViewChild(GenericListComponent, {static: false}) genericList;

  addText = 'Agregar Comprobante';
  searchTitle = 'Comprobantes';
  searchPlaceHolder = 'Ingrese el número del comprobante que desea buscar';
  messageEmpty = 'No se encuentran comprobantes.';
  messageFilters = 'No se encuentran comprobantes que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'CREAR_COMPROBANTE';
  atributoBusqueda = 'numero';

  comprobantesList = this.backendService.newSubjectWithoutDistinct<Filters>().pipe(
    switchMap(filters => {
      const promise = this.backendService.get<PaginatedList<Comprobante>>(Comprobante.path, filters).toPromise();
      return (async () => {
        this.isLoading = true;
        try {
          const permissions = await promise;
          this.comprobantesLista = permissions.data;
          return permissions;
        } catch (error) {
          return [];
        } finally {
          this.isLoading = false;
          this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
        }
      })();
    }))
  .pipe(share()) as Observer<Filters> & Observable<Comprobante[]>;

  constructor(
    private backendService: BackendService,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    public securityService: SecurityService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
  }

  onEditComprobante(comprobante: Comprobante) {
    this.router.navigate(['/comprobante/editar', comprobante.id]);
  }

  async onDeleteComprobante(comprobante: Comprobante) {

    this.dialogService.confirm('Eliminar Comprobante',
    '¿Está seguro que desea eliminar el comprobante ' + comprobante.numero + '?')
    .then( res => {
      if (res) {
        this.deleteComprobante(res, comprobante);
      }
    });
  }

  async deleteComprobante(res, comprobante: Comprobante) {

    if (res) {
      this.snackService.spinner(true);
      try {
        await this.backendService.delete(Comprobante.path + comprobante.id);
        this.snackService.success('Comprobante eliminado correctamente');
      } catch (ex) {
        this.snackService.error(ex.error.message);
      } finally {
        this.snackService.spinner(false);
        this.genericList.refreshRoleList();
      }
    }
  }

}
