import { Injectable } from '../../../../node_modules/@angular/core';
import { Color } from '../../models/color.model';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '../../../../node_modules/@angular/router';
import { SnackService } from '../../shared-services/snack.service';
import { BackendService } from '../../core/backend.service';

@Injectable()
export class ColorResolver implements Resolve<Color> {
  constructor(
    protected router: Router,
    protected snackService: SnackService,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id || 'agregar';

      if (id === 'agregar') {
        return new Color();
      }
      return await this.backendService.get<Color>(Color.path + id).toPromise();
    } catch (error) {
      this.router.navigate(['/colores']);
    }
  }
}
